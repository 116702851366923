import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import usePinnedDrawer from "../../hooks/drawer_pinned_hook";
import DrawerComponent from "../navigation/manage_drawer/event_detail";
import ManagerDashboardDrawerComponent from "../navigation/manage_drawer/manager_dashboard";

interface DrawerBoxWrapperProps {
  eventID?: string;
  children?: React.ReactNode;
  showManagerDashboard?: boolean;
}

const DrawerBoxWrapper: React.FC<DrawerBoxWrapperProps> = ({
  eventID,
  children,
  showManagerDashboard = false,
}) => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  // Custom hook for pinned drawer behavior, only used on larger screens
  const { marginLeft, isPinned, handlePinned } = usePinnedDrawer("75px");

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", position: "relative" }}>
      {showManagerDashboard ? (
        <ManagerDashboardDrawerComponent handlePinned={handlePinned} />
      ) : (
        <DrawerComponent eventID={eventID!} handlePinned={handlePinned} />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ml: isScreenSmall ? 1.5 : marginLeft,
          mr: 1.5,
          width: isScreenSmall ? "100%" : `calc(100% - ${marginLeft})`,
          minHeight: "100vh",
          position: "relative",
          zIndex: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DrawerBoxWrapper;
