import React, { useEffect, useRef, useState } from "react";
import { ISelectedAddon, ITicketBasket, ITicketRelease } from "../../../types";
import { Box, Chip, Divider, Stack, Typography, Tooltip } from "@mui/joy";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";
import TicketReleaseCountdown from "./tr_countdown";
import TicketType from "../ticket_types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  ShoppingCartItem,
  addTicket,
  updateCartItemAddons,
  openCartModal,
  setCurrentTicketReleaseId,
  setSingleTicket,
} from "../../../redux/features/ticketOrderSlice";
import WhaIsTicketRequestModal from "./what_is_ticket_request";
import StyledButton from "../../buttons/styled_button";
import { useTranslation } from "react-i18next";
import StyledText from "../../text/styled_text";
import TicketReleaseAddons from "./addons";
import { useMediaQuery, useTheme } from "@mui/material";
import { resetLoginSuccess } from "../../../redux/features/authSlice";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonIcon from "@mui/icons-material/Person";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfoIcon from "@mui/icons-material/Info";
import { adjustTransparency } from "../../../utils/manager/color";

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const TicketReleaseHasOpened = ({
  ticketRelease,
}: {
  ticketRelease: ITicketRelease;
}) => {
  const { items: ticketOrderItems, loading: makingRequest } = useSelector(
    (state: RootState) => state.ticketOrder
  ) as {
    items: ShoppingCartItem[];
    loading: boolean;
  };

  const [basket, setBasketItems] = React.useState<ITicketBasket[]>([]);
  const [whatIsRequestOpen, setWhatIsRequestOpen] = React.useState(false);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const darkMode = useIsDarkTheme();

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const palette = usePalette();

  const addonsRef = useRef<HTMLDivElement>(null);

  // Check if this is a single free ticket release
  const isSingleFreeTicket =
    ticketRelease.ticket_types?.length === 1 &&
    ticketRelease.ticket_types[0].price === 0 &&
    ticketRelease.ticket_release_method_detail?.max_tickets_per_user === 1;

  // Check if there are any add-ons available
  const hasAddons = ticketRelease.add_ons && ticketRelease.add_ons.length > 0;

  const handleDirectRegister = () => {
    if (isSingleFreeTicket && ticketRelease.ticket_types?.[0]) {
      // Set the current ticket release ID
      dispatch(
        setCurrentTicketReleaseId({
          ticketReleaseId: ticketRelease.id,
          eventId: ticketRelease.event_id,
        })
      );

      // Set the single ticket (this will clear any existing tickets)
      dispatch(setSingleTicket(ticketRelease.ticket_types[0]));

      if (!hasAddons) {
        // If no add-ons, open the cart modal directly
        dispatch(openCartModal());
      }
      // If there are add-ons, the add-ons section will be shown automatically
      // due to cartItemsForRelease having an item
    }
  };

  useEffect(() => {
    const basketItems: ITicketBasket[] = [];

    setBasketItems((prevBasket) => {
      const newBasket = [...prevBasket];

      ticketOrderItems.forEach((item, ticketIndex) => {
        const ticketType = ticketRelease.ticket_types?.find(
          (tt) => tt.id === item.ticket.id
        );

        if (ticketType) {
          const quantity = ticketOrderItems.filter(
            (item) => item.ticket.id === ticketType.id
          ).length;

          // For each ticket, ensure it is treated individually
          for (let i = 0; i < quantity; i++) {
            const basketItem: ITicketBasket = {
              id: ticketType.id,
              name: ticketType.name,
              quantity: 1,
              price: ticketType.price,
              type: "ticket", // Ensure the type is explicitly set to the string "ticket"
              addons: newBasket[ticketIndex]?.addons || [], // Preserve existing addons or initialize with empty
            };

            basketItems.push(basketItem);
          }
        }
      });

      return basketItems;
    });
  }, [ticketOrderItems, ticketRelease.ticket_types]);

  const cartItems = useSelector((state: RootState) => state.ticketOrder.items);
  const cartItemsForRelease = cartItems.filter(
    (item) => item.ticket.ticket_release_id === ticketRelease.id
  );

  // Calculate total amount including addons
  const totalAmount = cartItemsForRelease.reduce((total, item) => {
    const ticketPrice = item.ticket.price;
    const addonsPrice = item.addons.reduce((sum, addon) => {
      const addonInfo = ticketRelease.add_ons?.find((a) => a.id === addon.id);
      return sum + (addonInfo ? addonInfo.price * addon.quantity : 0);
    }, 0);
    return total + ticketPrice + addonsPrice;
  }, 0);

  // Handle addon change for a specific ticket in the basket
  const handleAddonChange = (
    cartItemId: string,
    updatedAddons: ISelectedAddon[]
  ) => {
    dispatch(updateCartItemAddons({ cartItemId, addons: updatedAddons }));
  };

  useEffect(() => {
    dispatch(resetLoginSuccess());
  }, [dispatch]);

  useEffect(() => {
    if (cartItemsForRelease.length === 1 && addonsRef.current && !hasScrolled) {
      addonsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setHasScrolled(true);
    }
  }, [cartItemsForRelease]);

  return (
    <>
      {/* {makingRequest && <LoadingOverlay />} */}

      {isSingleFreeTicket ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: 600,
            mt: 2,
            backgroundColor: darkMode
              ? adjustTransparency(palette.alwaysBlack, 0.5)
              : adjustTransparency(palette.isabellineExtraDark, 0.1),
            borderRadius: "12px",
            border: `2px solid ${adjustTransparency(
              palette.primaryExtraLight,
              0.6
            )}`,
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
            height: "auto",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
              borderColor: palette.primaryMain,
            },
          }}
        >
          {/* Left Side - Ticket Info */}
          <Box
            sx={{
              flex: 1,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <LocalOfferIcon
                sx={{ color: palette.primaryMain, fontSize: 24 }}
              />
              <StyledText
                fontWeight={700}
                fontSize={isScreenSmall ? 16 : 24}
                sx={{
                  overflowWrap: "break-word",
                  maxWidth: "100%",
                  color: palette.primaryDark,
                  lineHeight: 1.2,
                }}
              >
                {ticketRelease.ticket_types![0].name}
              </StyledText>
              {ticketRelease.ticket_types![0].description && (
                <Tooltip
                  title={ticketRelease.ticket_types![0].description}
                  placement="right"
                  arrow
                  variant="soft"
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <InfoIcon
                    sx={{
                      color: palette.primaryDark,
                      cursor: "help",
                      fontSize: 20,
                      opacity: 0.8,
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <StyledText
              fontWeight={600}
              fontSize={20}
              sx={{
                color: palette.primaryDark,
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {t("event.ticket_release.checkout.free")}
            </StyledText>
          </Box>

          {/* Right Side - Register/Select Button */}
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              bgcolor: adjustTransparency(palette.primaryExtraLight, 0.15),
              borderLeft: `2px dashed ${adjustTransparency(
                palette.primaryMain,
                0.3
              )}`,
              padding: 1.5,
            }}
          >
            <StyledButton
              size="md"
              onClick={handleDirectRegister}
              bgColor={palette.successLight}
              color={palette.black}
              startDecorator={hasAddons ? <LocalOfferIcon /> : <HowToRegIcon />}
              sx={{
                width: "100%",
                fontSize: "16px",
                py: 1.25,
                fontWeight: 600,
              }}
            >
              {hasAddons
                ? t("event.ticket_release.checkout.select")
                : ticketRelease.button_text}
            </StyledButton>
          </Box>
        </Box>
      ) : (
        <>
          <Stack spacing={2} sx={{ p: 0 }}>
            {ticketRelease.ticket_types!.length > 0 ? (
              ticketRelease.ticket_types!.map((ticketType, i) => {
                const key = `${ticketType.id}-${i}`;
                return (
                  <TicketType
                    ticketType={ticketType}
                    maxTicketsPerUser={
                      ticketRelease.ticket_release_method_detail
                        ?.max_tickets_per_user
                    }
                    key={key}
                  />
                );
              })
            ) : (
              <StyledText
                level="body-sm"
                fontSize={16}
                color={palette.textSecondary}
              >
                {t("event.ticket_release.no_tickets")}
              </StyledText>
            )}
          </Stack>
        </>
      )}
      {ticketRelease.add_ons &&
        ticketRelease.add_ons.length > 0 &&
        cartItemsForRelease.length > 0 && (
          <div ref={addonsRef}>
            <Box sx={{ mt: 2 }}>
              <TicketReleaseAddons
                ticketRelease={ticketRelease}
                cartItems={cartItemsForRelease}
                handleChange={handleAddonChange}
              />
            </Box>
          </div>
        )}
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 16,
          right: 16,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <TicketReleaseCountdown
          ticketRelease={ticketRelease}
          fw={500}
          fs={16}
          useOpen={false}
        />
      </Box>
      {cartItemsForRelease.length > 0 && totalAmount > 0 && (
        <Box
          sx={{
            maxWidth: "100%",
          }}
        >
          <Divider
            sx={{
              mb: 1,
              width: "100%",
            }}
          />
          {/* Total */}

          <WhaIsTicketRequestModal
            isOpen={whatIsRequestOpen}
            onClose={() => {
              setWhatIsRequestOpen(false);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default TicketReleaseHasOpened;
