import React, { useState, useEffect, useRef } from "react";
import NavigationBar from "../navigation";
import { Box, CssVarsProvider, IconButton, useTheme } from "@mui/joy";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../../theme";
import { INavigationBarOptions } from "../../types";
import { SHOW_SCROLL_UP_THRESHOLD } from "./page_wrapper_mui";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import usePalette, { useIsDarkTheme } from "../../theme/use_palette";
import Footer from "./footer";
import useShowMobileNav from "../../hooks/nav/show_mobile_nav_hook";
// @ts-ignore
import FOG from "vanta/dist/vanta.fog.min";
import * as THREE from "three";
import { lightPaletteExported } from "../../theme/theme_context";
import { lightenColor } from "../../utils/manager/color";

interface TesseraWrapperProps {
  children: React.ReactNode;
  navOptions?: INavigationBarOptions;
  defaultColors?: boolean;
  enableVantaBackground?: boolean;
}

const TesseraWrapper: React.FC<TesseraWrapperProps> = ({
  children,
  navOptions,
  defaultColors = false,
  enableVantaBackground = false,
}) => {
  const [showScroll, setShowScroll] = useState(false);
  const palette = usePalette();
  const [vantaEffect, setVantaEffect] = useState<any>(null);
  const vantaRef = useRef<HTMLDivElement>(null);
  const isDarkMode = useIsDarkTheme();

  const usingMobileNav = useShowMobileNav();

  useEffect(() => {
    if (enableVantaBackground) {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
      setVantaEffect(
        FOG({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          baseColor: palette.offWhite,
          blurFactor: 0.6,
          speed: 1.5,
          highlightColor: isDarkMode
            ? 0x22223b
            : lightenColor(lightPaletteExported.ultraVioletExtraLight, 0.5),
          midtoneColor: isDarkMode
            ? lightPaletteExported.ultraVioletExtraLight
            : 0xe2d9d4,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [enableVantaBackground, isDarkMode]);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > SHOW_SCROLL_UP_THRESHOLD) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= SHOW_SCROLL_UP_THRESHOLD) {
      setShowScroll(false);
    }
  };

  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <CssVarsProvider theme={theme}>
      <Box
        ref={vantaRef}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          backgroundColor: palette.offWhite,
        }}
      />

      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={useIsDarkTheme() ? "dark" : "light"}
        />

        <Box
          sx={{
            position: "relative",
            backgroundColor: enableVantaBackground ? "transparent" : undefined,
            backdropFilter: enableVantaBackground ? "blur(10px)" : undefined,
          }}
        >
          <NavigationBar
            options={navOptions}
            shouldUseDefaultColor={defaultColors}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            width: "100%",
            pb: 20,
            position: "relative",
            zIndex: 1,
            minHeight: "100vh",
          }}
          id="tessera-wrapper"
          style={{
            overflow: "hidden",
            marginTop: !usingMobileNav ? "64px" : "0",
          }}
        >
          {children}
        </Box>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: showScroll ? 1 : 0, y: showScroll ? 0 : 50 }}
          transition={{ duration: 0.5 }}
          style={{
            position: "fixed",
            top: "90px",
            right: "30px",
            zIndex: 1000,
          }}
        >
          <IconButton
            sx={{
              backgroundColor: palette.offBlack,
              color: "#fff",
              "&:hover": {
                backgroundColor: palette.alwaysWhite,
              },
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
            onClick={scrollTop}
            aria-label="Scroll to top"
          >
            <ArrowUpwardIcon color="primary" />
          </IconButton>
        </motion.div>

        <Footer />
      </Box>
    </CssVarsProvider>
  );
};

export default TesseraWrapper;
