import { title } from "process";
import seFaq from "../assets/faq/se_faq.json";
import { seMealTags } from "./mealTags";

const seTranslations = {
  navigation: {
    events: "Evenemang",
    create_event: "Publicera event",
    teams: "Grupper",
    contact: "Kontakt",
    login: "Logga in",
    product: "Platform",
    pricing: "Priser",
    manager: "Arrangör",
    solutions: "Lösningar",
  },

  become_a_manager: {
    welcome: "Välkommen till tessera!",
    proceed: "Vem är du?",
    customer: "Kund",
    manager: "Arrangör",
    skip: "Hoppa över",
    select_plan: "Välj paket",
    continue_as_manager: "Fortsätt som arrangör",
    choose_plan:
      "Välj det paket som passar dig bäst. När du kontaktar oss, vänligen låt oss veta vilken paket du är intresserad av och vad du skulle vilja veta mer om.",
    full_list_of_features: "Fullständig lista över funktioner",
    selected_plan: "Vald paket",
    register: {
      on_board:
        "Kul att ha dig ombord! Fyll i formuläret nedan för att bli en evenemangsarrangör.",
      free: {
        title: "Gratis Plan",
      },
      form: {
        company_name: "Företagsnamn",
        company_name_helperText: "Vad heter ditt företag?",
        referral_source: "Hur hörde du talas om oss?",
        referral_source_specific: "Ange hur du hörde talas om oss",
        beta_key: "Beta-nyckel",
      },
      request_beta_access: "Ställ en fråga",
      have_beta_key: "Kom igång nu",
      no_beta_key: "Ställ en fråga",
    },
  },

  tooltips: {
    add_ticket_type: "Lägg till biljettgrupp",
    manage_ticket_releases: "Hantera biljettsläpp för detta evenemang.",
    manage_tickets:
      "Tabellen visar alla biljettförfrågningar och biljetter för detta evenemang.",
    manage_tickets_custom_event_form_description:
      "Här kan du se alla anpassade evenemangsfält som användare har fyllt i när de begärde biljetter.",
    must_be_edited: "Formuläret måste vara giltigt innan du kan fortsätta.",
  },

  form: {
    required_description: "Fält markerade med * är obligatoriska.",
    advanced_settings: "Avancerade inställningar",
    event_details: {
      name: "Namn",
      name_helperText: "Vad är namnet på ditt evenemang?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vad ditt evenemang handlar om. Vad kan deltagarna förvänta sig? Markdown stöds.",
      date: "Datum",
      date_helperText: "När är ditt evenemang?",
      end_date: "Slutdatum",
      end_date_helperText: "När slutar ditt evenemang?",
      location: "Plats",
      location_helperText: "Var hålls ditt evenemang?",
      category: "Kategori",
      category_helperText: "Vilken typ av evenemang är det?",
      team: "Grupp",
      team_helperText:
        "Vilken grupp står värd för ditt evenemang? Du måste koppla evenemanget till ett grupp. Du måste vara medlem i gruppen för att kunna skapa ett event, läs mer <1>här</1>.",
      private_event: "Privat evenemang",
      private_event_helperText:
        "Är ditt evenemang privat? Om så är fallet kan bara personer med länken se det.",
      common_locations: "Platser du ofta använder",
      collect_food_preferences: "Fråga om matpreferenser",
      collect_food_preferences_helperText:
        "Vill du samla in matpreferenser från deltagarna? Om så är fallet kommer deltagarna att kunna ange sina matpreferenser när de begär en biljett.",
      language: "Språk",
      language_helperText: "Vilket språk kommer ert event att vara på?",
      tags: "Taggar",
      tags_helperText: "Lägg till taggar för att kategorisera ditt evenemang",
      tags_placeholder: "Skriv och tryck enter för att lägga till en tagg",
      display_attendee_count: "Visa antal deltagare",
      display_attendee_count_helperText: "Visa antalet deltagare på eventsidan",
    },
    ticket_release: {
      name: "Namn på biljettgrupp",
      name_helperText: "Vad är namnet på biljettgruppen?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vilken typ av biljetter du släpper. Vad kan deltagarna förvänta sig? Markdown stöds.",
      available_at: "Börjar sälja biljetter",
      available_at_helperText:
        "När släpps biljetterna? Detta är tidpunkten då användare kan börja önska biljetter.",
      closes_at: "Slutar sälja biljetter",
      closes_at_helperText:
        "När stänger biljettsläppet? Detta är tidpunkten då användare inte längre kan önska biljetter.",
      ticket_release_method: "Metod för biljettsläpp",
      ticket_release_method_helperText: "Hur vill du distribuera biljetter?",
      lottery_duration: "Lottningstid (minuter)",
      lottery_duration_helperText:
        "För Först till kvarn, definierar lottningstiden inom hur många minuter efterfrågade biljetter kommer att ingå i en lottning. Om fler biljetter efterfrågas än tillgängligt, kommer alla deltagare som önskar biljetter inom denna tidsram att delta i en lottning, övriga blir reserv.",
      max_tickets_per_user: "Biljetter per användare",
      max_tickets_per_user_helperText:
        "Hur många biljetter kan en användare önska/köpa?",
      tickets_available: "Antal biljetter",
      tickets_available_helperText:
        "Hur många biljetter kommer att vara tillgängliga?",
      notification_method: "Notifieringsmetod",
      notification_method_helperText:
        "Hur vill du meddela användare om biljettsläppet?",
      cancellation_policy: "Avbokningspolicy",
      cancellation_policy_helperText: "Vad är avbokningspolicyn?",
      reserved_ticket_release: "Reserverat biljettsläpp",

      promo_code: "Promokod",
      promo_code_helperText:
        "Vilken promokod ska användas för att få tillgång till detta reserverade biljettsläpp?",
      selective_description: "Beskriv allokeringsmetoden",
      selective_description_helperText:
        "Ange hur ditt team planerar att allokera biljetter. Denna information kommer att visas för användaren när de begär en biljett.",
      save_template: "Spara som mall",
      save_template_helperText:
        "Spara detta biljettsläpp som en mall för att använda det för framtida evenemang.",

      payment_deadline: "Betalningsdeadline",
      payment_deadline_helperText:
        "När är betalningsdeadlinen för användare som initialt fick en biljett?",
      reserve_payment_duration: "Reserverad Betalningstid",
      reserve_payment_duration_helperText:
        "Ange varaktigheten i text, t.ex. '2 dagar 3 timmar' eller '45 minuter'. Du kan använda dagar, timmar och minuter.",
      allocation_cut_off: "Stopp för Allokering",
      basic_details: "Grundläggande information",
      timing_configuration: "Viktiga datum",
      distribution_rules: "Biljettfördelning",
      ticket_settings: "Biljettinställningar",
      additional_settings: "Övriga inställningar",
      lottery_window_time:
        "Lotterifönstret kommer att vara öppet från <1>{{start}}</1> till <1>{{end}}</1>",
      reserved_ticket_release_helperText:
        "Ett reserverat biljettsläpp innehåller biljetter reserverade för specifika användare. En kampanjkod krävs för att få tillgång till dessa biljetter. Du kan lägga till fler biljettgrupper senare.",
      reserve_payment_duration_summary:
        "Användare har {{days}} dagar, {{hours}} timmar och {{minutes}} minuter på sig att slutföra betalningen efter att de fått sin biljett.",
      button_text: "Knapptext",
      button_text_helperText:
        "Vad ska knappen visa? (t.ex. 'Köp biljetter', 'Registrera', 'Anmäl dig')",
    },
    ticket_types: {
      name: "Namn på biljett",
      name_helperText: "Vad är namnet på denna biljett?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vad som ingår i denna biljett. Markdown stöds.",
      price: "Biljettpris",
      price_helperText: "Hur mycket kostar denna biljett?",
      save_template: "Spara som mall",
      save_template_helperText:
        "Spara denna biljett som en mall för att använda den för framtida evenemang.",
    },

    contact: {
      title: "Kontakt",
      email: "Din E-post",
      plan: "Paket",
      email_helperText: "Vad är kontakt-e-posten för denna biljett?",
      subject: "Ämne",
      subject_helperText: "Vad är ämnet för denna biljett?",
      name: "Ditt Namn",

      name_helperText: "Vad är namnet på kontaktpersonen?",
      message: "Meddelande",
      message_helperText: "Vad är meddelandet för denna biljett?",
      success: "Ditt meddelande har skickats!",
      fail: "Något gick fel. Försök igen senare.",
      description:
        "Om du har några frågor, problem eller förslag, tveka inte att kontakta oss. Vi kommer att återkomma till dig så snart som möjligt.",
      team_name: "Grupp",
      team_helperText: "Vilken grupp kontaktar du?",
    },
    addon: {
      name: "Namn",
      name_helperText: "Vad är namnet på detta tillägg?",
      description: "Beskrivning",
      description_helperText:
        "Beskriv vad detta tillägg handlar om. Markdown stöds.",
      price: "Pris (SEK)",
      price_helperText: "Hur mycket kostar detta tillägg?",
      max_quantity: "Max kvantitet",
      max_quantity_helperText:
        "Hur många av detta tillägg kan en användare köpa?",
      is_enabled: "Aktiverad",
      is_enabled_helperText:
        "Om tilläget är aktiverat kommer det att visas för användaren.",
      contains_alcohol: "Innehåller alkohol",
      contains_alcohol_helperText: "Innehåller detta tillägg alkohol?",
    },
    event_fields: {
      title: "Eventformulär",
      subtitle:
        "Lägg till och redigera specialgjorda fält för ditt evenemang. Dessa fält kommer att visas för användare när de begär/köper en biljett.",
      label_name: "Namn",
      label_description: "Beskrivning",
      label_type: "Typ",
      label_required: "Obligatorisk",
      form_field_description: "Beskrivning",
      form_field_description_helperText:
        "Beskriv vilken ytterligare information du vill samla in från användare och varför. Markdown stöds.",
      delete_field_confirm:
        "Är du säker på att du vill ta bort detta fält? Att ta bort detta fält kan resultera i förlust av svarsinformation. Du måste spara efter att ha tagit bort!",
      select_field_type: "Välj en fälttyp",
      field_name: "Fältnamn",
      field_name_placeholder: "Ange fältnamn",
      field_description: "Beskrivning",
      field_description_placeholder: "Ange fältbeskrivning",
      required: "Obligatorisk",
      options_label: "Alternativ",
      option: "Alternativ",
      option_placeholder: "Ange alternativ",
      button_add_option: "Lägg till alternativ",
      button_delete_option: "Ta bort alternativ",
      delete_field: "Ta bort fält",
      templates: {
        title: "Formulärfältmallar",
        seating: {
          name: "Sittplatspreferens",
          description: "Fråga deltagare om deras sittplatspreferenser",
          field: {
            name: "Vem skulle du föredra att sitta bredvid?",
            description: "Låt oss veta om du har några sittplatspreferenser",
          },
        },
        dietary: {
          name: "Kostbehov",
          description: "Samla in kostbehov från deltagare",
          field: {
            name: "Har du några kostbehov?",
            description: "Välj alla som gäller",
            options: [
              "Vegetarian",
              "Vegan",
              "Glutenfri",
              "Laktosfri",
              "Nötallergi",
            ],
          },
        },
        accessibility: {
          name: "Tillgänglighetsbehov",
          description: "Fråga om tillgänglighetskrav",
          field: {
            name: "Har du några tillgänglighetsbehov?",
            description: "Hjälp oss att tillgodose dina behov",
          },
        },
        emergency: {
          name: "Nödkontakt",
          description: "Samla in information om nödkontakt",
          field: {
            name: "Uppgifter för nödkontakt",
            description: "Ange namn och telefonnummer till nödkontakt",
          },
        },
        tshirt: {
          name: "T-shirtstorlek",
          description: "Samla in önskemål om t-shirtstorlek",
          field: {
            name: "Vilken t-shirtstorlek har du?",
            description: "Välj din önskade t-shirtstorlek",
          },
        },
      },
    },

    manager: {
      setup: {
        business_details: {
          legal_name: "Företagsnamn",
          legal_name_helperText: "Företagsnamn.",
          corporate_id: "Organisationsnummer",
          corporate_id_helperText: "Ditt företags organisationsnummer.",
          business_email: "Företagsemail",
          business_email_helperText: "Ditt företags emailadress.",
          country: "Land",
          country_helperText: "Ditt företags land.",
          phone_number: "Telefonnummer",
          phone_number_helperText: "Ditt företags telefonnummer.",
          address_line1: "Adressrad 1",
          address_line1_helperText: "Ditt företags adressrad 1.",
          address_line2: "Adressrad 2",
          address_line2_helperText: "Ditt företags adressrad 2.",
          city: "Stad",
          city_helperText: "Ditt företags stad.",
          postal_code: "Postnummer",
          postal_code_helperText: "Ditt företags postnummer.",
          terms_and_conditions_url: "Villkor URL",
          terms_and_conditions_url_helperText:
            "URL till ditt företags villkor.",
          privacy_policy_url: "Integritetspolicy URL",
          privacy_policy_url_helperText:
            "URL till ditt företags integritetspolicy.",
        },
      },
    },

    banking_details: {
      vat: "MOMS",
      vat_helperText:
        "MOMS-procenten som kommer att tillämpas på alla biljettförsäljningar.",
      user_pays_ticket_fee: "Kunden betalar biljettavgift",
      user_pays_ticket_fee_helperText:
        "Om aktiverad kommer kunden att betala biljetthanteringsavgiften istället för arrangören.",
    },

    domain_details: {
      slug: "Subdomän",
      slug_helperText:
        "Ange subdomänen för ditt evenemang. Denna sidan kommer dina deltagare att besöka för att köpa biljetter.",
      add_domain: "Lägg till domän",
      allowed_domains: "Tillåtna Domäner",
      domain_helperText:
        "Ange domän som du vill tillåta för att visa ditt evenemang.",
    },

    organization: {
      edit: {
        title: "Redigera Organisation",
        subtitle:
          "Redigera detaljerna för din organisation. Detta inkluderar både affärsdetaljer och butikens anpassning, vilket ändrar utseendet på din butik när en användare köper en biljett.",
        description: "Beskrivning",
        description_helperText:
          "Beskriv din organisation. Vad kan folk förvänta sig? Markdown stöds.",
        short_description: "Kort Beskrivning",
        short_description_helperText:
          "Ge en kort beskrivning av din organisation.",
        store_customization: {
          title: "Anpassa Webbutik",
          subtitle:
            "Anpassa utseendet av din webbutik när en användare köper en biljett.",
          background_color: "Bakgrundsfärg",
          background_color_helperText:
            "Välj en bakgrundsfärg för din webbutik.",
          brand_color: "Varumärkesfärg",
          brand_color_helperText: "Välj en varumärkesfärg för din webbutik.",
          accent_color: "Accentfärg",
          accent_color_helperText: "Välj en accentfärg för din webbutik.",
          rect_shape: "Knappform",
          rect_shape_helperText: "Välj formen på dina knappar.",

          rect_shapes: {
            rounded: "Rundad",
            edgy: "Kantig",
            pill: "Piller",
          },
          font_type: "Typsnitt",
          font_type_helperText: "Välj typsnitt för din butik.",
          font_types: {
            sans_serif: "Sans Serif",
            serif: "Serif",
            mono: "Monospace",
          },
          logo_url: "Logotyp",
          logo_url_helperText: "Ladda upp din organisations logotyp.",
          icon_url: "Ikon",
          icon_url_helperText:
            "Ladda upp din organisations ikon. Rekommenderad aspect ratio är 1:1.",
          current_url: "Nuvarande URL",
        },
      },
    },

    help: {
      event_name:
        "Ange evenemangets namn. Håll det kort, fängslande och beskrivande.",
      event_description: "Ge en översikt av evenemanget. Markdown stöds.",
      event_date: "Välj datum och starttid för ditt evenemang.",
      event_end_date:
        "Slutdatum/tid om ditt evenemang sträcker sig över flera timmar eller dagar.",
      event_location: "Detta är platsen/lokalen för ditt evenemang.",
      event_language: "Språket som evenemanget hålls på.",
      event_private:
        "Avgör vem som kan se evenemanget. Om privat kan endast personer med länken se det.",
      event_food_preferences: "Samla in matpreferenser från deltagarna.",
      event_team: "Teamet som arrangerar evenemanget.",

      ticket_release_name: "Ange namnet på biljettsläppet.",
      ticket_release_description:
        "Beskriv biljettsläppet. Till exempel, beskriv de olika typerna av biljetter som släpps. Markdown stöds.",

      ticket_release_open:
        "När kommer biljetterna att vara tillgängliga? (Lokal tid)",
      ticket_release_close: "När stänger biljettsläppet? (Lokal tid)",

      // Release Method
      ticket_release_method: "Välj hur biljetter ska distribueras",
      ticket_release_notification_method: "Hur användare kommer att meddelas",
      ticket_release_method_description:
        "Förklara vem detta selektiva släpp är avsett för",
      ticket_release_open_window: "Hur länge lotterifönstret förblir öppet",

      // Quantities
      ticket_release_max_tickets: "Maximalt antal biljetter en person kan köpa",
      ticket_release_quantity:
        "Totalt antal biljetter tillgängliga i detta släpp",

      // Notifications
      notification_method:
        "Hur användare kommer att meddelas om sina biljetter",

      // Policies
      ticket_release_cancellation_policy:
        "Vad som händer om någon avbokar sin biljett",

      // Reserved Settings
      ticket_release_is_reserved:
        "Ett reserverat biljettsläpp innehåller biljetter reserverade för specifika användare. En kampanjkod krävs för att få tillgång till dessa biljetter. Du kan lägga till fler biljettgrupper senare.",
      ticket_release_promo_code:
        "Kod som krävs för att få tillgång till reserverade biljetter",
      ticket_release_save_template: "Spara detta biljettsläpp som en mall",
      ticket_release_allocation_cut_off:
        "När kommer tilldelningen av biljetter att avslutas? Detta är när systemet slutar att tilldela biljetter.",

      ticket_release_reserve_payment_duration:
        "När en reserv får en biljett, hur länge har de på sig att betala innan den ges till nästa person i kön?",

      // Payment Settings
      ticket_release_payment_deadline:
        "Sista dag att betala för tilldelade biljetter",
      event_display_attendee_count:
        "Välj om antalet deltagare ska visas på eventsidan",
      event_tags:
        "Lägg till taggar för att hjälpa användare hitta ditt evenemang",
    },

    button_sign_in: "Logga in",
    button_add_field: "Lägg till fält",
    button_clear: "Rensa",
    button_next: "Nästa",
    button_create: "Skapa",
    button_edit: "Redigera",
    button_back: "Tillbaka",
    button_invite: "Bjud in",
    button_restart: "Börja om",
    button_confirm: "Bekräfta",
    button_cancel: "Avbryt",
    button_create_event: "Publicera event",
    button_save: "Spara",
    button_submit: "Submit",
    button_apply: "Tillämpa",
    button_manage: "Hantera",
    button_details: "Detaljer",
    button_add: "Lägg till",
    button_request: "Önska biljett(er)",
    button_purchase: "Betala",
    button_delete: "Radera",
    button_send: "Skicka",
    button_check_in: "Checka in",
    button_update_gdpr: "Spara och uppdatera GDPR samtycke",
    button_send_out: "Utskick",
    button_economy: "Ekonomi",
    button_close: "Stäng",
    button_templates: "Mallar",
    button_continue: "Fortätt",
    generate_sales_report: "Generera försäljningsrapport",
    button_create_private_event: "Skapa privat event",
    errors: {
      title: "Vänligen åtgärda följande fel:",
    },
  },
  create_event: {
    title: "Skapa evenemang",
    create_event_description:
      "Med tessera's enkla formulär kan du snabbt och enkelt skapa ditt event. Genom att fylla i events detaljer, biljettgrupper och biljetter kommer du snabbt kunna publicera ditt event.",
    event_details_title: "Eventdetaljer",
    event_details_description:
      "Låt oss börja med grunderna. Vad är detaljerna för ditt evenemang?",
    ticket_release_title: "Skapa biljettgrupp",
    ticket_release_description:
      "Biljettgrupper är en grupp av olika biljetter. Det hälper dig särskilja olika slags biljetter som till exempel standard biljetter från förtursbiljetter eller reserverade biljetter.",
    ticket_release_description_example:
      "Det går att skapa fler biljettgrupper senare, detta görs genom att skapa ytterligare biljettgrupper efter att eventet har skapats.",
    ticket_release: "Biljettgrupp",
    ticket_release_helperText:
      "Låt oss definiera detaljerna för denna biljettgrupp.",
    confirm_event_creation_restart_text:
      "Är du säker på att du vill börja om skapandet av eventet? Allting kommer återställas",
    ticket_types_title: "Biljetter",
    ticket_types_description:
      "Biljetterna tillhör den biljettgrupp du nyss skapade. Detta är de olika slags biljetterna som dina deltagare kan köpa.",
    ticket_types: "Biljetter",
    ticket_types_helperText: "Definiera biljetterna för denna biljettgrupp",
    ticket_types_select_helperText: "Välj en biljettyp att redigera",

    finish_title: "Då var det klart!",
    finish_description:
      "Se till att du har fyllt i allt korrekt. Du kan alltid redigera ditt evenemang senare. För att publicera ditt evenemang, klicka på knappen nedan.",
    no_teams:
      "Du är för närvarande inte med i en grupp och kan därför inte skapa ett evenemang. Kontakta din organisations administratör för att bli tillagd i en grupp.",
    review_submit_title: "Då var det klart!",
    select_category: "Välj en kategori",
  },

  // Profile
  profile: {
    title: "Profil",
    user_info_title: "Detaljer",
    full_name: "För- och efternamn",
    first_name: "Förnamn",
    last_name: "Efternamn",
    username: "Användarnamn",
    phone: "Telefonnummer",
    roles: "Roller",
    teams: "Grupper",
    email: "E-post",
    deactivate_account: {
      button_deactivate: "Inaktivera konto",
      button_reactivate: "Återaktivera konto",
      title: "Inaktivera konto",
      deactivate_account_confirmation_title: "Bekräfta kontoinaktivering",
      deactivate_account_confirmation_text:
        "Varning: Genom att inaktivera ditt konto kommer all din data inklusive biljetter, preferenser och personlig information att permanent raderas efter 30 dagar. Denna åtgärd kan inte ångras efter 30-dagarsperioden. Du kommer att få ett e-postmeddelande när raderingen är slutförd.",
      deactivate_account_confirmation_enter_text:
        'Vänligen skriv "radera konto" för att bekräfta.',
    },
    reactivate_account: {
      title: "Återaktivera konto",
      description:
        "Ditt konto är för närvarande inaktiverat. Vill du återaktivera det?",
      button_reactivate: "Återaktivera konto",
      countdown: "Tid kvar att återaktivera: {{seconds}} sekunder",
    },

    links_and_buttons: {
      your_ticket_requests: "Mina biljettförfrågningar",
      your_tickets: "Mina biljetter",
      your_teams: "Mina grupper",
    },

    tabs: {
      info: "Profil",
      tickets: "Dina Biljetter",
    },

    food_preferences: {
      title: "Matpreferenser",
      saved: "Dina matpreferenser har sparats.",
      allergies_and_dietary_restrictions_select: "Allergier och kost",
      allergies_and_dietary_restrictions: "Allergier och kost",
      allergies_and_dietary_restrictions_helperText:
        "Välj alla som gäller, lämna blankt om inga gäller.",
      additional_notes: "Övrigt",
      additional_notes_helperText:
        "Ange övrig speckost här angående dina matpreferenser. Lämna blankt om du inte har några.",
      privacy_policy_title: "Integritetspolicy",
      gdpr_agree_helperText:
        "Jag har läst och godkänner behandlingen av mina personuppgifter för att hantera mina matpreferenser enligt <1>Integritetspolicy för matpreferenser</1>",
      no_preferences: "Du har inga matpreferenser.",
      select_placeholder: "Välj...",
      additional_notes_required: "Ytterligare anteckningar krävs.",
      gluten_intolerant: "Glutenintolerant",
      lactose_intolerant: "Laktosintolerant",
      vegetarian: "Vegetarian",
      vegan: "Vegan",
      nut_allergy: "Nötallergi",
      egg_allergy: "Äggallergi",
      soy_allergy: "Sojaallergi",
      shellfish_allergy: "Skaldjursallergi",
      kosher: "Kosher",
      halal: "Halal",
      prefer_meat: "Jag Föredrar Kött Om Möjligt",
      other: "Annat",
    },

    your_ticket_requests: {
      title: "Dina biljettförfrågningar",
      description:
        "Nedan visas alla evenemang du har en biljett eller biljettförfrågan för. Klicka på en för att se mer detaljer.",
      upcoming_events: "Kommande evenemang",
      no_upcoming_events: "Du har inga kommande evenemang.",
      past_events: "Tidigare evenemang",
      no_past_events: "Du har inga tidigare evenemang.",
      no_ticket_requests: "Du har inga biljettförfrågningar.",
      show_past_events: "Visa",
      hide_past_events: "Dölj",
    },

    your_tickets: {
      title: "Dina biljetter",
      description:
        "Här kan du se alla biljetter du har fått. Du kan ge upp din biljett genom att klicka på biljetten och sedan välja alternativet 'Jag vill inte längre delta', vilket kommer att ge din biljett till nästa person i kön. Om du ännu inte har tilldelats en biljett eller reservbiljett kan du se dina biljettförfrågningar <1>här</1>.",
      upcoming_events: "Kommande evenemang",
      no_upcoming_events: "Du har inga kommande evenemang.",
      past_events: "Tidigare evenemang",
      no_past_events: "Du har inga tidigare evenemang.",
      no_tickets: "Du har inga biljetter.",
      order_id: "Order-ID",
      show_deleted: "Visa raderade",
      tickets_to: "Dina biljetter till",
    },

    your_teams: {
      title: "Dina grupper",
      description:
        "Här kan du se alla grupper du är en del av. Klicka på en grupp för att se mer detaljer. Du kan skapa en ny grupp <1>här</1>.",
      not_part_of_any_teams: "Inga grupper hittades.",
      add_user: "Lägg till användare",
      add_user_helperText:
        "Lägg till användare till gruppen från ditt närverk. Efter att användaren är tillaggd kan rollen ändras.",
      manage_team_events: "Hantera grupphändelser",
      no_events: "Det finns inga event i denna grupp.",
      users: "Användare",
      no_users: "Det finns inga användare i denna grupp.",
      delete_team: "Radera grupp",
      team_name_title: "Gruppnamn",
      edit_team: "Redigera grupp",
      delete_confirmation_title: "Bekräfta borttagning av team",
      delete_confirmation_body:
        "Är du säker på att du vill ta bort detta team? Denna åtgärd kan inte ångras.",

      events: "Evenemang",
    },
  },

  templates: {
    title: "Sparade Mallar",
    ticket_releases: {
      description:
        "Du kan skapa nya mallar genom att markera kryssrutan 'Spara som mall' när du skapar en biljettsläpp. Här kan du se alla dina sparade mallar. Klicka på 'Skapa' för att skapa en ny händelse från en mall.",
      no_templates: "Det finns inga tillgängliga mallar.",
    },
    ticket_types: {
      description:
        "Mallar för biljettbatcher är lite annorlunda jämfört med biljettsläpp. Att spara en mall innebär att du kommer att behålla den mallen för det biljettsläpp du redigerar. Att redigera din mall kommer automatiskt att uppdatera alla biljettsläpp som använder den mallen.",
      no_templates: "Det finns inga tillgängliga mallar.",
    },
  },

  create_team: {
    title: "Skapa grupp",
    what_is_a_team: "Vad är en grupp?",
    description:
      "Skapa en grupp för att organisera evenemang och hantera användare. Som administratör för ditt nätverk kan du skapa och hantera team. Skapa ett team för dig själv eller bjud in en teamchef som ska leda teamet. Användare i ditt nätverk kan ingå i flera team.",
    description_2:
      "Innan du tilldelar en användare till ett team måste du se till att de ingår i ditt nätverk. Du kan bjuda in användare till ditt nätverk <1>här</1>.",
    your_teams_text: "Dina grupper",
    add_team_title: "Gruppnamn",
    add_team_helperText:
      "Detta kommer att vara namnet på din grupp. Du kommer automatiskt att vara ägare till denna grupp.",
    create_team_button: "Skapa grupp",
    add_team_email: "Gruppens e-post",
    add_team_email_helperText:
      "Detta kommer att vara gruppens e-post. Detta måste vara en giltig e-postadress.",
  },

  manager: {
    dashboard: {
      events: "Evenemang",
      events_description:
        "Här kan du se de evenemang av de grupper du är en del av.",
      past_events: "Tidigare evenemang",
      upcoming_events: "Kommande evenemang",
      no_events: "Det finns inga event att visa.",
      no_events_create: "Skapa ert första evenemang",
    },

    danger: {
      warning_zone: "Varningszon",
      delete_warning: "Denna åtgärd kan inte ångras.",
    },

    setup: {
      title: "Onboarding",
      description: "Slutför din profil för att börja skapa evenemang.",
    },

    teams: {
      title: "{{name}} Grupp",
      subtitle:
        "En grupp är organisatören av ett evenemang. Ett nätverk består av flera grupper som kan skapa evenemang. En nätverksadministratör kan skapa och lägga till användare i en grupp. Nedan kan du se alla grupper i ditt nätverk, klicka på en grupp för att se mer information.",
    },

    onboarding: {
      welcomeMessage:
        "Välkommen till tesseras setup och onboarding för arrangörerer!",
      intro:
        "Välkommen till setup-sidan för arrangörer. Som evenemangsarrangör är du bara några steg från att konfigurera ditt betalningssystem genom <1>Surfboard Payments</1>. Följ instruktionerna och fyll i nödvändiga uppgifter för att slutföra onboarding-processen.",
      stepByStepGuide: "Steg för steg guide för att slutföra onboardingen",
      steps: {
        step1: {
          title: "Fyll i företagsuppgifter",
          description:
            "Vänligen ange följande företagsuppgifter i formuläret nedan. Denna information kommer att användas för att skapa ditt handlarkonto och onlinebutik.",
        },
        step2: {
          title: "Fyll i KYB-formuläret",
          active: "Klicka på länken nedan för att fylla i KYB-formuläret.",
          description:
            "tessera kommer att generera en Know Your Business (KYB)-länk åt dig från Surfboard Payments partnerportal. Denna länk kommer att skickas till din registrerade e-postadress.",
        },
        step3: {
          title: "Verifieringsprocess",
          description:
            "När ni har skickat in dina uppgifter kommer Surfboard Payments att verifiera ert företags legitimitet. Denna process tar vanligtvis 2-4 arbetsdagar. Du kommer att få ett meddelande via e-post när dina företagsuppgifter har godkänts.",
        },
        step4: {
          title: "Kontoskapande",
          description:
            "Efter godkännande kommer Surfboard Payments automatiskt att skapa ett handlarkonto och en onlinebutik för ditt företag. Du kommer att få inloggningsuppgifter och relevant information för att komma åt ditt handlarkonto.",
        },
      },
      customization: {
        title: "Anpassning",
        description:
          "Introduktionswebbappen kan anpassas för att matcha Dow Technologies färger och varumärke. Om du vill anpassa utseendet på din introduktionssida, vänligen kontakta vårt supportteam för hjälp.",
      },
      importantLinks: {
        title: "Viktiga länkar",
        description:
          "Om du vill lära dig mer om Surfboard Payments, vänligen besök följande länkar:",
        links: {
          partnersGuideTitle: "Partners Guide",
          partnersGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/partners",

          merchantsGuideTitle: "Handlarnas Guide",
          merchantsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/merchants/merchants-home",

          storesGuideTitle: "Butiksguide",
          storesGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/stores/stores-home",

          terminalsGuideTitle: "Terminalguide",
          terminalsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/terminals/terminals-home",

          onboardingRequirementsTitle: "Krav för onboarding",
          onboardingRequirementsURL:
            "https://developers.surfboardpayments.com/docs/concepts/onboarding#requirements-for-onboarding",

          onlinePaymentsGuideTitle: "Guide för onlinebetalningar",
          onlinePaymentsGuideURL:
            "https://developers.surfboardpayments.com/docs/guides/online-payments",
        },
      },
      needHelp: {
        title: "Behöver du hjälp?",
        description:
          "Om du har några frågor eller behöver hjälp under onboardingen, vänligen kontakta oss via mejl på <1>info@tesseratickets.se</1>.",
      },
      thankYou: "Tack för att du väljer tessera!",
    },

    settings: {
      general: {
        general_information: "Allmänt",
        title: "Allmänna inställningar",
        subtitle:
          "Anpassa utseendet på din kontrollpanel helt och hållet och gör den verkligen till din egen. Välj din huvudfärg, accentfärg och textfärg. Observera att dessa färger också kommer att användas för dina standardevenemangssidor.",
        main_color: "Huvudfärg",
        accent_color: "Accentfärg",
        text_color: "Textfärg",
        pick_color: "Välj {{color}}",
        close_color_picker: "Stäng {{color}}-väljaren",
        banner: "Omslagsbild",
        upload_banner: "Ladda upp omslagsbild",
        background_color: "Bakgrundsfärg",
        short_description: "Kort beskrivning",
        short_description_helperText:
          "Ge en kortfattad beskrivning av ditt nätverk.",
        description: "Beskrivning",
        description_helperText:
          "Detta kommer att visas på ditt nätverks företagssida. Markdown stöds.",
        network_look: "Nätverkets utseende",
        network_look_description:
          "Redigera dashboard färger och lägg till en omslagsbild som kommer att visas på din nätverkssida.",
      },
      users: {
        title: "Nätverksanvändare",
        description:
          "Här kan du bjuda in användare till ditt nätverk genom att skicka en e-postinbjudan till dem. Du kan också hantera rollerna för användarna i ditt nätverk. Användare kan tilldelas rollerna Super Admin, Admin eller Member. När en användare accepterar en inbjudan får han eller hon tillgång till nätverket och kan läggas till i team inom nätverket för att börja skapa evenemang.",
        no_users: "Det finns inga användare i detta nätverk.",
        delete_network: "Radera nätverk",
        delete_network_confirmation:
          "Är du säker på att du vill radera detta nätverk? Denna åtgärd kan inte ångras.",
        delete_network_confirmation_title: "Bekräfta nätverksradering",
        edit_network: "Redigera nätverk",
        invite_user: "Bjud in användare",
        invite_user_helperText:
          "Ange e-postadressen till den användare du vill bjuda in.",
        user_added_success: "Användare tillagd framgångsrikt!",
        select_role_placeholder: "Välj roll",
        role_super_admin: "Superadministratör",
        role_admin: "Administratör",
        role_member: "Medlem",
        invite_pending: "Inbjudan väntar",
        no_pending_invites: "Det finns inga väntande inbjudningar.",
      },
      subscription: {
        title: "Prenumeration",
        description:
          "Hantera din prenumerationsplan. Du kan uppgradera din plan för att få fler funktioner.",
        current_plan: "Nuvarande Plan",
        upgrade_plan: "Uppgradera Plan",
        upgrade_plan_description:
          "I BETA är uppgradering av planer inte tillgänglig.",
        downgrade_plan: "Nedgradera Plan",
        cancel_plan: "Avbryt Plan",
        cancel_plan_confirmation:
          "Är du säker på att du vill avbryta din plan? Denna åtgärd kan inte ångras.",
        cancel_plan_confirmation_title: "Bekräfta Avbrytning av Plan",
        payment_plan: "Betalningsplan",
        enabled_features: "Aktiverade Funktioner",
        enabled_features_description:
          "I BETA är alla funktioner aktiverade, här är en lista över alla funktioner du kan använda.",
      },
      customization: {
        title: "Anpassa utseende",
        description:
          "Anpassa utseendet av ditt nätverkets handlarportal. Detta kan du också göra genom handlarportalen.",
        background_color: "Bakgrundsfärg",
        background_color_helperText: "Välj en bakgrundsfärg för ditt nätverk.",
        brand_color: "Varumärkesfärg",
        brand_color_helperText: "Välj en varumärkesfärg för ditt nätverk.",
        accent_color: "Accentfärg",
        upload_logo: "Ladda upp logotyp",
        logo_helperText: "Ladda upp en logotyp för ditt nätverk.",
        upload_icon: "Ladda upp ikon",
        accent_color_helperText: "Välj en accentfärg för ditt nätverk.",
        rect_shape: "Rektangelform",
        rect_shape_helperText: "Välj formen på dina knappar.",
        rect_shapes: {
          rounded: "Rundad",
          edgy: "Kantig",
          pill: "Piller",
        },
        font_type: "Teckensnittstyp",
        font_type_helperText: "Välj teckensnittstyp för ditt nätverk.",
        font_types: {
          sans_serif: "Sans Serif",
          serif: "Serif",
          mono: "Monospace",
        },
        logo_url: "Logotyp URL",
        logo_url_helperText: "Ange URL för ditt nätverks logotyp.",
        icon_url: "Ikon URL",
        icon_url_helperText:
          "Ange URL för ditt nätverks ikon. Rekommenderad aspect ratio är 1:1.",
        save_settings: "Spara inställningar",
        save_logo: "Spara logotyp",
        reset_settings: "Återställ inställningar",
      },

      social_media: {
        title: "Social Media",
        subtitle:
          "Lägg till dina sociala medie-länkar för att göra det enklare för dina användare att hitta er.",
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        youtube: "YouTube",
        tiktok: "TikTok",
        website: "Hemsida",
      },
      api: {
        title: "API-nycklar",
        no_keys: "Du har inte skapat några API-nycklar än.",
        buttons: {
          create: "Skapa ny API-nyckel",
          cancel: "Avbryt",
          done: "Klar",
          revoke: "Återkalla",
          download: "Ladda ner",
        },
        table: {
          headers: {
            apiKey: "API-nyckel",
            description: "Beskrivning",
            permissions: "Behörigheter",
            rateLimit: "Frekvens Gräns",
            expiresAt: "Upphör",
            status: "Status",
          },
          status: {
            active: "Aktiv",
            revoked: "Återkallad",
          },
        },
        createModal: {
          title: "Skapa ny API-nyckel",
          form: {
            description: {
              label: "Beskrivning",
              placeholder: "Ange API-nyckel beskrivning",
              error: "Beskrivning krävs",
            },
            permissions: {
              label: "Behörigheter",
              placeholder: "Välj behörigheter",
              error: "Minst en behörighet krävs",
            },
            expiresAt: {
              label: "Upphör",
              placeholder: "åååå-MM-ddTHH:mm",
              error: "Utgångsdatum krävs",
            },
            rateLimit: {
              label: "Frekvens Gräns",
              requests: {
                label: "Förfrågningar",
                placeholder: "t.ex. 10",
                error: "Måste vara minst 1",
              },
              duration: {
                label: "Varaktighet",
                placeholder: "t.ex. 1m",
                error: "Varaktighet krävs",
              },
            },
          },
        },
        revokeModal: {
          title: "Bekräfta återkallning",
          message:
            "Är du säker på att du vill återkalla denna API-nyckel? Denna åtgärd kan inte ångras.",
        },
        successModal: {
          title: "API-nyckel skapades framgångsrikt",
          warning:
            "⚠️ Detta är den enda gången du kommer att se den hemliga nyckeln. Spara den på ett säkert ställe.",
          fields: {
            apiKey: "API-nyckel",
            secretKey: "Hemlig nyckel",
          },
        },
        messages: {
          fetchError: "Kunde inte hämta API-nycklar",
          createError: "Kunde inte skapa API-nyckel",
          revokeError: "Kunde inte återkalla API-nyckel",
          fetchPermissionsError: "Kunde inte hämta behörigheter",
          revokeSuccess: "API-nyckeln återkallades framgångsrikt",
          copySuccess: "Kopierad till urklipp!",
        },
      },
    },

    drawer: {
      title: "Nätverkspanel",
      events: "Evenemang",
      contact_database: "Kontaktdatabas",
      merchant_portal: "Handlarportal",
      business_page: "Företagssida",
      all_events: "Alla evenemang",
      teams: "Grupp",
      create_event: "Skapa evenemang",
      pin: "Fäst",
      unpin: "Avnåla",
      settings: {
        title: "Inställningar",
        general: "Allmänt",
        subscription: "Prenumeration",
        financial: "Ekonomi",
        users: "Användare",
        customize: "Utseende",
        social_media: "Sociala medier",
        api: "API-nycklar",
      },
      tessera_ai: "Tessera AI",
    },

    contact_database: {
      title: "Kontaktdatabas",
      description:
        "Kontaktdatabasen består av alla användare som interagerar med ditt nätverk. När en användare begär en biljett till ett av dina evenemang, kommer de att läggas till i kontakt databasen. Detta gör att du kan bjuda in användare till framtida evenemang.",
      import_button: "Importera",
      delete_button: "Radera",
      invite_button: "Bjud in",
      invite: {
        select_event: "Välj Evenemang",
        select_event_helperText:
          "Se till att du har redigerat evenemangets inbjudningsmall innan du bjuder in användare. Du kan göra detta genom att gå till <1>Hantera Evenemang > E-post > Mallar</1>. Välj nu det evenemang du vill bjuda in användare till.",
        unsubscribed_info:
          "Några användare kommer inte att mejlas då de har avregistrerat sig från att ta emot e-post från er.",
      },
      details: {
        title: "Kontaktinformation",
        close: "Stäng",
        contact_details: "Kontaktuppgifter",
        email: "E-post",
        subscribed: "Prenumererad",
        yes: "Ja",
        no: "Nej",
        last_added_info: "Senast tillagd information",
        last_added_reason: "Senast tillagd anledning",
        last_added_by: "Senast tillagd av",
        logs: "Loggar",
        load_more: "Ladda mer",
      },
      import: {
        title: "Importera Kontakter",
        close: "Stäng",
        description:
          "Du kan importera kontakter till din kontaktdatabas genom att ange deras e-postadresser nedan eller genom att ladda upp en CSV-fil. CSV-filen måste innehålla en enda kolumn med e-postadresserna till de kontakter som du vill importera",
        emails_label: "E-postadresser",
        emails_placeholder: "Ange e-postadresser separerade med kommatecken",
        import_button: "Importera",
      },
    },
    ai: {
      title: "Tessera AI-assistent (Alpha)",
      sessions: "Chattar",
      session: "Chatt",
      no_sessions: "Inga chattar att visa.",
      description:
        "Tessera AI är en assistent som kan hjälpa dig att komma nqigång med att skapa evenemang, hantera ditt nätverk och mycket mer. Du kan ställa frågor till Tessera AI som kommer att ge dig den information du behöver. Observera att Tessera AI fortfarande är under utveckling och vissa funktioner kanske inte är tillgängliga.",
      message: "Din förfrågan",
      message_placeholder: "Beskriv vad du vill göra...",
      submit: "Skicka",
      response: "Tessera AI",
      user: "Användare",
      success: "Förfrågan behandlades framgångsrikt",
      select_organization: "Välj organisation",
      new_chat: "Ny chatt",
      use_event_button: "Skapa evenemang",
      no_event_name: "Inget evenemangsnamn",
      mistakes:
        "Tessera AI kan göra misstag. Dubbelkolla alltid informationen.",
      welcome_message: {
        assistant_name: "Tessera AI",
        greeting:
          "👋 Hej! Jag är din Tessera AI-assistent. Jag kan hjälpa dig med:",
        features: {
          events: "Skapa och utforma nya evenemang",
          tickets:
            "Svara på frågor om deltagare och biljetter till ett evenemang",
          food: "Svara på frågor om deltagarnas matpreferenser och allergier",
          marketing: "Svara på frågor om marknadsföring och webbplatsstatistik",
        },
        language_support:
          "Jag kan hjälpa dig på både svenska och engelska. Hur kan jag hjälpa dig idag?",
      },
      landing_page: {
        prompt: "Be mig hjälpa dig designa din landningssida",
        placeholder:
          "t.ex., Skapa en modern hero-sektion med ett registreringsformulär",
      },
    },
    ai_create: {
      manual_create: "Du kan också <1>skapa manuellt</1>",
      title: "Skapa evenemang med AI",
      initial_prompt: "Berätta om evenemanget du vill skapa",
      input_placeholder: "Skriv ditt svar här...",
      thinking: "AI tänker...",
      restart_session: "Börja om",
      already_answered: "Jag har redan svarat på detta.",
      fields: {
        name: "Namn",
        category: "Kategori",
        description: "Beskrivning",
        date: "Datum",
        end_date: "Slutdatum",
        location: "Plats",
        organization_id: "Organisation",
        is_private: "Privat evenemang",
        collect_food_preferences: "Samla matpreferenser",
        language: "Språk",
        // Ticket Release fields
        open: "Öppnar",
        close: "Stänger",
        promo_code: "Kampanjkod",
        is_reserved: "Reserverad",
        save_template: "Spara som mall",
        tickets_available: "Tillgängliga biljetter",
        cancellation_policy: "Avbokningspolicy",
        notification_method: "Notifieringsmetod",
        max_tickets_per_user: "Max biljetter per användare",
        ticket_release_method: "Släppmetod",
        method_description: "Urvalskriterier",
        open_window_duration: "Lotterifönster varaktighet",
        payment_deadline: "Betalningsfrist",
        reserve_payment_duration: "Reservationsbetalning varaktighet",
        allocation_cut_off: "Tilldelningsstopp",
        // Ticket Type fields
        price: "Pris",
        // Boolean values
        yes: "Ja",
        no: "Nej",
      },
      sections: {
        event_details: "Evenemangsdetaljer",
        ticket_release: "Biljettsläpp",
        ticket_types: "Biljetttyper",
      },
      generated_event_details: "Genererade evenemangsdetaljer",
      event_details: "Evenemangsdetaljer",
      ticket_release: "Biljettsläpp",
      ticket_types: "Biljetttyper",
      buttons: {
        edit_event: "Redigera event",
        create_event: "Publicera event",
      },
      completion_message:
        "Ditt event är klart! Du kan ge fler instruktioner om du vill.",
    },
  },

  features: {
    limit_description:
      "Du har överskridit gränsen för {{feature}}, uppgradera ditt abonnemang för att få mer.",
  },

  manage_event: {
    title: "Hantera {{event_name}}",
    delete_button: "Radera {{event_name}}",
    view_button: "Visa eventsida",
    manage_button: "Hanera",
    ticket_orders: "Biljettbeställningar",
    ticket: "Biljetter",
    manage_ticket_releases: "Hantera biljettsläpp",
    allocate_tickets_button: "Tilldela biljetter",
    allocated_tickets: "Tilldelade biljetter",
    ticket_release_method_title: "Biljettsläppsmetod",
    ticket_release_ticket_info_title: "Biljettinformation",
    lottery_entered_ticket_requests: "Önskades under lotterifönstret",
    not_lottery_entered_ticket_requests: "Önskades utanför lotterifönstret",
    ticket_release_actions_title: "Biljettsläppsåtgärder",
    paid_tickets: "Betalda biljetter",
    not_yet_paid_tickets: "Ännu inte betalda biljetter",
    remaining_tickets: "Återstående biljetter",
    refunded_tickets: "Återbetalade biljetter",
    not_open: "Inte öppnat än",
    not_yet_open: "Not yet open",
    closed: "har stängts",
    open: "är öppen",
    the_ticket_release: "Biljettsläppet",
    deleted_ticket_requests: "Raderade biljettförfrågningar",
    deleted_tickets: "Raderade biljetter",
    check_allocated_reserve_tickets: "Försök att tilldela reservbiljetter",
    check_allocated_reserve_tickets_tooltip:
      "Genom att trycka på denna knapp kommer du manuellt försöka tilldela reserverade biljetter. Detta är användbart om du inte vill vänta på den automatiska tilldelningen.",
    reserve_tickets: "Reserverade biljetter",
    pay_within_hours: "Användare måste betala inom (timmar)*",
    ticket_release_time_title: "Biljettsläppets öppen och stängningstid",
    edit_payment_deadline: "Redigera betaldeadline",
    payment_deadline: "Betalningsfrist",
    payment_deadline_description:
      "Här kan du ange betalningsfristen för ditt evenemang. Observera att detta inte kommer att påverka biljetter som redan har tilldelats, dvs biljetter som redan har en betalningsfrist, detta kommer endast att påverka biljetter som tilldelas efter denna ändring.",
    payment_deadline_not_editable:
      "Betalningsfristen kan inte redigeras för denna biljettsläpp. Betalningsfristen ställs in när du tilldelar biljetterna.",
    payment_deadline_helperText:
      "När ska användare som fått ordinarie biljetter betala?",
    reserve_payment_duration: "Reservbiljett betaldeadline",
    reserve_payment_duration_helperText:
      "Hur lång tid har andvändare som tidigare haft en reservbiljett på sig att betala innan den ges till nästa person i kön?",
    reserve_payment_duration_text:
      "Reserverade biljetter som tilldelats kommer att ha en standard betalningsfrist på {{ days }} dagar, {{ hours }} timmar, {{ minutes }} minuter och {{ seconds }} sekunder.",
    allocate_tickets_confirm_title: "Bekräfta tilldelning av biljetter",
    allocate_tickets_warning:
      "Detta biljettsläpp är för närvarande öppet. Att tilldela biljetter nu kommer automatiskt att stänga biljettsläppet. Är du säker på att du vill tilldela biljetter nu?",
    allocate_tickets_confirm:
      "Är du säker på att du vill stänga detta biljettsläpp?",
    manage_tickets: "Hantera biljetter",
    tickets_available: "Biljetter tillgängliga",
    allocate_tickets_helperText:
      "Hur lång tid har användare på sig att betala för sina biljetter innan det ges till nästa person i kön?",
    delete_event_title: "Bekräfta radering av evenemang",
    delete_event_confirmation:
      "Är du säker på att du vill radera detta evenemang? Denna åtgärd kan inte ångras.",
    delete_event_confirmation_enter_text: "Skriv in 'delete' för att bekräfta.",
    manage_tickets_custom_event_form: "Anpassade evenemangsfält",
    add_ticket_release: "Ny biljettgrupp",

    delete_ticket_release_confirmation:
      "Är du säker på att du vill radera detta biljettsläpp? Denna åtgärd kan inte ångras.",

    ticket_releases: {
      title: "Hantera biljettsläpp",
    },

    breadcrumbs: {
      manage: "Hantera",
      edit: "Redigera",
      ticket_releases: "Biljettsläpp",
      event: "Evenemang",
      add_ons: "Tillägg",
      ticket_types: "Biljettbatcher",
      form: "Formulär",
      send_outs: "Utskick",
      tickets: "Biljetter",
      event_page: "Eventsida",
      emails: "E-post",
      email_templates: "E-postmallar",
    },

    overview: {
      title: "Översikt",
      site_visits: "Webbplatsbesök",
      unique_visitors: "Unika Besökare",
      num_ticket_orders: "Antal Biljettbeställningar",
      total_income: "Total Inkomst",
      total_ticket_orders: "Totala Biljettbeställningar",
      total_paid_tickets: "Totalt Betalda Biljetter",
      total_refunded_tickets: "Totalt Återbetalda Biljetter",
      total_revenue: "Total Intäkt",
      total_remaining_tickets: "Totalt Kvarvarande Biljetter",
      revenue_per_ticket: "Intäkt per Biljett",
      event_capacity: "Evenemangskapacitet",
      abandoned_orders: "Övergivna Beställningar",
      time_to_payment_avg: "Genomsnittlig Betalningstid",
      event_is_at_capacity: "Evenemanget är fullsatt",
      tickets_remaining: "biljetter kvar",
      from_creation_to_payment: "Från skapande till betalning",
      total_payable_tickets: "Totalt betalbara biljetter",
    },

    no_data: "Ingen Data",
    settings: {
      financial: {
        title: "Finansiella inställningar",
        description:
          "Här kan du hantera de finansiella inställningarna för ditt evenemang. Du kan lägga till dina bankuppgifter och se den finansiella statusen för ditt evenemang.",
      },

      domain: {
        title: "Domäninställningar",
        description:
          "Här kan du hantera domäninställningarna för ditt evenemang.",
      },
    },

    drawer: {
      title: "Eventpanel",
      settings: {
        title: "Inställningar",
        financial: "Finansiell",
        emails: "E-post",
        domains: "Domäner",
      },

      send_outs: {
        title: "Utskick",
        list: "Lista",
        new: "Ny",
      },

      manage: {
        title: "Hantera",
        attendees: "Deltagare",
        ticket_releases: "Biljettsläpp",
        check_in: "Checka in",
        form_responses: "Formulärsvar",
        overview: "Översikt",
      },

      edit: {
        event: "Evenemang",
        ticket_releases: "Biljettsläpp",
        ticket_types: "Biljettbatcher",
        form: "Formulär",
        event_page: "Eventsida",
      },

      economy: {
        title: "Ekonomi",
        pay_outs: "Utbetalningar",
        sales_report: "Försäljningsrapport",
      },

      emails: {
        title: "E-post",
        templates: "Mallar",
      },

      tools: {
        title: "Verktyg",
        seating_plan: "Bordsplacering",
        meal_management: "Matplanering",
        embed_event: "Integrera event",
      },

      is_not_pinned: "Fäst meny",
      is_pinned: "Lossa meny",
    },

    tickets: {
      title: "Hantera deltagare",
      no_tickets: "Inga deltagare att visa.",

      user: {
        info: "Deltagarinfo",
        food_preferences: "Matpreferenser",
        full_name: "För- & efternamn",
        username: "Användarnamn",
        id: "ID",
        email: "E-post",
        phone: "Telefon",
        is_guest: "Är gäst",
      },

      ticket_info: {
        id: "ID",
        title: "Biljettinfo",
        ticket_batch: "Biljettgrupp",
        ticket_release: "Biljettsläpp",
        requested_at: "Begärd vid",
        is_paid: "Är betald",
        allocated: "Tilldelad",
        purchasable_at: "Kan köpas vid",
        payment_deadline: "Betalningsdeadline",
        entered_into_lottery: "Med i lotteri",
        is_reserve: "Reserv",
        paid_at: "Betald vid",
        checked_in: "Incheckad",
        deleted_at: "Raderad vid",
        is_deleted: "Är raderad",
        deleted_reason: "Anledning",
        ticket_type: "Biljettyp",

        ticket_types: {
          ticket: "Biljett",
          ticket_request: "Biljettförfrågan",
        },

        add_ons: {
          title: "Tillägg",
          name: "Namn",
          price: "Pris",
          quantity: "Antal",
          contains_alcohol: "Innehåller alkohol",
        },

        edit: {
          title: "Redigera biljettinfo",
          checked_in_helperText: "Har användaren checkat in?",
          payment_deadline_helperText:
            "Användaren kommer att få ett e-postmeddelande med den uppdaterade betalningsfristen.",
        },
      },

      event_form: {
        title: "Formulärsvar",
      },

      ticket_actions: {
        title: "Biljettåtgärder",
        allocate: "Tilldela",
      },

      payment_info: {
        title: "Betalningsinfo",
        currency: "Valuta",
        amount: "Belopp",
        refunded: "Återbetalad",
        refunded_at: "Återbetalad vid",
        payment_method: "Betalningsmetod",
      },

      ticket_count: "{{count}} biljett(er) - Total kostnad: {{totalCost}} SEK",
      ticket: "Biljett",
      chips: {
        paid: "Betald",
        pay_now: "Betala nu",
        not_paid_in_time: "Inte betald i tid",
        refunded: "Återbetald",
        deleted: "Raderad",
        handled: "Hanterad",
        not_handled: "Inte hanterad",
        reserved: "Reserverad",
        checked_in: "Incheckad",
      },
    },

    economy: {
      title: "Eventekonomi",
      subtitle:
        "Här kan du se ekonomin för ditt evenemang. Du kan generera försäljningsrapporter och se den totala intäkten för ditt evenemang.",
      sales_reports: "Försäljningsrapporter",
      total_sales: "Total försäljning",
      tickets_sold: "Biljetter sålda",
      created_at: "Skapad vid",
      status: "Status",
      message: "Meddelande",
      download: "Ladda ner",
      no_reports: "Det finns inga försäljningsrapporter för detta evenemang.",
    },

    private_event: {
      title: "Detta är ett privat evenemang",
      subtitle:
        "Detta evenemang är privat. Dela länken nedan med de personer du vill bjuda in till evenemanget.",
      description: "Kopiera länken nedan för att dela med gäster.",
    },
    send_out: {
      title: "Utskick",
      new: "Nytt utskick",
      description:
        "Skicka ett e-postmeddelande till specifika gäster som har en biljett till detta evenemang.",
      preview: "Förhandsgranska",
      subject: "Ämne",
      subject_helperText: "Vad är ämnet för e-postmeddelandet?",
      message: "Meddelande",
      message_helperText:
        "Vad är innehållet i e-postmeddelandet? Markdown stöds.",
      preview_helperText:
        "Så här kommer e-postmeddelandet att se ut för användarna.",
      ticket_releases: "Välj biljettgrupp",
      ticket_releases_helperText:
        "Användarna av de valda biljettgrupperna kommer att få e-postmeddelandet.",
      filter_tickets: "Filtrera biljetter",
      filter_tickets_helperText:
        "Filtrera biljetter baserat på deras status. Du kan välja flera statusar.",
      num_users:
        "E-postmeddelandet kommer att skickas till {{numUsers}} användare.",
      recipients: "Mottagare",
      status_message: "Statusmeddelande",
      no_send_outs: "Det finns inga utskick för detta evenemang.",
    },
    edit: {
      title: "Redigera event",
      subtitle:
        "Här kan du redigera alla detaljer om ditt evenemang, samt ändra omslagsbilden.",
      event_details: {
        title: "Eventdetaljer",
        description:
          "Ändra detaljerna för ditt evenemang och klicka på 'Spara'.",
      },
      ticket_releases: {
        title: "Redigera biljettgrupper",
        edit_name: "Redigera {{name}}",
        subtitle:
          "Skapa biljettgrupper för ditt evenemang. Du kan skapa så många du vill, och du kan redigera dem senare.",
        select: "Välj en biljettgrupp för att redigera det.",
        add: "Skapa ny biljettgrupp",
        closed: "Stängt",
        add_subtitle: "Lägg till en ny biljettgrupp samt biljetter.",
        no_ticket_releases:
          "Det finns inga tillgängliga biljetter för detta event.",
        edit_ticket_types: "Redigera biljetter",
        add_helperText: "Vänligen välj en biljettgrupp att redigera.",

        edit_add_ons: "Redigera tillägg",
      },
      add_ons: {
        title: "Redigera biljettillägg",
        subtitle:
          "Lägg till tillägg till biljettsläppet. Du kan skapa så många du vill. Försök att vara specifik med namnet och beskrivningen av tillägget. Du kan också ange om tillägget kostar extra.",
        form_title: "Formulär för tillägg",
        form_subtitle:
          "Definiera dina tillägg här. Du kan lägga till så många du vill.",
        confirm_delete_title: "Bekräfta radering av tillägg",
        confirm_delete_text:
          "Är du säker på att du vill ta bort detta tillägg? Denna åtgärd kan inte ångras.",
      },

      ticket_types: {
        title: "Redigera biljettbatcher",
        ticket_details: "Biljettinformation",
        ticket_details_helperText:
          "Ändra detaljerna för dina biljettyper och klicka sedan på 'Spara'.",
        select_ticket_type: "Välj en biljettyp att redigera.",
      },

      event_page: {
        title: "Redigera eventsida",
        description:
          "Välj mellan en enkel eventsida eller anpassa den efter dina önskemål.",
        enable_description:
          "Du kan redigera eventsidan innan du aktiverar den.",
        enable: "Använd anpassad eventsida",
        enabled: "Aktiverad",
        disabled: "Inaktiverad",
        editor_title: "Redigeraren",
        editor_description1:
          "Sidredigeraren för evenemang är ett kraftfullt verktyg som hjälper dig skapa en anpassad evenemangsida. Lägg till text, bilder, videor och mer för att designa sidan som du vill. Vi tar hand om resten.",
        editor_description2:
          "Redigeraren uppdateras inte automatiskt om du redigerar dina evenemangsdetaljer eller organisationsdetaljer, vilket inkluderar beskrivningar eller färger. Det rekommenderas därför att skapa din anpassade evenemangsida efter att du har färdigställt dina evenemangsdetaljer. Om du ändrar detaljerna för ditt evenemang måste du uppdatera din anpassade evenemangsida manuellt, alternativt återställa sidan till standardläget för att visa de nya detaljerna, men detta kommer att ta bort anpassade komponenter som du har lagt till.",
        editor_description3:
          "Redigeraren är en helt ny funktion i BETA och har därför inte testats fullständigt. Om du stöter på några problem, vänligen skapa ett supportärende.",
        editor_button: "Gå till redigeraren",
      },

      banner: {
        title: "Redigera Omslagsbild",
        description:
          "Ladda upp en omslagsbild för ditt evenemang. Bilddimensionen 1920x1080 rekommenderas.",
        upload_button: "Ladda upp omslagsbild",
        current_banner: "Nuvarande omslagsbild",
        remove_button: "Ta bort Banner",
      },
    },

    form_field_responses: {
      list_view: "Listvy",
      table_view: "Tabellvy",
      no_form_responses: "Det finns inga formulärsvar för detta evenemang.",
      no_form_responses_for_ticket:
        "Det finns inga formulärsvar för denna biljett.",
    },

    email_templates: {
      title: "E-postmallar för evenemang",
      description: "Anpassa e-postmallar för ditt evenemang.",
      edit_email_template: "Redigera e-postmall",
      template_name: "Mallnamn",
      subject: "Ämne",
      body: "Innehåll",
      use_variables:
        "Fyll i innehållet i e-postmallen. Du kan använda variablerna nedan för att anpassa e-posten.",
      add_variable: "Lägg till {{variable}}",
      save_template: "Spara mall",
      validation: {
        name_required: "Mallnamn är obligatoriskt",
        subject_required: "Ämne är obligatoriskt",
        body_required: "Innehåll är obligatoriskt",
        body_invalid_variables:
          "Innehållet innehåller variabler som inte finns i de obligatoriska uppgifterna",
      },
    },

    meals: {
      title: "Måltidshantering",
      edit_meal: "Redigera Måltid",
      add_meal: "Lägg Till Ny Måltid",
      name: "Måltidsnamn",
      name_placeholder: "Ange måltidsnamn",
      description: "Beskrivning",
      description_placeholder: "Ange måltidsbeskrivning",
      tags_label: "Kostmärkningar",
      tags: seMealTags,
      select_tags: "Välj kostmärkningar",
      custom_tag: "Anpassad Märkning",
      custom_tag_placeholder: "Lägg till anpassad kostmärkning",
      add_tag: "Lägg Till Märkning",
      tag_exists: "Märkningen finns redan",
      update_meal: "Uppdatera Måltid",
      submit_meal: "Spara Måltid",
      search_placeholder: "Sök måltider...",
      dietary_conflicts: "Kostkonflikter",
      no_conflicts: "Inga kostkonflikter hittades",
      dietary_preferences: "Kostpreferenser",
      additional_notes: "Ytterligare Anteckningar",
      conflicts: {
        gluten: "Kan inte äta gluten",
        dairy: "Kräver mjölkfri eller laktosfri måltid",
        vegetarian: "Är vegetarian",
        vegan: "Är vegan",
        nuts: "Kan inte äta nötter",
        eggs: "Kan inte äta ägg",
        soy: "Kan inte äta soja",
        shellfish: "Kan inte äta skaldjur",
        kosher: "Inte kosher",
        halal: "Inte halal",
        meat: "Äter inte kött",
        other: "Övrigt",
        other_details: "Övrigt: {{details}}",
      },
    },

    embed_event: {
      title: "Bädda in evenemang",
      description:
        "Genom att bädda in ditt evenemang integrerar du det direkt på din webbplats. Kopiera koden nedan och klistra in den på din webbplats. Om du behöver mer anpassning, till exempel för att visa flera evenemang, kan du göra det genom att använda tesseras <1>Event API</1>.",
      domain_warning:
        "Innan du bäddar in, se till att din domän är tillagd i de tillåtna domänerna i <1>Domäninställningar</1>",
      add_script: "Inkludera CDN och HTML",
      add_js: "Lägg till Javascript",
      example: "Så här kan det se ut",
    },

    tools: {
      seating_plan: {
        save_button: "Spara bordsplacering",
        auto_seating: {
          button: "Automatisk bordsplacering",
          modal_title: "Konfiguration av automatisk bordsplacering",
          description: "Den automatiska bordsplaceringen kommer att:",
          features: {
            use_preferences:
              "Använd valda bordsplaceringsfrågor för att gruppera deltagare",
            keep_groups: "Försöka hålla grupper tillsammans vid samma bord",
            distribute: "Fördela deltagare jämnt över tillgängliga bord",
            consider_capacity: "Ta hänsyn till bordens kapacitet vid placering",
            privacy_note: "OBS: Använder INTE AI-metoder",
          },
          using_responses:
            "Använder svar från den valda bordsplaceringsfrågan för att skapa grupper.",
          select_question:
            "Vänligen välj en bordsplaceringsfråga att använda för gruppering.",
          custom_groups: {
            title: "Anpassade grupper (Valfritt)",
            description:
              "Lägg till anpassade gruppnamn som ska beaktas vid bordsplacering",
            input_placeholder: "Ange ett gruppnamn och tryck Enter",
            add_button: "Lägg till grupp",
          },
          actions: {
            cancel: "Avbryt",
            generate: "Generera bordsplacering",
          },
          success: "Bordsplaceringen har genererats!",
          unassigned_warning:
            "{{count}} deltagare kunde inte placeras vid bord.",
          error: "Kunde inte generera bordsplacering. Vänligen försök igen.",
        },
        create_table: {
          title: "Skapa nytt bord",
          type_label: "Bordstyp",
          type_round: "Runt",
          type_square: "Fyrkantigt",
          type_rectangle: "Rektangulärt",
          num_chairs: "Antal stolar",
          table_size: "Bordsstorlek",
          table_width: "Bordsbredd",
          table_height: "Bordshöjd",
          short_side_seats: "Platser på kortsidan",
          yes: "Ja",
          no: "Nej",
          add_button: "Lägg till bord",
        },
        edit_table: {
          title: "Redigera bord",
          assigned_attendees: "Tilldelade deltagare",
          drag_drop_hint:
            "Dra och släpp deltagare här för att tilldela till detta bord",
        },
        seating_preference: {
          label: "Välj bordsplaceringsfrågor",
          description:
            "Välj vilka formulärfält som ska användas för bordsplacering",
          no_fields: "Inga bordsplaceringsfrågor tillgängliga",
          placeholder: "Välj bordsplaceringsfrågor",
        },
        attendees: {
          unassigned: "Otilldelade deltagare",
          search_placeholder: "Sök deltagare...",
          search_helper_text: "Sök på namn, e-post eller preferenser",
          no_attendees: "Inga otilldelade deltagare",
        },
        floor_plan: {
          title: "Planritning",
          description:
            "Ladda upp en planritning för att underlätta bordsplaceringen",
          upload_button: "Ladda upp planritning",
          drag_drop: "Dra och släpp en bild här eller klicka för att välja",
          file_types: "Stödda format: PNG, JPG, JPEG",
          error: "Fel vid uppladdning av planritning",
          success: "Planritning uppladdad",
        },
      },
    },
  },

  event_form_fields: {
    title: "Evenemangsformulär",
    description:
      "Evenemangsarrangören har begärt ytterligare information från dig. Se information från evenemangsarrangören nedan.",
    accept_terms_and_conditions:
      "Genom att skicka in detta formulär godkänner du att dela ovanstående information med evenemangsarrangören för att de ska kunna planera evenemanget. Informationen kommer att behandlas i enlighet med kapitlens informationsbehandlingspolicy.",
    no_form_fields: "Oj då! Det finns inget formulär för detta evenemang.",
    ticket_number: "Biljett {{number}}",
    view_form_fields: "Visa formulär",
    hide_form_fields: "Dölj formulär",
    save_response: "Spara svar",
    ticket_number_description: "Sriv in detaljer för biljett {{number}} nedan.",
    existing_image: "Bild redan tillgänglig",
  },

  event: {
    list_title: "Evenemang",
    tickets: "Biljetter",
    reserved: "Reserverad",
    ticket_releases: "Biljettsläpp",
    event_by: "Evenemang av",
    location: "Plats",
    date_and_time: "Datum & Tid",
    organizer: "Arrangör",
    time: "Tid",
    description: "Om eventet",
    attendees: "{{count}} deltagare",

    no_ticket_releases: "Det finns inga biljettsläpp för detta evenemang.",
    promo_code_helperText:
      "Ange promokoden för att få tillgång till reserverade biljetter.",
    promo_code_applied: "Tillämpad promokod",

    contact_organizers: "Kontakta arrangörerna",
    ticket_request_success_title: "Biljettförfrågan skickad!",
    ticket_request_success_description:
      "Du kan också fylla i denna information senare om du vill. Du kan göra det <1>här</1>.",
    ticket_releases_description:
      "Här kan du se alla biljettsläpp för eventet. Du kan begära biljetter för varje biljettsläpp. Om du har en kampanjkod, bläddra till botten av vyn för att ange den.",
    ticket_order_pay_direct_form_later:
      "Du kan fylla i formuläret efter att du har betalat för din biljett.",
    continue_pay: "Fortätta till betalning",
    continue_ticket_orders: "Visa biljettförfrågan",

    categories: {
      event: "Evenemang",
      workshop: "Workshop",
      lecture: "Föreläsning",
      party: "Fest",
      conference: "Konferens",
      concert: "Konsert",
      other: "Övrigt",
    },
    status: {
      scheduled: "Planerad",
      upcoming: "Kommande",
      past: "Passerad",
    },
    check_in: {
      scan_ticket_title: "Scanna biljett",
      scan_ticket_instructions: "Skanna QR-koden för att checka in.",
      loading: "Laddar...",
      manual_check_in_button: "check-in",
      search_tickets: "Sök efter biljett",
      checked_in: "Incheckad",
      hide_checked_in: "Dölj incheckade",
      search_tickets_helperText: "Skriv in namn, e-post eller biljett ID.",
      ready_to_scan: "Redo att scanna",
      no_camera_permission: "Kameraåtkomst nekad",
    },
    ticket_release: {
      closed: "Biljettsläppet har stängts",
      tickets_available_in: "Biljetter släpps {{time}}",
      tickets_available_in_format: "MMMM d, yyyy 'kl' HH:mm",
      tickets_available_for: "Biljettsläppet stänger",
      method: "Detta biljettsläpp använder <1>{{method}}</1>",
      sold_out: "Slutsålt",
      second: "sekund(er)",
      minute: "minut(er)",
      hour: "timme(ar)",
      day: "dag(ar)",
      week: "vecka(or)",
      month: "månad(er)",
      year: "år",
      month_short: "mo",
      day_short: "d",
      hour_short: "h",
      minute_short: "m",
      second_short: "s",
      max_tickets_per_user: "{{count}} Biljetter/person",
      cancellation: "Avbokning",
      requires_account: "Konto krävs",
      requires_account_tooltip:
        "Du måste vara inloggad för att begära biljetter till detta biljettsläpp.",
      max_tickets_error:
        "Du kan bara köpa/begära upp till {{count}} biljetter.",
      switch_release: {
        title: "Byt Biljettsläpp",
        confirm:
          "Du kan endast köpa eller begära biljetter från ett släpp åt gången. Är du säker på att du vill byta?",
      },
      method_info: {
        fcfsl:
          "Förfrågningar som görs inom de första <1>{{duration}}</1> minuter av biljettsläppet kommer att delta i lotteriet.",
      },
      remove_reminder: "Ta bort påminnelse",
      set_reminder: "Sätt påminnelse 10 minuter innan biljettsläppet öppnar",
      reserved: "Reserverad",
      no_tickets: "Det finns inga biljetter tillgängliga.",
      legal: {
        privacy_consent:
          "Genom att begära/köpa en biljett godkänner du att dela dina uppgifter med evenemangsarrangören i enlighet med Tesseras",
        terms_consent: "Jag har läst och godkänner Tesseras",
        information_processing_policy_info:
          "Genom att begära/köpa en biljett godkänner du att dela dina uppgifter med evenemangsarrangören i enlighet med Tesseras <1>Integritetspolicy</1>. Du godkänner också Tesseras <2>Återbetalningspolicy</2> och <3>Villkor</3>.",
      },
      checkout: {
        title: "Biljettdetaljer",
        overview: "Översikt",
        what_is_a_request_title: "Vad är en biljettförfrågan?",
        what_is_a_request:
          "När du gör en förfrågan är du inte garanterad att få biljetterna. Tilldel§§ningen görs enligt den metod som beskrivs i släppbeskrivningen.",
        total: "Summa",
        subtotal: "Delsumma",
        tax: "Moms ({{vat}}%)",
        transaction_fee: "Transaktionsavgift",
        tax_amount: "Moms",
        fee_amount: "Transaktionsavgift",
        ticket: "Biljett",
        proceed: "Fortsätt",
        view_cart: "Visa kundvagn",
        cart_summary: "Din kundvagn",
        cart_description:
          "Kontrollera att biljetterna nedan är korrekta innan du fortsätter till betalning.",
        proceed_request: "Begär biljetter",
        proceed_payment: "Till kassan",
        free_tickets: "Gratis biljetter",
        register: "Registrera dig",
        complete_signup: "Slutför din anmälan",
        free: "Gratis",
        taxes_fees_next_step: "Öppna kundvagnen för att se skatt och avgifter",
        cart_description_payment:
          "Verifiera att biljetterna nedan är korrekta innan du fortsätter till betalning.",
        cart_description_request:
          "Verifiera att biljetterna nedan är korrekta innan du fortsätter med din biljettförfrågan.",
        select: "Välj",
      },
      add_ons: {
        title: "Biljettillägg",
        description: "Här kan du lägga till tillägg till din biljett.",
        max_quantity: "Max kvantitet",
        contains_alcohol: "Innehåller alkohol",
        view_add_ons: "Visa biljettillägg",
        ticket_number: "Biljett {{number}}",
      },
      request_process: {
        complete_ticket_request: "Slutför önska biljetter",
        account_required_description:
          "För att begära en biljett till detta evenemang måste du ha ett konto. Vänligen logga in eller skapa ett konto.",
        already_have_an_account: "Har du redan ett konto?",
        continue: "Fyll i dina uppgifter för att fortsätta",
        form: {
          first_name: "Förnamn",
          last_name: "Efternamn",
          email: "E-post",
          phone_number: "Telefonnummer (valfritt)",
          password: "Lösenord",
          password_repeat: "Upprepa lösenord",
          button_save_account: "Spara konto",
          button_save_account_helperText:
            "Tessera sparar dina uppgifter och skapar ett konto åt dig.",
          button_sign_in: "Logga in",
          button_sign_up: "Registrera dig",
          button_continue_as_guest: "Fortsätt som gäst",
        },
      },
    },
    actions: {
      add_to_calendar: "Lägg till i kalender",
      share: "Dela",
      calendar: {
        google: "Google Kalender",
        outlook: "Outlook",
        ical: "iCal",
      },
    },

    loading: {
      event: "Laddar evenemang...",
      create_order: "Skapar din beställning...",
      create_request: "Skapar din biljettförfrågan...",
      fetch_order: "Hämtar din beställning...",
      fetch_request: "Hämtar din biljettförfrågan...",
      load_order: "Laddar din beställning...",
      load_request: "Laddar din biljettförfrågan...",
      just_a_moment: "Ett ögonblick...",
      redirect_payment: "Omdirigerar till betalning...",
      saving_request: "Sparar din biljettförfrågan...",
      registering_ticket: "Registrerar din biljett...",
    },
    quick_tips: {
      title: "Kom igång med ditt event",
      description:
        "Ditt event har skapats! Här är några tips för att komma igång:",
      edit_event: "Redigera event",
      add_ticket_release: "Ny biljettgrupp",
      manage_tickets: "Hantera biljetter",
      share_event: "Dela evenemanget",
      create_addons: "Skapa biljettillägg",
      invite_attendees: "Bjud in deltagare",
      setup_domains: "Sätt upp domäner",
      create_meal_plan: "Skapa måltider",
    },
    promo_code_title: "Promokod",
  },

  ticket_request: {
    description:
      "Din biljettförfrågan har skickats! Du kommer att få ett e-postmeddelande när din biljettförfrågan har behandlats.",
    cost_overview: "Prisöversikt",
    cancel_ticket_request_button: "Avbryt",
    go_to_tickets_button: "Gå till biljetter",
    cancel_ticket_request_confirm_title:
      "Bekräfta avbrytande av biljettförfrågan",
    handled: "Biljettförfrågan konverterad till biljett",
    deleted: "Raderad biljettförfrågan",
    ticket_request: "Biljettförfrågan",
    cancel_ticket_request_confirm:
      "Är du säker på att du vill avbryta denna biljettförfrågan? Denna åtgärd kan inte ångras.",
    cancelled_at: "Raderades vid {{date}}",
  },

  ticket_release_method: {
    first_come_first_served_title: "Först till kvarn",
    first_come_first_served_description:
      "Först till kvarn-lotteriet är en metod för biljettsläpp där personer som önskar en biljett inom en angiven tidsram deltar i ett lotteri. När biljetter tilldelas, placeras alla biljettförfråganden inom denna tidsram i ett lotteri och vinnarna väljs slumpmässigt. Vinnarna får en biljett och resten placeras på väntelistan. Alla som önskar en biljett efter den angivna tidsramen placeras på väntelistan, om inte lotteriet är ofullständigt. Om lotteriet inte är fullt, ges de återstående biljetterna till personerna på väntelistan i den ordning de önskade biljetten.",
  },

  tickets: {
    cost_overview: "Prisöversikt",
    hide_cost_overview: "Dölj prisöversikt",
    view_cost_overview: "Visa prisöversikt",
    confirm_cancel_ticket_title: "Bekräfta borttagande av biljett",
    confirmed_ticket:
      "Din biljett har bekräftats! Nu är det dags att betala för din biljett. Du kan betala för din biljett genom att klicka på knappen nedan. Om du inte betalar för din biljett före <1>{{payBefore}}</1> kommer din biljett att ges till nästa person i kön.",
    reserve_ticket:
      "Tyvärr fick du en reservbiljett till detta evenemang. Du kommer att meddelas om en biljett blir tillgänglig.",
    has_paid: "Du har betalat för din biljett",
    is_registered: "Du är registrerad för eventet",
    unregister_button: "Avregistrera dig från eventet",
    confirm_unregister_title: "Bekräfta avregistrering",
    confirm_unregister_text:
      "Är du säker på att du vill avregistrera dig från detta evenemang? Denna åtgärd kan inte ångras.",
    has_refunded: "Din biljett har återbetalats.",
    request_refund:
      "Du kan begära en återbetalning genom att kontakta arrangören.",
    no_refund: "Tyvärr, det finns ingen återbetalning för denna biljett.",

    cancel_ticket_request_button: "Avbryt",
    cancel_ticket_button: "Jag vill inte längre delta",
    confirm_cancel_ticket_request_title:
      "Bekräfta avbrytande av biljettförfrågan",
    reserve_number: "Du har plats <1>{{number}}</1> på reservlistan.",
    leave_reserve_list_text: "Lämna reservlistan",
    paid_ticket:
      "Du har betalat för din biljett! Vi ser fram emot att se dig på evenemanget. Kvittot kommer att skickas till din e-post.",
    confirm_cancel_reserve_ticket_text:
      "Är du säker på att du vill avbryta din biljett? Du kommer inte att kunna få tillbaka din biljett och denna åtgärd kan inte ångras!",
    confirm_cancel_ticket_text:
      "Är du säker på att du vill lämna reservlistan och avbryta din biljett? Du kommer inte att kunna få tillbaka din biljett och denna åtgärd kan inte ångras!",
    pay_button: "Betala",
    not_paid_on_time:
      "Du har inte betalat för din biljett i tid, därför har din biljett gått till nästa person i kön.",
    total_cost: "Totalt",
    edit_details: "Redigera detaljer",
    payment: {
      title: "Bekräfta ditt biljettköp",
      pay_now: "Betala {{price}} SEK",
      description: "Här kan du betala för din biljett.",
    },
    qr_code: {
      description:
        "Detta är din QR-kod. Var vänlig ha den redo när du anländer till evenemanget.",
      already_checked_in: "Du har redan checkat in.",
      wallet: {
        add_to_google_wallet: "Spara till Google Wallet",
        add_to_apple_wallet: "Spara till Apple Wallet",
      },
    },

    attendee_details: {
      title: "Ange deltagaruppgifter",
      description: "Vänligen fyll i uppgifterna för varje biljettinnehavare",
      primary_attendee: "Primär deltagare",
      attendee_number: "Deltagare {{number}}", // Uses number parameter
      form_fields: "Evenemangsformulär",
      form_fields_required:
        "Vänligen fyll i alla obligatoriska fält i evenemangsformuläret innan du fortsätter.",
      form_fields_save_required:
        "Vänligen spara formulärfältet innan du fortsätter.",
      fields: {
        first_name: "Förnamn",
        last_name: "Efternamn",
        email: "E-post",
        phone: {
          label: "Telefonnummer",
          optional: "(Valfritt)",
        },
      },
      food_preferences: {
        title: "Allergier & Kostpreferenser",
        additional_info: "Ytterligare information",
        edit_in_profile: "Redigera i profil",
        additional_info_placeholder:
          "Vänligen ange eventuella ytterligare kostbehov eller allergier",
        agree_text_1:
          "Jag och övriga deltagare godkänner Tesseras <1>Integritetspolicy</1>.",
        agree_text_2:
          "Jag tillåter arrangören att behandla min och andra deltagares information.",

        options: {
          gluten_intolerant: "Glutenintolerant",
          lactose_intolerant: "Laktosintolerant",
          vegetarian: "Vegetarian",
          vegan: "Vegan",
          nut_allergy: "Nötallergi",
          shellfish_allergy: "Skaldjursallergi",
          egg_allergy: "Äggallergi",
          soy_allergy: "Sojaallergi",
          halal: "Halal",
          kosher: "Kosher",
        },
      },
      buttons: {
        continue: "Fortsätt",
        save: "Spara ändringar",
        save_attendees: "Spara deltagare",
      },
    },
  },

  customer: {
    login: "Logga in",
    signup: "Registrera dig",
    oauth: "Du kan också logga in med",
    info: {
      subtitle: "Extern inloggning och registrering.",
      description:
        "Välkommen till tessera! Genom att skapa ett konto samlas all din information på ett ställe. Begär enkelt biljetter, hantera dina biljetter och mer. Obs: Du behöver inte ett konto för att begära biljetter till vissa evenemang.",
      forgot_password: "Glömt lösenord?",
      dont_have_an_account: "Har du inget konto? Registrera dig!",
    },
    form: {
      first_name: "Förnamn",
      last_name: "Efternamn",
      username: "Användarnamn",
      email: "E-post",
      password: "Lösenord",
      password_repeat: "Upprepa lösenord",
      button_signup: "Registrera dig",
      button_login: "Logga in",
    },
    accept_terms: {
      first: "Jag godkänner tesseras",
      privacy_policy: "Integritetspolicyn",
      terms_of_use: "Villkoren",
      acceptable_use_policy: "Användarvillkoren",
      cookie_policy: "Cookiepolicyn",
    },
    forgot_password: {
      title: "Begär nytt lösenord",
      description:
        "Ange e-postadressen som är kopplad till ditt konto för att få en länk för att återställa ditt lösenord.",
    },

    wait_verify_email: {
      title: "Verifiera din e-postadress",
      description:
        "Vi har skickat en verifieringslänk till din e-postadress. Klicka på länken för att verifiera din e-postadress.",
    },

    resend: {
      title: "Skicka verifieringslänk",
      toast_description:
        "Det verkar som att du inte har verifierat din e-postadress.",
      toast_button: "Skicka igen",
      description:
        "Om du inte har fått ett e-postmeddelande, klicka på knappen nedan för att skicka en ny verifieringslänk.",
    },
  },

  auth: {
    other_login_options: "Eller logga in med",
    login_with_kth: "KTH Login",
    alternative_login_methods: "Alternativa inloggningsmetoder",
  },

  legal: {
    privacy_policy: "Integritetspolicy",
    terms: "Villkor",
    return: "Återbetalningspolicy",
  },

  modal: {
    sure_quit: "Är du säker på att du vill avsluta?",
  },

  common: {
    copy: "Kopiera",
    coming_soon: "Kommer snart...",
    copied_to_clipboard: "Kopierad till urklipp",
    you: "Du",
    back: "Tillbaka",
    cancel: "Avbryt",
    show_all: "Visa alla",
    show_less: "Visa mindre",
    search: "Sök...",
    created: "Skapad",
    made_at: "Gjord",
    updated: "Uppdaterad",
    learn_more: "Läs mer",
    private_event: "Privat evenemang",
    mobile_warning:
      "Välkommen till tessera! Vi ser att du använder en mobil enhet. Vissa delar av webbplatsen kanske inte är optimerade för mobila enheter. Men att begära och visa biljetter bör fungera som förväntat. Om du är en evenemangsarrangör rekommenderar vi att du använder en skrivbordsenhet.",
    show_more: "Visa mer",
    hour_one: "timme",
    hour_other: "timmar",
    minute_one: "minut",
    minute_other: "minuter",
    edit: "Redigera",
    settings: "Inställningar",
    use: "Använd",
    members: "Medlemmar",
    events: "Evenemang",
    view_details: "Visa detaljer",
    delete: "Radera",
    create_event: "Publicera event",
    of: "av",
    or: "eller",
    days_ago: "dagar sedan",
    since: "sedan",
    lets_start: "Kom igång",
    yes: "Ja",
    no: "Nej",
    ticket_id: "Biljett ID",
    customize: "Anpassa",
  },

  beta: {
    coming_soon: "Kommer snart...",
  },

  footer: {
    about_title: "Om",
    about_content:
      "tessera är en plattform som gör biljettsläpp och biljettadministration enkelt.",
    quick_links_title: "Snabblänkar",
    home: "Hem",
    events: "Evenemang",
    profile: "Profil",
    report_an_issue_title: "Rapportera ett problem",
    made_by: "tessera är byggt av <1>Lucas Dow</1>.",
    report_an_issue_content:
      "Om något inte fungerar, eller om du har ett förslag, kan du <1>Skapa ett problem på Github</1>.",
    contact_title: "Kontakt",
    follow_us: "Följ oss",
    support_title: "Support",
    legal_title: "Legal",
  },

  book_demo: {
    button_text: "Boka gratis demo",
    title: "Boka en demo",
    description:
      "Fyll i formuläret nedan för att boka en demo. Vi kontaktar dig inom några arbetsdagar för att boka en tid som passar dig.",
    name: "Namn",
    name_helperText: "Vad heter du?",
    email: "E-post",
    email_helperText: "Vad är din e-postadress?",
    message: "Meddelande",
    message_placeholder: "Hur kan tessera förenkla era evenemang?",
  },

  main_page: {
    hero: {
      title: "Ditt event. <1>Förenklat.</1>",
    },
    phrases: {
      base: "Eventhantering är nu",
      keywords: {
        "1": "tessera",
        "2": "lätt",
        "3": "smidigt",
        "4": "sömlöst",
        "5": "okomplicerat",
        "6": "stressfritt",
        "7": "innovativt",
        "8": "säkert",
        "9": "lätt",
      },
    },
    use_cases: {
      title: "Perfekt För",
      industry_solutions: {
        title: "Skräddarsydda <1>Eventlösningar</1> För Alla Organisationer",
        description:
          "tessera är utformad för att möta de unika behoven hos organisationer inom olika sektorer. Från studentföreningar till företagsevenemang anpassar sig vår plattform till dina specifika krav och eventmål.",
      },
      nonprofit: "Ideella Organisationer",
      agencies: "Eventbyråer",
      universities: "Universitetsföreningar",
      corporate: "Företagsevenemang",
      banquets: "Banketter & Fester",
      conferences: "Konferenser & Shower",
      descriptions: {
        nonprofit:
          "Till ett reducerat pris kan du som driver en ideell organisation fokusera på att skapa fantastiska event medans tessera hanterar resten.",
        agencies:
          "Förstärk nätverkande och engagemang för personal, volontärer, medlemmar och donatorer med toppmöten och konferenser.",
        universities:
          "Öka studentengagemanget med virtuella karriärmässor, akademiska konferenser och andra campus-evenemang.",
        corporate:
          "Organisera och genomför effektfulla företagsevenemang, från globala produktlanseringar till teambuilding-aktiviteter.",
        banquets:
          "Arrangera minnesvärda firanden och formella middagar med smidig biljetthantering och gästhantering.",
        conferences:
          "Koordinera konferenser och shower med flera spår, reserverade platser och VIP-paket.",
      },
    },

    not_a_pain: "Biljettsläpp ska inte vara jobbiga!",
    welcome: "{{name}}, välkommen till",
    learn_how_button: "Lär dig hur",
    get_in_touch_button: "Kontakta",
    key_features: {
      title:
        "Enklare <1>Eventhantering</1> som gör att du kan fokusera på att skapa fantastiska event.",
      description:
        "Vi säkerställer att ditt event blir en succé genom att tillhandahålla en plattform med verktygen du behöver för att göra det enkelt.",
      feature1: {
        title: "Innovativ biljettfördelning",
        description:
          "tessera erbjuder flexibla biljettalternativ, inklusive lotterier och först-till-kvarn-metoder. Detta säkerställer en rättvis och transparent fördelning, oavsett eventets storlek.",
      },
      feature2: {
        title: "Enkel eventhantering",
        description:
          "Att skapa och hantera evenemang har aldrig varit enklare. Med tesseras effektiva system kan du sätta upp evenemang, släppa biljetter och hantera deltagare med bara några klick.",
      },
      feature3: {
        title: "Helt anpassningsbara eventsidor",
        description:
          "Skapa vackra, varumärkesanpassade eventsidor med tesseras anpassningsverktyg. Justera färger, logotyper och layouter för att få ditt evenemang att sticka ut och spegla din unika identitet.",
      },
      feature4: {
        title: "AI-driven eventskapande",
        description:
          "Skapa event på några minuter med vår AI-assistent. Få omedelbara svar om ditt event, försäljning, deltagare och mer. Låt AI hantera komplexiteten medan du fokuserar på det som är viktigt.",
      },
      feature5: {
        title: "Komplex Teamhantering",
        description:
          "Stöd för organisationer med komplexa hierarkier. Skapa team, definiera roller och hantera behörigheter enkelt. Perfekt för stora organisationer med flera avdelningar.",
      },
      feature6: {
        title: "Fullständig anpassning",
        description:
          "Anpassa varje aspekt av ditt event. Från formulärbyggare till landningssidor, biljetttyper till tillägg - gör ditt event unikt och skräddarsytt efter dina behov.",
      },
    },
    event_dashboard: {
      title: "Tesseras <1>eventöversikt</1>",
      description:
        "Hantera biljettsläpp, biljetter och deltagare på ett ställe. Skicka e-post, se statistik och mycket mer.",
    },
    support: {
      title: "Behöver du hjälp?",
      description:
        "Besök vår <1>FAQ</1> eller <2>Supportcenter</2> för att skapa en supportbiljett.",
    },
    social_proof: {
      title: "Vad arrangörer säger om Tessera",
      cta: "Registrera Dig Nu!",
      ready: "Är du redo att komma igång?",
      description:
        "Registrera dig idag och börja använda vår gratisplan, eller boka en demo för att lära dig mer om våra premiumplaner.",
      testimonials: {
        chip: "Intyg",
        lydia_brorsson: {
          name: "Lydia Brorsson",
          title: "Student, KTH",
          quote:
            "Jag använde tessera till datasektionens vårbal. Det är en stor sittning med många olika biljetttyper, samt högt tryck på biljetterna när de släpps. tessera fungerade felfritt och var enkelt att använda då hemsidan är intuitiv. Dessutom hanterar tessera reservplatser smidigt, vilket underlättade mycket.",
          description:
            "Ansvarig för Datasektionens Vårbal 2024, med 200 gäster.",
        },
        erik_nordlof: {
          name: "Erik Nordlöf",
          title: "Student, KTH",
          quote:
            "Jag tyckte tessera funkade väldigt bra. Att skapa biljetter var enkelt, speciellt för särskilt inbjudna gäster. System fungerade väl som helhet och gästerna tyckte det var lättanvändt.",
          description:
            "Ansvarig för Oscar II's bal på THS med över 200 gäster.",
        },
        abhinav_sasikumar: {
          name: "Abhinav Sasikumar",
          title: "Student, KTH",
          quote:
            "Tessera hjälpte oss att kunna ta ut betalningarna på ett smidigt sätt, även om vi hade initialt använde ett vanligt formulär. Detta underlättade även kontrollen av biljetter på plats, till skillnad från att behöva skicka ut betalningslänkar manuellt och manuellt dra ut listor av folk som har betalat för att kontrollera betalning. Eftersom vi hade många biljettyper blev det även väldigt smidigt att kunna ha olika promocodes och olika allokeringsmetoder för olika biljettsläpp!",
          description: "Eventsansvarig för D-Dagen 2024 med över 300 gäster.",
        },
        love_belin: {
          name: "Love Belin",
          title: "Student, KTH",
          quote:
            "Jag använde mig av Tessera för första gången för en sittning som DKM (Datas Klubbmästeri) hade nu under hösten. Vi har tidigare använt oss av lite olika sätt för att sälja biljetter för våra event, men nu när jag testat Tessera har jag svårt att argumentera för att jag inte skulle fortsätta med det. Jag fick en bra genomgång av Lucas när jag skulle sätta igång och kunde därefter sätta upp allt väldigt flitigt själv. Efter man har satt upp allt inför biljettsläpp så finns det inte så mycket administrativt arbete att göra då det finns en otrolig mängd inbyggda funktioner som gör allt mycket lättare. Hemsidan är väldigt intuitiv och lätt att förstå. Rekommenderar Tessera väldigt starkt för biljettsläpp för events.",
          description: "Huvudansvarig för event med ca 100 gäster.",
        },
      },
    },
    login_page: {
      secure_spot: "För att säkra din plats.",
      for_business_inquiries:
        "För företagsrelaterade förfrågningar, vänligen kontakta <1>Lucas Dow</1>, skaparen av tessera",
    },
    page_description: {
      what_title: "Vad är tessera?",
      what: "tessera är en plattform som gör biljettförsäljning enkel. Att köpa\
            biljetter ska inte vara svårt! tessera gör det enkelt att skapa\
            biljettsläpp och hantera dem. Evenemangsarrangörer kan enkelt\
            se dina matpreferenser, allergier och mer för att göra din upplevelse\
            så trevlig som möjligt, utan krångel. Ditt konto är automatiskt knutet\
            till dina biljetter, så det finns inget behov av att konstant fylla i google-formulär\
            eller något sådant.",
      in_beta_title: "tessera BETA",
      in_beta:
        "tessera är just nu i BETA. Detta innebär att tessera fortfarande\
        utvecklas och förbättras. Om du eller din organisation är intresserade\
        av att använda tessera BETA kan vi sätta upp en grupp för dig kostnadsfritt. På detta sätt kan du skapa\
        evenemang och hantera biljettsläpp. Samtidigt kan du ge feedback\
        och hjälpa oss att förbättra tessera. Ta kontakt för att komma igång!",
      how_title: "Hur fungerar det?",
      how: "För att skapa ett biljettsläpp till ditt evenemang måste du först bli en evenemangsarrangör genom att skapa ett team. För närvarande skapas team genom att kontakta oss. När du har skapat ett team kan du börja skapa och hantera biljettsläpp. Du kan bjuda in andra användare till ditt team så att de kan hjälpa till att hantera biljettsläppen. Som användare kan du se offentliga evenemang i evenemangsfliken. tessera fungerar inte som andra biljettplattformar där du måste betala för en biljett direkt. tessera erbjuder olika sätt att distribuera biljetter på, som ett lotterisystem eller ett först till kvarn system. Därför köper inte en användare en biljett, utan önskar (Request) en biljett istället. När biljettsläppet stänger används den valda allokationsmetoden för att distribuera biljetter till användarna. Om du har några frågor, tveka inte att kontakta oss.",
    },
    get_in_touch: {
      subtitle: "Skaparen av tessera",
      call_to_action: "Ta kontakt",
      quote1:
        "Jag var så frustrerad över att alltid behöva använda Google Forms för biljettförsäljning, så jag bestämde mig för att skapa tessera för att göra det enklare för alla.",
      quote2:
        "tessera är det största projektet jag någonsin har arbetat med, och jag ser fram emot att se det växa. Om du är intresserad av att använda tessera, hör av dig till mig!",
    },
    how_to_use: {
      request_ticket_title: "Önska en biljett",
      request_ticket:
        "tesserars nyskapande sätt att dela ut biljetter gör det möjligt för användare att få biljetter till evenemang genom olika metoder, som ett lotteri eller på en först-till-kvarn. Detta gör att alla har en rättvis chans att få biljetter. För att önska en biljett, gå bara till evenemangsfliken, sök upp ditt evenemang och klicka på 'Önska Biljett'.",
      fill_out_title: "Fyll i personuppgifter",
      fill_out:
        "När du har önskat en biljett behöver du fylla i personliga uppgifter, som dina matpreferenser och eventuella allergier. Detta gör att arrangörerna kan skräddarsy evenemanget för dig och säkerställer en mer personlig upplevelse. tessera kopplar ditt konto till dina biljetter, vilket gör processen smidigare och hjälper dig att njuta mer av evenemanget.",
      wait_title: "Vänta på din biljett",
      wait: "Det sista steget är att vänta på att biljettsläppet stänger och biljetterna delas ut, antingen genom ett lotteri eller på en först-till-kvarn-basis. tessera ser till att biljetterna fördelas rättvist. Håll ett öga på din e-post eller ditt tessera-konto för att få uppdateringar om din begärans status. Om du får biljetter kommer du även att få vidare instruktioner, som hur du betalar.",
    },
    revenue_calculator: {
      title: "Beräkna Din Evenemangsintäkt",
      description:
        "Använd vår kalkylator för att uppskatta din evenemangsintäkt efter avgifter",
      number_of_tickets: "Antal Biljetter",
      ticket_price: "Biljettpris",
      who_pays_fees: "Vem Betalar Transaktionsavgifterna?",
      attendee_pays: "Deltagaren Betalar Avgifter",
      organization_pays: "Organisationen Betalar Avgifter",
      total_revenue: "Total Intäkt",
      fee_per_ticket_standard: "Avgift per Biljett (3,5% + 3 SEK)",
      fee_per_ticket_non_profit: "Avgift per Biljett (3% + 1 SEK)",
      total_fees: "Totala Avgifter",
      net_revenue: "Nettointäkt",
      read_more: "Läs mer om biljettavgifter",
      is_non_profit: "Ideell Organisation",
    },
  },

  product: {
    titles: {
      ticket_distribution: "Biljettfördelning",
      event_management: "Eventhantering",
      event_pages: "Eventsidor",
      team_management: "Teamhantering",
      beta_program: "BETA-program",
      manager_onboarding: "Onboarding för arrangörer",
      event_forms: "Eventformulär",
      data_security: "Datasäkerhet",
      payments: "Betalningar",
    },
    main: {
      more_features: "Fler funktioner",
      title: "Upptäck tessera's lösningar",
      description:
        "Utforska hur tessera kan förändra din upplevelse av evenemangshantering.",
      about_title: "Vad är tessera?",
      about_description:
        "tessera är en innovativ plattform designad för att förenkla evenemangshantering, biljettfördelning och deltagarengagemang. Med en uppsättning §kraftfulla funktioner ger tessera evenemangsarrangörer möjlighet att skapa sömlösa och minnesvärda upplevelser.",
      about_description_2:
        "tessera håller sina arrangörer och deltagare i åtanke och erbjuder en användarvänlig plattform som effektiviserar planeringsprocessen för evenemang. Med tessera BETA kan du påverka framtiden för evenemangshantering genom att ge feedback och förslag till vårt team. All typ av feedback är välkommen! Vill du ha en ny funktion? Låt oss veta!",
      features: {
        title: "Lösningar",
        more_coming_soon:
          "...och många fler som vi är glada att snart kunna dela med dig!",
      },
    },
    ticket_distribution: {
      title: "Biljettfördelning",
      description:
        "Flexibla och rättvisa biljettfördelningsmetoder för evenemang av alla storlekar",
      section_1: {
        title: "Innovativ biljettfördelning",
        description:
          "tessera erbjuder flexibla biljettalternativ, inklusive lotterier och först till kvarn-metoder. Detta säkerställer en rättvis och transparent fördelning, oavsett evenemangets storlek.",
        description_2:
          "Om du letar efter ett anpassningsbart biljettdistributionssystem är tessera rätt val för dig. Låt oss diskutera dina behov och hitta den bästa lösningen för ditt evenemang.",

        cards: {
          card_1: {
            title: "Lotterier",
            description:
              "Skapa en lotteridragning för biljettsläpp för att säkerställa en rättvis och transparent fördelning av biljetter.",
          },
          card_2: {
            title: "Först till kvarn",
            description:
              "Använd först till kvarn-metoden för att distribuera biljetter till de snabbaste användarna.",
          },
          card_3: {
            title: "Reserverade biljettsläpp",
            description:
              "Skapa reserverade biljettsläpp med kampanjkoder för specifika användare.",
          },
          card_4: {
            title: "Anpassningsbar biljettfördelning",
            description:
              "Anpassa biljettdistributionsmetoder för att passa ditt evenemangs behov.",
          },
          card_5: {
            title: "Traditionella förskottsbetalningar",
            description:
              "Vi erbjuder en traditionell förskottsköpsmetod för biljettsläpp.",
          },
        },
      },
      section_2: {
        title: "För att det är rättvist",
        description:
          "tessera erbjuder ett rättvist och transparent biljettdistributionssystem. Evenemang där många människor är intresserade av att delta kan vara svåra att hantera. tesseras innovativa biljettdistributionsmetoder säkerställer att alla har en lika stor chans att få en biljett.",
        description_2:
          "Traditionella biljettdistributionsmetoder såsom förskottsbetalningar är också tillgängliga. tessera erbjuder en mängd olika biljettdistributionsmetoder för att passa ditt evenemangs behov.",
      },
    },
    event_management: {
      title: "Eventhantering",
      description: "Enkel eventhantering för alla dina evenemang",
      section_1: {
        title: "Enkel eventhantering",
        description:
          "Att skapa och hantera evenemang har aldrig varit enklare. Med tesseras strömlinjeformade system kan du ställa in evenemang, släppa biljetter och hantera deltagare med bara några få klick.",
        description_2:
          "tessera erbjuder en rad funktioner för att hjälpa dig hantera ditt evenemang, från att skapa biljettsläpp till att spåra deltagare. Låt oss hjälpa dig att göra ditt evenemang till en framgång.",
        description_3:
          "Definiera när ditt evenemang börjar och när det slutar. Skapa ett biljettsläpp och räkna ned till släppet tillsammans med dina användare. Definiera biljettyper och hur de distribueras. Lägg till anpassade fält till ditt evenemangsformulär för att samla in ytterligare information från dina användare.",
      },
      section_2: {
        title: "För att det är enkelt",
        description:
          "tessera förenklar eventhantering genom att erbjuda en användarvänlig plattform för att skapa och hantera evenemang. Med tessera kan du enkelt ställa in biljettsläpp, hantera deltagare och spåra biljettförsäljning.",
        description_2:
          "Vår plattform är utformad för att göra eventhantering så enkel och okomplicerad som möjligt. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
    },
    customizable_event_pages: {
      title: "Anpassningsbara eventsidor",
      description:
        "Skapa unika och engagerande eventsidor som speglar ditt varumärke och lyfter fram ditt evenemang.",
      section_1: {
        title: "Designa med flexibilitet",
        description:
          "Med Tesseras kraftfulla sidbyggare kan du anpassa varje aspekt av din eventsida. Justera layout, färgpalett och innehåll för att skapa en unik upplevelse som passar ditt evenemang.",
        description_2:
          "Använd färdiga mallar eller integrera din egen HTML och CSS för att skapa en helt skräddarsydd eventsida som sticker ut.",
      },
      section_2: {
        title: "Höj din online-närvaro",
        description:
          "En väl utformad eventsida gör det enkelt för dina besökare att utforska evenemangets detaljer, köpa biljetter och engagera sig. Låt Tessera hjälpa dig att skapa en sida som imponerar.",
        description_2:
          "Vår plattform är intuitiv och användarvänlig, så att du snabbt kan designa och publicera din eventsida utan teknisk expertis.",
      },
    },
    team_management: {
      title: "Teamhantering",
      description: "Hantera dina team enkelt och samarbeta kring evenemang",
      section_1: {
        title: "Enkel teamhantering",
        description:
          "tessera gör det enkelt att hantera team och samarbeta kring evenemang. När du ställer in din organisation kan du skapa team för olika evenemang och tilldela roller till teammedlemmar. Detta säkerställer att alla är på samma sida och arbetar mot ett gemensamt mål.",
        description_2:
          "Bjud in medlemmar att gå med i din organisation och tilldela dem till team. Definiera roller och behörigheter för att säkerställa att alla har rätt åtkomstnivå. Låt oss hjälpa dig att hantera dina team och samarbeta kring evenemang. Låt oss ställa in din organisation så att du kan börja bjuda in medlemmar och skapa team idag!",
      },
      section_2: {
        title: "För samarbete är viktigt",
        description:
          "tessera är utformad för att vara en samarbetsplattform för eventplanering. Med tessera kan du skapa team, lägga till medlemmar och tilldela roller för att effektivisera din eventplaneringsprocess.",
        description_2:
          "Vår plattform är utformad för att göra det enkelt för team att samarbeta kring eventplanering. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
    },
    beta_program: {
      title: "tessera BETA",
      description:
        "Gå med i tessera BETA-programmet och hjälp till att forma framtidens eventhantering",
      section_1: {
        title: "tessera BETA",
        description:
          "tessera är för närvarande i BETA, vilket innebär att det fortfarande utvecklas och förbättras. Om din organisation är intresserad av att använda tessera BETA kommer vi att ställa in ett team åt dig utan kostnad. Du kommer att ha tillgång till alla funktioner i tessera, inklusive biljettdistribution, eventhantering och teamsamarbete.",
        description_2:
          "Gå med i tessera BETA-programmet och hjälp till att forma framtidens eventhantering. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
      section_2: {
        title: "För att det är innovativt",
        description:
          "tessera är en innovativ plattform som ständigt utvecklas för att möta behoven hos eventarrangörer. Med tessera BETA kan du hjälpa till att forma framtidens eventhantering genom att ge feedback och förslag till vårt team.",
        description_2:
          "Vår plattform är utformad för att vara innovativ och framåtblickande, så att du kan skapa framgångsrika evenemang som möter dina deltagares behov.",
      },
    },
    secure_payment: {
      title: "Säker betalningshantering",
      description: "Integrera säkra betalningar sömlöst i dina evenemang",
      section_1: {
        title: "Integrerade betalningar",
        description:
          "Bearbeta biljettbetalningar säkert inom tesseras plattform. tessera samarbetar med Surfboard Payments för att ge dina deltagare en sömlös betalningsupplevelse.",
        description_2:
          "Onlinekassor har aldrig varit enklare. Betala med antingen kredit-/betalkort, apple pay eller google pay. tessera ger dina deltagare en säker betalningsupplevelse.",
        description_3: "Läs mer om Surfboard Payments",
      },
      section_2: {
        title: "För att det är viktigt",
        description:
          "tesseras plattform säkerställer att alla betalningar bearbetas säkert, med robust återbetalningshantering för att göra hanteringen av betalningar stressfri.",
        description_2:
          "Med tessera kan du hantera återbetalningar direkt från plattformen, vilket förenklar eventhantering och ger en sömlös upplevelse för deltagarna.",
      },
    },
    custom_event_forms: {
      title: "Anpassade eventformulär",
      description:
        "Samla in anpassad deltagarinformation för att personanpassa din eventupplevelse",
      section_1: {
        title: "Samla in anpassad information",
        description:
          "tesseras anpassningsbara formulärbyggare gör att du kan samla in deltagarinformation som är specifik för ditt evenemang. Oavsett om det är frågor eller speciella önskemål hjälper tessera dig att samla in rätt information. Denna information lagras säkert och kan nås när som helst.",
        description_2:
          "Vår lättanvända formulärbyggare låter dig samla in information av olika typer, inklusive text, siffror, flervalsalternativ, med möjlighet att lägga till anpassade fält. Låt oss hjälpa dig att skapa ett framgångsrikt evenemang.",
      },
      section_2: {
        title: "För att det är flexibelt",
        description:
          "tessera tillhandahåller ett flexibelt datahanteringssystem som säkert lagrar all information du samlar in. Med integritet och datasäkerhet i åtanke säkerställer tessera att all deltagardata hanteras säkert.",
        description_2:
          "tesseras plattform är GDPR-kompatibel, vilket säkerställer att ditt evenemang och dina deltagares data skyddas enligt de högsta standarderna.",
      },
    },
    privacy_security: {
      title: "Integritet och datasäkerhet",
      description:
        "Skyddar användardata med GDPR-efterlevnad och robusta säkerhetsåtgärder",
      section_1: {
        title: "GDPR-efterlevnad",
        description:
          "tessera är engagerad i att skydda användardata i enlighet med GDPR-reglerna. Vi säkerställer att all personlig information hanteras transparent och säkert, vilket ger dina deltagare sinnesro.",
        description_2:
          "Vår plattform tillhandahåller tydliga integritetspolicyer som informerar användare om hur deras data används, lagras och skyddas.",
      },
      section_2: {
        title: "För att det är säkert",
        description:
          "tessera använder robusta säkerhetsåtgärder för att säkerställa att all deltagarinformation hålls säker. Vi använder kryptering och säkra datahanteringsmetoder för att skydda mot obehörig åtkomst.",
        description_2:
          "Med tessera kan du fokusera på att skapa fantastiska evenemang, med vetskap om att dina deltagares data är säkra och deras integritet respekteras.",
      },
    },
    manager_onboarding: {
      title: "Onboarding för arrangörer",
      description: "Kom igång snabbt med enkel onboarding och setup",
      section_1: {
        title: "Enkel onboarding",
        description:
          "tessera tillhandahåller en enkel onboarding-process för eventarrangörer. Skapa ett konto, ställ in din organisation, fyll i dina företagsuppgifter och börja sälja biljetter på nolltid.",
        description_2:
          "Onboarding-verifieringsprocessen tar 1-4 dagar. När ditt konto är verifierat kan du börja skapa evenemang och hantera biljettsläpp.",
      },
      section_2: {
        title: "För att det är nödvändigt",
        description:
          "tessera säkerställer att alla dess arrangörer är legitima företag. Detta hjälper till att skydda deltagare och säkerställa att evenemang drivs professionellt.",
        description_2:
          "Vår plattform är utformad för att göra onboarding så enkel som möjligt.",
        description_3_logged_out:
          "Låt oss börja med att skapa ett konto och registrera ditt företag!.",
        description_3_logged_in:
          "Om du inte redan har valt en plan kan du göra det nu. När du har valt en plan kommer onboarding-processen att guida dig genom de följande stegen!",
        steps: {
          step_1: {
            number: "01",
            label: "Registrera dig",
          },
          step_2: {
            number: "02",
            label: "Registrera företag",
          },
          step_3: {
            number: "03",
            label: "Fyll i detaljer",
          },
          step_4: {
            number: "04",
            label: "Klar!",
          },
        },
      },
    },
  },

  refund: {
    dialog_title: "Återbetala Biljett",
    description:
      "Endast fullständiga återbetalningar på hela biljettordern är tillåtna. Därför kan fler biljetter påverkas, vänligen kontrollera detaljerna nedan innan du fortsätter.",
    reason: "Anledning till Återbetalning",
    amount: "Återbetalningsbelopp",
    submit: "Genomför Återbetalning",
    order_info: "Order ID: {{id}}, Totalt: SEK {{total}}",
    user_info: "Namn: {{name}}, E-post: {{email}}",
    tickets_to_refund: "Biljetter att återbetala",
    total_amount: "Totalt Belopp: SEK {{amount}}",
    cannot_refund: "Kan inte återbetala biljett",
    amount_error: {
      min: "Återbetalningsbeloppet måste vara större än 0",
      max: "Återbetalningsbeloppet kan inte överstiga SEK {{max}}",
    },
    affected_tickets: "Påverkade Biljetter",
  },
  error: {
    no_rows_selected: "Inga rader valda",
    invalid_refund_selection:
      "Ogiltig återbetalningsval. Vänligen välj en enskild biljett.",
    ticket_not_found: "Vald biljett hittades inte",
    no_ticket_selected: "Ingen biljett vald för återbetalning",
    unknown: "Ett okänt fel inträffade",
  },
  success: {
    delete: "Lyckades ta bort {{type}}",
    undelete: "Lyckades återställa {{type}}",
    allocate: "Lyckades tilldela biljetter",
    refund_processed: "Återbetalning genomförd",
  },

  pricing: {
    get_started: "Kom igång",
    contact_us: "Kontakta oss",
    selected_plan: "Valt paket",
    pricing: "Priser",
    most_popular: "Mest populär",
    beta_text:
      "tessera är för närvarande i BETA, därför är alla funktioner inkluderade i gratisplanen.",
    choose_plan: "Välj det paket som passar dig bäst",
    how_to_pay: "Hur vill du betala?",
    monthly: "Månadsvis",
    yearly: "Årsvis",
    discount: "33% rabatt",
    excluding_vat: "Alla priser är exklusive moms.",
    list_of_features: "Lista över funktioner",
    per_event: "/event",
    per_month: "/månad",
    per_year: "/månad",
    plans: {
      free: "Gratis",
    },
    ticket_fees: {
      title: "Transaktionsavgift",
      description:
        "Transaktionsavgiften inkluderar moms, alla bank- och kreditkortsavgifter, inga dolda kostnader, vilket ger dig de tjänster du behöver för att lyckas med ditt evenemang.",
      regular_fees: "Standardavgift",
      non_profit_fees: "För ideella föreningar",
      fee_1: "3,5 % + 3 SEK",
      fee_2: "3 % + 1 SEK",
      per_sold_ticket: "Per transaktion",
    },
  },

  business_page: {
    events: "Event",
    about: "Om {{networkName}}",
    upcoming_events: "Kommande event",
    past_events: "Tidigare event",
    no_upcoming_events: "Det finns inga kommande event.",
    no_past_events: "Det finns inga tidigare evemt.",
    view_event: "Visa evemt",
    calendar_view: "Kalendervy",
    grid_view: "Rutnätsvy",
    contact_us: "Kontakta Oss",
    see_events_cta: "Se event",
    ticket_release: "Biljettsläpp",
    events_this_month: "Event denna månad",
    no_events_this_month: "Inga event denna månad",
    ticket_releases_this_month: "Biljettsläpp denna månad",
    no_ticket_releases_this_month: "Inga biljettsläpp denna månad",
    calendar: {
      organized_by: "Organiserat av <1>{{name}}</1>",
      ticket_release: "Biljettsläpp",
      event: "event",
      close: "Stäng",
    },
  },

  faq: {
    search_placeholder: "Sök efter frågor",
    title: "Vanliga frågor",
    subtitle:
      "Om du behöver ytterligare hjälp, vänligen skapa en biljett i <1>Supportcenter</1>.",
    ...seFaq,
  },

  solutions: {
    titles: {
      in_person_events: "Fysiska Evenemang",
      university_events: "Universitetsevenemang",
      concerts: "Konserter",
      theater_shows: "Teaterföreställningar",
      galas: "Galamiddagar",
      corporate_events: "Företagsevenemang",
      complex_teams: "Komplexa Team",
      easy_event_setup: "Enkel Evenemangshantering",
    },
    main: {
      title: "Lösningar för Varje Evenemang",
      description:
        "Hitta den perfekta lösningen för dina evenemangshanteringsbehov",
      about_title: "Evenemangshantering Förenklad",
      about_description:
        "Tessera erbjuder specialiserade lösningar för olika typer av evenemang och organisationer. Från universitetsevenemang till företagssammankomster har vi verktygen du behöver för att lyckas.",
      about_description_2:
        "Vår plattform är utformad för att vara flexibel och anpassningsbar, så att du kan skapa och hantera evenemang precis som du vill.",
      solutions: {
        title: "Upptäck Våra Lösningar",
      },
    },
    easy_event_setup: {
      title: "Skapa event enkelt",
      description:
        "Skapa och hantera event enkelt - från koncept till genomförande på några minuter",
      section_1: {
        title: "Beskriv ditt event",
        description:
          "Att publicera ett event har aldrig varit så enekelt, tack vare tesseras AI-drivna evenemangskapande. Vår platform gör det enkelt att skapa evenemang med vardagligt språk. Om du behöver göra detaljerade ändringar, kan du göra det med vår eventbyggare.",
        description_2:
          "Vår plattform guidar dig genom varje steg i processen och säkerställer att du inte missar några viktiga detaljer. Från grundläggande evenemangsinformation till avancerade inställningar har vi gjort det enkelt att få igång ditt evenemang snabbt.",
      },
      section_2: {
        title: "För att det är enkelt",
        description:
          "tesseras plattform är utformad för att göra evenemangskapande så smidigt som möjligt. Med våra AI-drivna förslag och färdiga mallar kan du fokusera på det som är viktigast - att skapa minnesvärda upplevelser för dina deltagare.",
        description_2:
          "Oavsett om du organiserar ditt första evenemang eller ditt hundrade, ger vår plattform dig verktygen och vägledningen du behöver för att lyckas.",
      },
      features: {
        title: "Huvudfunktioner",
        feature_1: {
          title: "AI-driven uppsättning",
          description:
            "Låt vår AI-assistent guida dig genom evenemangskapandet med smarta förslag och bästa praxis.",
        },
        feature_2: {
          title: "Färdiga mallar",
          description:
            "Välj bland olika professionellt designade mallar för olika typer av evenemang.",
        },
        feature_3: {
          title: "Eventbyggaren",
          description:
            "Eventbyggaren hjälper dig att skapa eller göra detaljerade ändringar i ditt event.",
        },
        feature_4: {
          title: "Snabb anpassning",
          description:
            "Anpassa enkelt varje aspekt av ditt evenemang samtidigt som du behåller professionell kvalitet.",
        },
        feature_5: {
          title: "Olika sags event",
          description:
            "tessera stödjer många typer av evenemang, inklusive fysiska evenemang, studentevenemang, företagsevenemang och mer.",
        },
      },
    },
    in_person_events: {
      title: "Fysiska Evenemang",
      description:
        "Omfattande lösningar för att hantera fysiska evenemang av alla storlekar",
      section_1: {
        title: "Smidig Hantering av Fysiska Evenemang",
        description:
          "tessera tillhandahåller alla verktyg du behöver för att hantera framgångsrika fysiska evenemang. Från biljettdistribution till incheckning säkerställer vår plattform en smidig upplevelse för både arrangörer och deltagare.",
        description_2:
          "Skapa vackra eventsidor, hantera biljettsläpp och hantera deltagarinformation på ett och samma ställe. Vår plattform är utformad för att göra hanteringen av fysiska evenemang så effektiv som möjligt.",
      },
      section_2: {
        title: "Funktioner för Fysiska Evenemang",
        description:
          "tessera erbjuder specialiserade funktioner för fysiska evenemang, inklusive sittplatsarrangemang, hantering av matpreferenser och incheckning på plats.",
        description_2:
          "Vår plattform hjälper dig att hantera varje aspekt av ditt fysiska evenemang och säkerställer en sömlös upplevelse från början till slut.",
      },
      event_types: {
        title: "Typer av Fysiska Evenemang",
        description:
          "tessera stödjer en mängd olika fysiska evenemang, inklusive:",
        types: [
          {
            icon: "festival",
            title: "Festivaler",
            description: "Musikfestivaler, kulturfestivaler och matfestivaler",
          },
          {
            icon: "school",
            title: "Studentevenemang",
            description:
              "Universitetsbaler, examenceremonier och studentmässor",
          },
          {
            icon: "business",
            title: "Konferenser",
            description:
              "Företagskonferenser, tech meetups och branschseminarier",
          },
          {
            icon: "theater",
            title: "Kulturevenemang",
            description:
              "Teaterföreställningar, konstutställningar och konserter",
          },
          {
            icon: "celebration",
            title: "Ceremonier",
            description: "Prisutdelningar, galor och formella middagar",
          },
          {
            icon: "groups",
            title: "Sociala Evenemang",
            description:
              "Nätverksträffar, gemenskapssamlingar och sociala klubbar",
          },
        ],
      },
    },
    university_events: {
      title: "Universitetsevenemang",
      description:
        "Specialiserade lösningar för studentkårer och organisationer som hanterar universitetsevenemang",
      section_1: {
        title: "Hantering av Studentevenemang",
        description:
          "tessera erbjuder specialiserade verktyg för att hantera studentevenemang, från formella middagar till stora fester. Vår plattform förstår studentorganisationers unika krav.",
        description_2:
          "Skapa vackra eventsidor, hantera biljettsläpp med lotterisystem och hantera bordsplaceringar på ett och samma ställe. Vår plattform är utformad för att göra hanteringen av studentevenemang så effektiv som möjligt.",
      },
      section_2: {
        title: "Funktioner för Studentevenemang",
        description:
          "tessera erbjuder specialiserade funktioner för studentevenemang, inklusive bordsplacering, kostpreferenser och klädkodhantering.",
        description_2:
          "Vår plattform hjälper dig att hantera varje aspekt av ditt studentevenemang, från biljettförsäljning till gästlistor, och säkerställer en sömlös upplevelse för både arrangörer och deltagare.",
      },
      event_types: {
        title: "Typer av Studentevenemang",
        description:
          "tessera stödjer alla typer av studentevenemang, inklusive:",
        types: [
          {
            icon: "party",
            title: "Gasquer",
            description: "Formella studentmiddagar med underhållning och dans",
          },
          {
            icon: "dinner",
            title: "Banketter",
            description:
              "Formella middagar med traditionella studentceremonier och sånger",
          },
          {
            icon: "table",
            title: "Sittningar",
            description:
              "Sittande middagar med underhållning och traditionella studentaktiviteter",
          },
          {
            icon: "celebration",
            title: "Fester",
            description:
              "Casual till semiformella studentsammankomster och firanden",
          },
          {
            icon: "graduation",
            title: "Examensevenemang",
            description: "Examenceremonier, baler och festmiddagar",
          },
          {
            icon: "welcome",
            title: "Välkomstevenemang",
            description:
              "Mottagningsveckor, välkomstfester och studentintroduktioner",
          },
        ],
      },
    },
    concerts: {
      description:
        "Specialiserade verktyg för att hantera konserter, från biljettförsäljning till venuhantering",
    },
    theater_shows: {
      description:
        "Kompletta lösningar för teaterproduktioner och framträdanden",
    },
    galas: {
      description:
        "Elegant verktyg för att hantera formella evenemang och galor",
    },
    corporate_events: {
      description:
        "Professionella lösningar för företagsevenemang och företagssammankomster",
    },
    complex_teams: {
      title: "Hantering av Komplexa Team",
      description:
        "Kraftfulla lösningar för organisationer med komplexa teamstrukturer som hanterar flera evenemang samtidigt",
      section_1: {
        title: "Avancerad Teamhantering",
        description:
          "tessera erbjuder ett sofistikerat teamhanteringssystem som låter organisationer skapa och hantera komplexa teamhierarkier. Från avdelningar till underteam gör vår plattform det enkelt att organisera och delegera ansvar.",
        description_2:
          "Skapa anpassade roller, ställ in behörigheter och hantera åtkomstnivåer för olika teammedlemmar. Vår plattform är utformad för att hantera de mest komplexa organisationsstrukturer samtidigt som den behåller enkelhet och effektivitet.",
      },
      section_2: {
        title: "Effektiv Hantering av Flera Evenemang",
        description:
          "tesseras plattform gör det möjligt för team att hantera flera evenemang samtidigt med minimal övervakning. Vårt avancerade behörighetssystem säkerställer att teammedlemmar endast har tillgång till de evenemang och funktioner de behöver.",
        description_2:
          "Följ evenemangsframsteg, övervaka biljettförsäljning och hantera resurser över flera evenemang och team från en centraliserad kontrollpanel. Vår plattform växer med din organisation, oavsett om du hanterar ett fåtal evenemang eller hundratals.",
      },
      event_types: {
        title: "Exempel på Teamstrukturer",
        description:
          "tessera stödjer olika teamstrukturer och evenemangshanteringsscenarier, inklusive:",
        types: [
          {
            icon: "departments",
            title: "Avdelningsteam",
            description:
              "Skapa team för olika avdelningar med egna evenemang och underteam",
          },
          {
            icon: "chapters",
            title: "Sektionshantering",
            description:
              "Hantera flera sektioner eller filialer med oberoende evenemangskalendrar",
          },
          {
            icon: "projects",
            title: "Projektteam",
            description:
              "Sätt upp tillfälliga team för specifika evenemang eller projektserier",
          },
          {
            icon: "committees",
            title: "Kommittéstruktur",
            description:
              "Organisera kommittéer med specifika roller och ansvarsområden",
          },
          {
            icon: "venues",
            title: "Platsteam",
            description:
              "Skapa team dedikerade till att hantera evenemang på specifika platser",
          },
          {
            icon: "collaborations",
            title: "Samarbetsevenemang",
            description:
              "Möjliggör för flera team att samarbeta kring gemensamma evenemang",
          },
        ],
      },
    },
  },

  ticket_release: {
    available: "Tillgängliga",
    paid: "Betalda",
    unpaid: "Obetalda",
    refunded: "Återbetalda",
    deleted: "Borttagna",
    open: "Öppen",
    closed: "Stängd",
    remaining_tickets: "Återstående biljetter",
    total_tickets: "Totalt antal biljetter",
  },
};

export default seTranslations;
