import { Box } from "@mui/joy";
import { useEffect } from "react";
import TesseraWrapper from "../wrappers/page_wrapper";

const TermsAndConditions: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <TesseraWrapper>
      <Box sx={{ mx: 5 }}>
        <div
          // @ts-ignore
          name="termly-embed"
          data-id="25dd72fd-454b-4379-9f9d-df4c7b05c852"
          data-type="iframe"
        />
      </Box>
    </TesseraWrapper>
  );
};

export default TermsAndConditions;
