import { de } from "date-fns/locale";
import gbFaq from "../assets/faq/gb_faq.json";
import { add } from "lodash";
import { select } from "redux-saga/effects";
import { enMealTags } from "./mealTags";

const enTranslations = {
  navigation: {
    events: "Events",
    create_event: "Publish Event",
    teams: "Teams",
    contact: "Contact",
    login: "Login",
    product: "Platform",
    pricing: "Pricing",
    manager: "Manager",
    solutions: "Solutions",
  },

  become_a_manager: {
    welcome: "Welcome to tessera!",
    proceed:
      "Are you an event manager? Complete your profile and start creating events.",
    continue_as_manager: "Continue as Manager",
    customer: "Customer",
    manager: "Event Manager",
    skip: "I'm here to buy tickets, Skip",
    select_plan: "Select Plan",
    choose_plan:
      "Choose the plan that works best for you. When contacting us, please specify which plan you are interested in and what additional information you need.",
    full_list_of_features: "Full List of Features",
    selected_plan: "Selected Plan",
    register: {
      on_board:
        "Great to have you on board! Please fill out the form below to become an event manager.",
      free: {
        title: "Free Plan",
      },
      form: {
        company_name: "Company Name",
        company_name_helperText: "What is the name of your company?",
        referral_source: "How did you hear about us?",
        referral_source_specific: "Please specify",
        beta_key: "Beta Key",
      },
      request_beta_access: "Ask a question",
      have_beta_key: "Get started now",
      no_beta_key: "Ask a question",
    },
  },

  book_demo: {
    button_text: "Book a Free Demo",
    title: "Book a Demo",
    description:
      "Fill out the form below to book a demo. We will contact you shortly to schedule a time.",
    name: "Name",
    name_helperText: "What is your name?",
    email: "Email",
    email_helperText: "What is your email?",
    message: "Message",
    message_placeholder: "How can tessera simplify your events?",
  },

  product: {
    titles: {
      ticket_distribution: "Ticket Distribution",
      event_management: "Event Management",
      event_pages: "Event Pages",
      team_management: "Team Management",
      beta_program: "BETA Program",
      manager_onboarding: "Manager Onboarding",
      event_forms: "Event Forms",
      data_security: "Data Security",
      payments: "Payments",
    },
    main: {
      more_features: "More Features",
      title: "Discover <1>tessera</1>'s Features",
      description:
        "Explore how tessera can transform your event management experience.",
      about_title: "What is tessera?",
      about_description:
        "tessera is an innovative platform designed to simplify event management, ticket distribution, and attendee engagement. With a suite of powerful features, tessera empowers event organizers to create seamless and memorable experiences.",
      about_description_2:
        "tessera keeps its managers and attendees in mind, providing a user-friendly platform that streamlines the event planning process. With tessera BETA, you can help shape the future of event management by providing feedback and suggestions to our team. All types of feedback are welcome! Do you want a new feature? Let us know!",
      features: {
        title: "Key Features",
        more_coming_soon:
          "...and many more we are excited to share with you soon!",
      },
    },
    ticket_distribution: {
      title: "Ticket Distribution",
      description:
        "Flexible and Fair Ticket Distribution Methods for Events of All Sizes",
      section_1: {
        title: "Innovative Ticket Distribution",
        description:
          "tessera offers flexible ticketing options, including lotteries and first-come-first-served methods. This ensures a fair and transparent distribution, no matter the event size.",
        description_2:
          "If you're looking for a customizable ticket allocation system, tessera is the right choice for you. Let us discuss your needs and find the best solution for your event.",

        cards: {
          card_1: {
            title: "Lotteries",
            description:
              "Create a lottery ticket release to ensure a fair and transparent distribution of tickets.",
          },
          card_2: {
            title: "First-Come-First-Served",
            description:
              "Use the first-come-first-served method to distribute tickets to the fastest users.",
          },
          card_3: {
            title: "Reserved Ticket Batch",
            description:
              "Create reserved ticket batch with promo codes for specific users.",
          },
          card_4: {
            title: "Customizable Ticket Allocation",
            description:
              "Customize ticket allocation methods to suit your event's needs.",
          },
          card_5: {
            title: "Traditional Upfront Payments",
            description:
              "We offer a traditional upfront purchase method for ticket releases.",
          },
        },
      },
      section_2: {
        title: "Because it's fair",
        description:
          "tessera offers a fair and transparent ticket distribution system. Events where large numbers of people are interested in attending can be difficult to manage. tessera's innovative ticket distribution methods ensure that everyone has an equal chance of getting a ticket.",
        description_2:
          "Traditional ticket distribution methods such as upfront payments are also available. tessera offers a variety of ticket distribution methods to suit your event's needs.",
      },
    },
    event_management: {
      title: "Event Management",
      description: "Effortless Event Management for All Your Events",
      section_1: {
        title: "Effortless Event Management",
        description:
          "Creating and managing events has never been easier. With tessera's streamlined system, you can set up events, release tickets, and handle attendees in just a few clicks.",
        description_2:
          "tessera offers a range of features to help you manage your event, from creating ticket releases to tracking attendees. Let us help you make your event a success.",
        description_3:
          "Define when your event begins and ends. Create a ticket release and count down to the release together with your users. Define the ticket types and how they are distributed. Add custom fields to your event form to collect additional information from your users.",
      },
      section_2: {
        title: "Because it's simple",
        description:
          "tessera simplifies event management by providing a user-friendly platform for creating and managing events. With tessera, you can easily set up ticket releases, manage attendees, and track ticket sales.",
        description_2:
          "Our platform is designed to make event management as simple and straightforward as possible. Let us help you create a successful event.",
      },
    },
    customizable_event_pages: {
      title: "Customizable Event Pages",
      description:
        "Create unique and engaging event pages that showcase your brand and elevate your event.",
      section_1: {
        title: "Design with Flexibility",
        description:
          "With Tessera's powerful page builder, you can customize every aspect of your event page. Adjust layouts, color schemes, and content to create a unique experience that perfectly suits your event.",
        description_2:
          "Choose from pre-designed templates or integrate your own HTML and CSS to craft a truly personalized event page that stands out.",
      },
      section_2: {
        title: "Enhance Your Online Presence",
        description:
          "A well-designed event page makes it easy for visitors to explore event details, purchase tickets, and engage with your brand. Tessera helps you create pages that captivate and impress.",
        description_2:
          "Our platform is intuitive and user-friendly, enabling you to design and publish your event page quickly and effortlessly, even without technical expertise.",
      },
    },
    team_management: {
      title: "Team Management",
      description: "Effortlessly Manage Your Teams and Collaborate on Events",
      section_1: {
        title: "Effortless Team Management",
        description:
          "tessera makes it easy to manage teams and collaborate on events. When setting up your organization, you can create teams for different events and assign roles to team members. This ensures that everyone is on the same page and working towards a common goal.",
        description_2:
          "Invite members to join your organization and assign them to teams. Define roles and permissions to ensure that everyone has the right level of access. Let us help you manage your teams and collaborate on events. Let's set up your organization so you can start inviting members and creating teams today!",
      },
      section_2: {
        title: "Because it's collaborative",
        description:
          "tessera is designed to be a collaborative platform for event planning. With tessera, you can create teams, add members, and assign roles to streamline your event planning process.",
        description_2:
          "Our platform is designed to make it easy for teams to collaborate on event planning. Let us help you create a successful event.",
      },
    },
    beta_program: {
      title: "tessera <1>BETA</1>",
      description:
        "Join the tessera BETA Program and Help Shape the Future of Event Management",
      section_1: {
        title: "tessera BETA",
        description:
          "tessera is currently in BETA, meaning it is still being developed and improved. If your organization is interested in using tessera BETA, we will set up a team for you free of charge. You will have access to all the features of tessera, including ticket distribution, event management, and team collaboration.",
        description_2:
          "Join the tessera BETA program and help shape the future of event management. Let us help you create a successful event.",
      },
      section_2: {
        title: "Because it's innovative",
        description:
          "tessera is an innovative platform that is constantly evolving to meet the needs of event organizers. With tessera BETA, you can help shape the future of event management by providing feedback and suggestions to our team.",
        description_2:
          "Our platform is designed to be innovative and forward-thinking, so you can create successful events that meet the needs of your attendees.",
      },
    },
    secure_payment: {
      title: "<1>Secure</1> Payment Processing",
      description: "Seamlessly integrate secure payments into your events",
      section_1: {
        title: "Integrated Payments",
        description:
          "Process ticket payments securely within tessera's platform. tessera teams up with Surfboard Payments to provide a seamless payment experience for your attendees.",
        description_2:
          "Online checkouts have never been easier. Pay with either credit/debit, apple pay or google pay. tessera provides a secure payment experience for your attendees.",
        description_3: "Read more about Surfboard Payments",
      },
      section_2: {
        title: "Because it's secure",
        description:
          "tessera's platform ensures that all payments are processed securely, with robust refund management to make handling payments stress-free.",
        description_2:
          "With tessera, you can manage refunds directly from the platform, simplifying event management and providing a seamless experience for attendees.",
      },
    },
    custom_event_forms: {
      title: "<1>Custom</1> Event Forms",
      description:
        "Collect custom attendee information to personalize your event experience",
      section_1: {
        title: "Collect Custom Information",
        description:
          "tessera's customizable form builder allows you to collect attendee information specific to your event. Whether it's questions or special requests, tessera helps you gather the right information. This information is stored securely and can be accessed at any time.",
        description_2:
          "Our easy-to-use form builder lets you collect different types of information, including text, numbers, multiple choice, with the possibility to add custom fields. Let us help you create a successful event.",
      },
      section_2: {
        title: "Because it's flexible",
        description:
          "tessera provides a flexible data management system, securely storing all the information you collect. With privacy and data security in mind, tessera ensures that all attendee data is handled securely.",
        description_2:
          "tessera's platform is GDPR-compliant, ensuring that your event and your attendees' data are protected according to the highest standards.",
      },
    },
    privacy_security: {
      title: "Privacy and Data <1>Security</1>",
      description:
        "Protecting user data with GDPR compliance and robust security measures",
      section_1: {
        title: "GDPR Compliance",
        description:
          "tessera is committed to safeguarding user data in line with GDPR regulations. We ensure that all personal information is handled transparently and securely, giving your attendees peace of mind.",
        description_2:
          "Our platform provides clear privacy policies that inform users about how their data is used, stored, and protected.",
      },
      section_2: {
        title: "Because it's secure",
        description:
          "tessera employs robust security measures to ensure that all attendee information is kept safe. We utilize encryption and secure data handling practices to protect against unauthorized access.",
        description_2:
          "With tessera, you can focus on creating amazing events, knowing that your attendees' data is secure and their privacy is respected.",
      },
    },
    manager_onboarding: {
      title: "Manager <1>Onboarding</1>",
      description: "Get Started Quickly with Easy Onboarding and Setup",
      section_1: {
        title: "Easy Onboarding",
        description:
          "tessera provides a simple onboarding process for event managers. Create an account, set up your organization, fill in your business details, and start selling tickets in no time.",
        description_2:
          "The onboarding verification process takes 1-4 days. Once your account is verified, you can start creating events and managing ticket releases.",
      },
      section_2: {
        title: "Because it's necessary",
        description:
          "tessera ensures that all of its managers are legitimate businesses. This helps to protect attendees and ensure that events are run professionally.",
        description_2:
          "Sign up today and register your company to start creating events for your attendees. tessera's onboarding is easy to follow and will have you up and running in no time.",
        description_3_logged_out:
          "Let's start by creating an account and registering your company!",
        description_3_logged_in:
          "If you haven't already selected a plan, you can do so now. Once you have selected a plan, the onboarding process will guide you through the following steps!",
        steps: {
          step_1: {
            number: "01",
            label: "Sign up",
          },
          step_2: {
            number: "02",
            label: "Register company",
          },
          step_3: {
            number: "03",
            label: "Fill out details",
          },
          step_4: {
            number: "04",
            label: "Done!",
          },
        },
      },
    },
  },

  main_page: {
    hero: {
      title: "Your Event. <1>Simplified</1>",
    },
    phrases: {
      base: "Event management is now",
      keywords: {
        "1": "tessera",
        "2": "easy",
        "3": "effortless",
        "4": "seamless",
        "5": "straightforward",
        "6": "stress-free",
        "7": "innovative",
        "8": "secure",
        "9": "simple",
      },
    },

    use_cases: {
      title: "Perfect For",
      industry_solutions: {
        title: "Customized <1>Event Solutions</1> for Every Organization",
        description:
          "tessera is designed to meet the unique needs of organizations across different sectors. From student associations to corporate events, our platform adapts to your specific requirements and event objectives.",
      },
      nonprofit: "Non-Profit Organizations",
      agencies: "Event Agencies",
      universities: "University Associations",
      corporate: "Corporate Events",
      banquets: "Banquet & Parties",
      conferences: "Conferences & Shows",
      descriptions: {
        nonprofit:
          "Drive fundraising and outreach efforts through interactive virtual or in-person galas and charity events.",
        agencies:
          "Amplify networking and engagement for staff, volunteers, chapter members & donors with summits and conferences.",
        universities:
          "Enhance student engagement with virtual career fairs, academic conferences, and other on-campus events.",
        corporate:
          "Organize and execute impactful corporate events, from global product launches to team-building activities.",
        banquets:
          "Host memorable celebrations and formal dinners with seamless ticketing and guest management.",
        conferences:
          "Coordinate conferences and shows with multiple tracks, reserved seating, and VIP packages.",
      },
    },

    key_features: {
      title:
        "Simpler <1>Event Management</1> that allows you to focus on creating great events.",
      description:
        "We ensure that your event is a success by providing a platform that provides the tools you need to make it easy.",
      feature1: {
        title: "Innovative Ticket Distribution",
        description:
          "tessera offers flexible ticketing options, including lotteries and first-come-first-served methods. This ensures a fair and transparent distribution, no matter the event size.",
      },
      feature2: {
        title: "Effortless Event Management",
        description:
          "Creating and managing events has never been easier. With tessera's streamlined system, you can set up events, release tickets, and handle attendees in just a few clicks.",
      },
      feature3: {
        title: "Fully Customizable Event Pages",
        description:
          "Create beautiful, branded event pages with tessera's customization tools. Adjust colors, logos, and layouts to make your event stand out, reflecting your unique identity.",
      },
      feature4: {
        title: "AI-Powered Event Creation",
        description:
          "Create events in minutes with our AI assistant. Get instant answers about your event, attendees, and more. Let AI handle the complexity while you focus on what matters.",
      },
      feature5: {
        title: "Complex Team Management",
        description:
          "Support for organizations with complex hierarchies. Create teams, define roles, and manage permissions with ease. Perfect for large organizations with multiple departments.",
      },
      feature6: {
        title: "Complete Customization",
        description:
          "Customize every aspect, from dashboard to event pages. From form builders to landing pages, ticket types to addons - make your event truly unique and tailored to your needs.",
      },
    },
    event_dashboard: {
      title: "The Tessera <1>Event Dashboard</1>",
      description:
        "Manage tickets releases, tickets & attendees in one place. Send emails, view statistics, and more.",
    },
    support: {
      title: "Have a question or need help?",
      description:
        "Please visit our <1>FAQ</1> or our <2>Support Center</2> to create a support ticket.",
    },
    social_proof: {
      title: "Why Event Managers <1>Trust</1> Tessera",
      cta: "Sign Up Now!",
      ready: "Are you ready to get started?",
      description:
        "Sign up today and start using our free plan, or book a demo to learn more about our premium plans.",
      testimonials: {
        chip: "Testamonials",
        lydia_brorsson: {
          name: "Lydia Brorsson",
          title: "Student, KTH",
          quote:
            "I used tessera for the Computer Science Chapter's spring ball. It's a big event with many different types of tickets and high pressure when tickets are released. tessera worked flawlessly and was easy to use as the website is intuitive. In addition, tessera handles spare tickets smoothly, which made it much easier.",
          description:
            "Responsible for the Computer Science Chapter's Spring Ball 2024, with 200 guests.",
        },
        erik_nordlof: {
          name: "Erik Nordlöf",
          title: "Student, KTH",
          quote:
            "I found tessera worked very well. Creating tickets was easy, especially for specially invited guests. The system worked well overall and guests found it easy to use.",
          description:
            "Responsible for Oscar II's ball at THS with over 200 guests.",
        },
        abhinav_sasikumar: {
          name: "Abhinav Sasikumar",
          title: "Student, KTH",
          quote:
            "tessera helped us process payments smoothly, even though we had initially used a standard form. This also made it easier to check tickets on the spot, as opposed to having to manually send out payment links and compile lists of people who have paid. Since we had many ticket types, it was also very convenient to have different promo codes and different allocation methods for different ticket releases.",
          description:
            "Responsible for the D-Dagen 2024 fine dining event with over 300 guests.",
        },
        love_belin: {
          name: "Love Belin",
          title: "Student, KTH",
          quote:
            "I used tessera for the first time for an event that we had this fall. We've used a few different ways to sell tickets for our events in the past, but now that I've tried tessera, I find it hard to argue that I shouldn't continue with it. I got a good briefing from Lucas when I was getting started and was then able to set everything up myself very easily. Once you have everything set up for ticket release, there is not much administrative work to do as there is an incredible amount of built-in features that make everything much easier. The website is very intuitive and easy to understand. Highly recommend tessera for ticketing events.",
          description: "Held a party with about 100 guests.",
        },
      },
    },
    not_a_pain: "Ticket releases shouldn't be a pain!",
    welcome: "{{name}}, Welcome to",
    learn_how_button: "Learn How",
    get_in_touch_button: "Get in Touch",
    login_page: {
      secure_spot: "Secure your spot.",
      for_business_inquiries:
        "For business inquiries, please contact <1>Lucas Dow</1>, the creator of tessera.",
    },
    page_description: {
      what_title: "What is tessera?",
      what: "tessera is a platform that simplifies ticket releases. Purchasing tickets should not be a hassle. tessera makes it easy to create and manage ticket releases. Event organizers can easily view preferences and allergies to enhance your experience without any extra effort. Your account is automatically linked to your tickets, eliminating the need for additional forms.",
      in_beta_title: "tessera BETA",
      in_beta:
        "tessera is currently in BETA, meaning it is still being developed and improved. If your organization is interested in using tessera BETA, we will set up a team for you free of charge. This allows you to create and manage events while providing valuable feedback to help us improve tessera. Contact us to get started!",
      how_title: "How Does It Work?",
      how: "To create a ticket release for your event, you must first become an event organizer by creating a team. Currently, teams are created by contacting us. Once your team is set up, you can start creating and managing ticket releases, and invite other members to help. Users can view public events under the 'Events' tab. tessera offers various ticket distribution methods like lotteries and first-come-first-served systems. Users request tickets, and once the release closes, tickets are allocated based on the chosen method. For questions, feel free to contact us.",
    },
    get_in_touch: {
      subtitle: "The creator of tessera",
      call_to_action: "Get in Touch",
      quote1:
        "I was so frustrated with always using Google Forms for ticket releases, so I decided to create tessera to make it easier for everyone.",
      quote2:
        "tessera is the biggest project I've ever worked on, and I'm so excited to see it grow. If you are interested in using tessera, please get in touch.",
    },
    how_to_use: {
      request_ticket_title: "Request a Ticket",
      request_ticket:
        "tessera's innovative ticket distribution allows users to request tickets through methods like lotteries or first-come-first-served, making the process fairer and more accessible. Go to the 'Events' tab, find your event, and click 'Request' to begin.",
      fill_out_title: "Fill Out Personal Details",
      fill_out:
        "After requesting a ticket, provide personal details like food preferences and allergies. This helps organizers tailor the event to your needs, ensuring a personalized experience. tessera links your account to your tickets, streamlining the process and enhancing your event experience.",
      wait_title: "Wait for Ticket Allocation",
      wait: "After requesting a ticket, wait for the ticket release to close and for tickets to be allocated, either by lottery or on a first-come-first-served basis. tessera ensures fair distribution, so monitor your email or tessera account for updates. If successful, you'll receive further instructions, including payment details if necessary.",
    },
    revenue_calculator: {
      title: "Calculate Your Event Revenue",
      description:
        "Use our calculator to estimate your event's revenue after fees",
      number_of_tickets: "Number of Tickets",
      ticket_price: "Ticket Price",
      who_pays_fees: "Who Pays the Transaction Fees?",
      attendee_pays: "Attendee Pays Fees",
      organization_pays: "Organization Pays Fees",
      total_revenue: "Total Revenue",
      fee_per_ticket_standard: "Fee per Ticket (3.5% + 3 SEK)",
      fee_per_ticket_non_profit: "Fee per Ticket (3% + 1 SEK)",
      total_fees: "Total Fees",
      net_revenue: "Net Revenue",
      read_more: "Read more about ticket fees",
      is_non_profit: "Non-Profit Organization",
    },
  },
  tooltips: {
    add_ticket_type: "Add Ticket",
    manage_ticket_releases: "Manage ticket batches for this event.",
    manage_tickets:
      "This table shows all the ticket requests and tickets for this event.",
    manage_tickets_custom_event_form_description:
      "Here you can see all the form responses for this event. This information was submitted by users when requesting tickets.",
    must_be_edited: "This form must be valid before proceeding.",
  },
  form: {
    required_description: "Fields marked with * are required.",
    advanced_settings: "Advanced Settings",
    event_details: {
      name: "Name",
      name_helperText: "What is the name of your event?",
      description: "Description",
      description_helperText:
        "Describe your event. What should people expect? Markdown is supported.",
      date: "Date",
      date_helperText: "When is your event?",
      end_date: "End Date",
      end_date_helperText: "When does your event end?",
      location: "Location",
      location_helperText: "Where is your event?",
      common_locations: "Commonly Used Locations",
      category: "Category",
      category_helperText: "What type of event is it?",
      team: "Team",
      team_helperText:
        "Which team is hosting your event? You need to tie the event to a team. If you're not part of a team, you can create one.",
      tags: "Tags",
      tags_helperText:
        "Add up to 10 tags to help people find your event (optional)",
      tags_placeholder: "Add tags...",
      private_event: "Private Event",
      private_event_helperText:
        "Is your event private? Only people with the link can see it.",
      collect_food_preferences: "Collect Food Preferences",
      collect_food_preferences_helperText:
        "Do you want to collect food preferences from users?",
      display_attendee_count: "Display Attendee Count",
      display_attendee_count_helperText:
        "Do you want to display the number of attendees for this event?",
      language: "Language",
      language_helperText: "What language will your event be in?",
    },
    ticket_release: {
      name: "Name",
      name_helperText: "What is the name of your ticket batch?",
      description: "Description",
      description_helperText:
        "Describe the types of tickets being released. What should people expect? Markdown is supported.",
      button_text: "Button Text",
      button_text_helperText:
        "What should the button say? (e.g. 'Buy Tickets', 'Register', 'Sign Up')",
      available_at: "Start selling tickets",
      available_at_helperText:
        "When will the tickets be released? This is when users can start requesting tickets.",
      closes_at: "Stop selling tickets",
      closes_at_helperText:
        "When will the ticket release close? This is when users can no longer request tickets.",
      ticket_release_method: "Ticket Release Method",
      ticket_release_method_helperText:
        "How do you want to distribute tickets?",
      lottery_duration: "Lottery Duration (minutes)",
      lottery_duration_helperText:
        "For First Come First Serve, the lottery duration defines the time frame within which requested tickets will be entered into a lottery. If more tickets are requested than available, all requests within this time frame will enter a lottery, the rest will be reserves.",
      max_tickets_per_user: "Tickets per user",
      max_tickets_per_user_helperText:
        "How many tickets can a user request/buy?",
      tickets_available: "Tickets available",
      tickets_available_helperText: "How many tickets will be available?",
      notification_method: "Notification Method",
      notification_method_helperText:
        "How do you want to notify users about their tickets?",
      cancellation_policy: "Cancellation Policy",
      cancellation_policy_helperText: "What is your cancellation policy?",
      reserved_ticket_release: "Reserved Ticket Batch",
      promo_code: "Promo Code",
      promo_code_helperText:
        "What promo code should be used to access this reserved ticket batch?",

      selective_description: "Describe the Allocation Method",
      selective_description_helperText:
        "Specify how your team plans to allocate tickets. This information will be shown to users when they request a ticket.",
      save_template: "Save as Template",
      save_template_helperText:
        "Save this ticket release as a template to use again later.",

      payment_deadline: "Payment Deadline",
      payment_deadline_helperText:
        "When is the payment deadline for users who initially got a ticket?",
      reserve_payment_duration: "Reserve Payment Duration",
      reserve_payment_duration_helperText:
        "Enter the duration in plain text, e.g. '2 days 3 hours' or '45 minutes'. You can use days, hours, and minutes.",
      allocation_cut_off: "Allocation Cut-off",
      basic_details: "Basic Details",
      timing_configuration: "Important Dates",
      distribution_rules: "Ticket Distribution",
      ticket_settings: "Ticket Settings",
      additional_settings: "Additional Settings",
      lottery_window_time:
        "The lottery window will be open from <1>{{start}}</1> to <1>{{end}}</1>",
      reserved_ticket_release_helperText:
        "A reserved ticket release contains tickets reserved for specific users. A promo code is required to access these tickets. You can add more ticket batches later.",
      reserve_payment_duration_summary:
        "Users will have {{days}} days, {{hours}} hours, and {{minutes}} minutes to complete their payment after receiving their ticket.",
    },
    ticket_types: {
      name: "Name",
      name_helperText: "What is the name of this ticket?",
      description: "Description",
      description_helperText:
        "Describe what's included in this ticket. Markdown is supported.",
      price: "Ticket Price",
      price_helperText: "How much does this ticket cost?",
      save_template: "Save as Template",
      save_template_helperText:
        "Save this ticket as a template to use again later.",
    },
    contact: {
      title: "Contact",
      email: "Your Email",
      plan: "Plan",
      email_helperText: "What is the contact email for this ticket?",
      subject: "Subject",
      subject_helperText: "What is the subject for this ticket?",
      name: "Your Name",
      name_helperText: "What is the name of the contact person?",
      message: "Message",
      message_helperText: "What is the message for this ticket?",
      success: "Your message has been sent!",
      fail: "Your message could not be sent. Please try again later.",
      description:
        "If you have any questions, problems, or suggestions, feel free to contact us. We will get back to you as soon as possible.",
      team_name: "Team",
      team_helperText: "Which team are you contacting?",
    },

    event_fields: {
      title: "Custom Event Fields",
      subtitle:
        "Add and edit custom fields for your event. These fields will be shown to users when they request a ticket.",
      label_name: "Question",
      label_description: "Description",
      label_type: "Type",
      label_required: "Required",
      form_field_description: "Description",
      form_field_description_helperText:
        "Describe what additional information you want to collect from users, and why. Markdown is supported.",
      delete_field_confirm:
        "Are you sure you want to delete this field? Deleting this field might result in loss of response data. You must save after deleting!",
      select_field_type: "Select a field type",
      field_name: "Question",
      field_name_placeholder: "Enter question",
      field_description: "Field Description",
      field_description_placeholder: "Enter field description",
      required: "Required",
      options_label: "Options",
      option: "Option",
      option_placeholder: "Enter option",
      button_add_option: "Add Option",
      button_delete_option: "Delete Option",
      delete_field: "Delete Field",

      templates: {
        title: "Form Field Templates",
        seating: {
          name: "Seating Preference",
          description: "Ask attendees about their seating preferences",
          field: {
            name: "Who would you prefer to sit next to?",
            description: "Let us know if you have any seating preferences",
          },
        },
        dietary: {
          name: "Dietary Requirements",
          description: "Collect dietary requirements from attendees",
          field: {
            name: "Do you have any dietary requirements?",
            description: "Select all that apply",
            options: [
              "Vegetarian",
              "Vegan",
              "Gluten-free",
              "Dairy-free",
              "Nut allergy",
            ],
          },
        },
        accessibility: {
          name: "Accessibility Needs",
          description: "Ask about accessibility requirements",
          field: {
            name: "Do you have any accessibility requirements?",
            description: "Help us accommodate your needs",
          },
        },
        emergency: {
          name: "Emergency Contact",
          description: "Collect emergency contact information",
          field: {
            name: "Emergency contact details",
            description:
              "Please provide name and phone number of emergency contact",
          },
        },
        tshirt: {
          name: "T-Shirt Size",
          description: "Collect t-shirt size preferences",
          field: {
            name: "What is your t-shirt size?",
            description: "Select your preferred t-shirt size",
          },
        },
      },
    },

    addon: {
      name: "Name",
      name_helperText: "What is the name of this addon?",
      description: "Description",
      description_helperText: "Describe this addon. Markdown is supported.",
      price: "Price (SEK)",
      price_helperText: "How much does this addon cost?",
      max_quantity: "Max Quantity",
      max_quantity_helperText: "How many of this addon can a user buy?",
      is_enabled: "Enabled",
      is_enabled_helperText: "An enabled addon is available for purchase.",
      contains_alcohol: "Contains Alcohol",
      contains_alcohol_helperText: "Does this addon involve alcohol?",
    },

    banking_details: {
      vat: "VAT",
      vat_helperText:
        "The VAT percentage that will be applied to all ticket sales.",
      user_pays_ticket_fee: "Customer pays ticket fee",
      user_pays_ticket_fee_helperText:
        "If enabled, the customer will pay the ticket processing fee instead of the organizer.",
    },

    domain_details: {
      slug: "Subdomain",
      slug_helperText:
        "Add a custom subdomain for your event. This is the page your attendees will visit to buy tickets.",
      add_domain: "Add Domain",
      allowed_domains: "Allowed Domains",
      domain_helperText: "What domains are allowed to access this event?",
    },

    manager: {
      setup: {
        business_details: {
          legal_name: "Legal Name",
          legal_name_helperText: "Legal name of your business.",
          corporate_id: "Corporate ID",
          corporate_id_helperText: "Corporate ID of your business.",
          business_email: "Business Email",
          business_email_helperText: "Email of your business.",
          country: "Country",
          country_helperText: "Country of your business.",
          phone_number: "Phone Number",
          phone_number_helperText: "Phone number of your business.",
          address_line1: "Address Line 1",
          address_line1_helperText: "Address Line 1 of your business.",
          address_line2: "Address Line 2",
          address_line2_helperText: "Address Line 2 of your business.",
          city: "City",
          city_helperText: "City of your business.",
          postal_code: "Postal Code",
          postal_code_helperText: "Postal code of your business.",
          terms_and_conditions_url: "Terms and Conditions URL",
          terms_and_conditions_url_helperText:
            "URL to your business's terms and conditions.",
          privacy_policy_url: "Privacy Policy URL",
          privacy_policy_url_helperText:
            "URL to your business's privacy policy.",
        },
      },
    },

    organization: {
      edit: {
        title: "Edit Organization",
        subtitle:
          "Edit the settings of your team. This includes both the business details and the store customization, which changes the appearance of your store when a user purchases a ticket.",
        description: "Description",
        description_helperText:
          "Describe your organization. What should people expect? Markdown is supported.",
        short_description: "Short Description",
        short_description_helperText:
          "Provide a short description of your organization.",
        store_customization: {
          title: "Store Customization",
          subtitle:
            "Customize the appearance of your store when a user purchases a ticket.",
          background_color: "Background Color",
          background_color_helperText:
            "Choose a background color for your store.",
          brand_color: "Brand Color",
          brand_color_helperText: "Choose a brand color for your store.",
          accent_color: "Accent Color",
          accent_color_helperText: "Choose a color that matches your brand",
          rect_shape: "Button Shape",
          rect_shape_helperText: "Choose the shape of your buttons.",
          rect_shapes: {
            rounded: "Rounded",
            edgy: "Edgy",
            pill: "Pill",
          },
          font_type: "Font Type",
          font_type_helperText: "Choose the font type for your store.",
          font_types: {
            sans_serif: "Sans Serif",
            serif: "Serif",
            mono: "Monospace",
          },
          logo_url: "Logo",
          logo_url_helperText: "Upload your organization's logo.",
          icon_url: "Icon",
          icon_url_helperText:
            "Upload your organization's icon. Recommended aspect ratio is 1:1.",
          current_url: "Current URL",
        },
      },
    },

    help: {
      event_name:
        "Enter the event name. Keep it short, catchy, and descriptive.",
      event_description:
        "Provide an overview of the event. Markdown is supported.",
      event_date: "Pick the date and start time for your event.",
      event_end_date:
        "End date/time if your event spans multiple hours or days.",
      event_location: "This is the location/venue of your event.",
      event_language: "The language of your event.",
      event_private:
        "Determines who can see the event. If private, only people with the link can see it.",
      event_food_preferences: "Collect food preferences from attendees.",
      event_team: "The team that is hosting the event.",
      event_display_attendee_count:
        "Display the number of attendees for this event.",
      event_tags: "Enter tags for this event, press enter to add a new tag",

      ticket_release_name: "Enter the name of the ticket release.",
      ticket_release_description:
        "Describe the ticket release. For instance describe the different types of tickets being released. Markdown is supported.",

      ticket_release_open: "When will the tickets be available? (Local time)",
      ticket_release_close: "When will the ticket release close? (Local time)",

      // Release Method
      ticket_release_method: "Choose how tickets will be distributed",
      ticket_release_notification_method: "How users will be notified",
      ticket_release_method_description:
        "Explain who this selective release is for",
      ticket_release_open_window: "How long the lottery window stays open",

      // Quantities
      ticket_release_max_tickets: "Maximum tickets one person can buy",
      ticket_release_quantity:
        "Total number of tickets available in this release",

      // Notifications
      notification_method: "How users will be notified about their tickets",

      // Policies
      ticket_release_cancellation_policy:
        "What happens if someone cancels their ticket",

      // Reserved Settings
      ticket_release_is_reserved:
        "A reserved ticket release contains tickets reserved for specific users. A promo code is required to access these tickets. You can add more ticket batches later.",
      ticket_release_promo_code: "Code needed to access reserved tickets",
      ticket_release_save_template: "Save this ticket release as a template",
      ticket_release_allocation_cut_off:
        "When will the allocation of tickets be cut off? This is when the system will stop allocating tickets.",

      ticket_release_reserve_payment_duration:
        "When a reserve receives a ticket, how long do they have to pay before it's given to the next person in line?",

      // Payment Settings
      ticket_release_payment_deadline: "Last day to pay for allocated tickets",
    },

    button_sign_in: "Sign in",
    button_add_field: "Add Field",
    button_clear: "Clear",
    button_next: "Next",
    button_edit: "Edit",
    button_create: "Create",
    button_back: "Back",
    button_restart: "Restart",
    button_confirm: "Confirm",
    button_cancel: "Cancel",
    button_create_event: "Publish Event",
    button_save: "Save",
    button_submit: "Submit",
    button_apply: "Apply",
    button_invite: "Invite",
    button_manage: "Manage",
    button_details: "Details",
    button_request: "Request",
    button_purchase: "Purchase",
    button_delete: "Delete",
    button_send: "Send",
    button_check_in: "Check in",
    button_update_gdpr: "Save and Renew Consent",
    button_send_out: "Send Out",
    button_economy: "Economy",
    button_close: "Close",
    button_add: "Add",
    button_templates: "Templates",
    generate_sales_report: "Generate Sales Report",
    button_continue: "Continue",
    button_create_private_event: "Create private event",
    errors: {
      title: "Please fix the following errors:",
    },
  },

  create_event: {
    title: "Create Event",
    create_event_description:
      "Create an event to manage ticket releases, attendees, and more. tessera makes it as easy as possible. We will walk you through the process step by step.",
    event_details_title: "Event Details",
    event_details_description:
      "Let's start with the basics. What are the details of your event?",
    ticket_release_title: "Create Ticket Batch",
    ticket_release_description:
      "A ticket batch contains a group of tickets. Use multiple tickets batches when you to seperate batches based on selling start and end dates or when using promo codes for reserved tickets.",
    ticket_release_description_example:
      "More ticket batches can be created later.",
    ticket_release: "Ticket Batch",
    ticket_release_helperText:
      "Let's define the details for this ticket batch.",
    confirm_event_creation_restart_text:
      "Are you sure you want to restart event creation? All progress will be lost.",
    ticket_types_title: "Tickets",
    ticket_types_description:
      "The tickets you create here will be apart of the ticket batch you previously defined. Additional tickets can be created later in the event dashboard.",
    ticket_types: "Tickets",
    ticket_types_helperText:
      "Here you can specify the details of your different tickets.",
    finish_title: "That's it!",
    finish_description:
      "You have successfully filled out all the details for your event. Click the button below to create your event. You can also go back and edit your event, or do so later on the edit event page.",
    no_teams:
      "You are currently not part of any team, and therefore cannot create an event. Contact your organization's administrator to create a team.",
    review_submit_title: "That's it!",
    select_category: "Select a category",
    ticket_types_select_helperText: "Select a ticket to edit it's details.",
  },

  templates: {
    title: "Saved Templates",
    ticket_releases: {
      description:
        "You can create new templates by ticking the 'Save as Template' checkbox when creating a ticket release. Here you can see all your saved templates. Click 'Create' to create a new event from a template.",
      no_templates: "There are no templates available.",
    },
    ticket_types: {
      description:
        "Templates for tickets are a bit different from ticket batches. Saving a template will assume that you will keep that template for the ticket release you are editing. Editing your template will automatically update all ticket batches that use that template.",
      no_templates: "There are no templates available.",
    },
  },

  profile: {
    title: "Profile",
    user_info_title: "Details",
    full_name: "Full Name",
    first_name: "First Name",
    last_name: "Last Name",
    phone: "Phone Number",
    email: "Email",
    username: "Username",
    roles: "Roles",
    teams: "Teams",
    deactivate_account: {
      button_deactivate: "Deactivate Account",
      button_reactivate: "Reactivate Account",
      title: "Deactivate Account",
      deactivate_account_confirmation_title: "Confirm Account Deactivation",
      deactivate_account_confirmation_text:
        "Warning: By deactivating your account, all your data including tickets, preferences, and personal information will be permanently deleted after 30 days. This action cannot be undone after the 30-day period. You will receive an email once the deletion is completed.",
      deactivate_account_confirmation_enter_text:
        'Please enter "delete account" to confirm.',
    },
    reactivate_account: {
      title: "Reactivate Account",
      description:
        "Your account is currently deactivated. Would you like to reactivate it?",
      button_reactivate: "Reactivate Account",
      countdown: "Time remaining to reactivate: {{seconds}} seconds", // Add this line
    },
    tabs: {
      info: "Profile",
      tickets: "Your Tickets",
    },

    links_and_buttons: {
      your_ticket_requests: "My Ticket Requests",
      your_tickets: "My Tickets",
      your_teams: "My Teams",
    },

    food_preferences: {
      title: "Food Preferences",
      saved: "Your food preferences have been stored.",
      allergies_and_dietary_restrictions: "Allergies and Dietary Preferences",
      allergies_and_dietary_restrictions_select:
        "Allergies and Dietary Preferences",
      allergies_and_dietary_restrictions_helperText:
        "Select all that apply, leave blank if none.",
      additional_notes: "Additional Notes",
      additional_notes_helperText:
        "Provide any additional notes about your food preferences. Leave blank if none.",
      privacy_policy_title: "Privacy Policy",
      gdpr_agree_helperText:
        "I agree to the processing of my personal data for managing my food preferences according to the <1>Privacy Policy</1>.",
      no_preferences: "You have no food preferences.",
      select_placeholder: "None selected, no preferences",
      additional_notes_required: "Additional notes are required.",
      gluten_intolerant: "Gluten Intolerant",
      lactose_intolerant: "Lactose Intolerant",
      vegetarian: "Vegetarian",
      vegan: "Vegan",
      nut_allergy: "Nut Allergy",
      egg_allergy: "Egg Allergy",
      soy_allergy: "Soy Allergy",
      shellfish_allergy: "Shellfish Allergy",
      kosher: "Kosher",
      halal: "Halal",
      prefer_meat: "I Prefer Meat If Available",
      other: "Other",
    },

    your_ticket_requests: {
      title: "My Ticket Requests",
      description:
        "Listed below are all the events you currently have a ticket for. Click on a ticket to see more details.",
      no_upcoming_events: "You have no upcoming events.",
      past_events: "Past Events",
      no_past_events: "You have no past events.",
      no_ticket_requests: "You have no ticket requests.",
      upcoming_events: "Upcoming Events",
      show_past_events: "Show",
      hide_past_events: "Hide",
    },

    your_tickets: {
      title: "My Tickets",
      description:
        "Here you can see all the tickets you have received. You can give up your ticket by clicking on it and choosing 'I no longer wish to attend', which will pass your ticket to the next person in line. If you have not yet been allocated a ticket, you can see your ticket requests <1>here</1>.",
      upcoming_events: "Upcoming Events",
      no_upcoming_events: "You have no upcoming events.",
      past_events: "Past Events",
      no_past_events: "You have no past events.",
      no_tickets: "You have no tickets.",
      order_id: "Order ID",
      show_deleted: "Show Deleted",
      tickets_to: "Your tickets to",
    },

    your_teams: {
      title: "Your Teams",
      description:
        "Here you can see all the teams you are a part of. Click on a team to see more details. You can create a new team <1>here</1>.",
      not_part_of_any_teams: "No teams to list.",
      add_user: "Add User",
      add_user_helperText:
        "Add a user within your network to this team. The role can be changed later.",
      manage_team_events: "Manage Team Events",
      no_events: "There are no events in this team",
      users: "Users",
      no_users: "There are no users in this team.",
      delete_team: "Delete Team",
      team_name_title: "Team Name",
      edit_team: "Edit Team",
      delete_confirmation_title: "Confirm Team Deletion",
      delete_confirmation_body:
        "Are you sure you want to delete this team? This action cannot be undone.",
      events: "Events",
    },
  },

  create_team: {
    title: "Create Team",
    what_is_a_team: "What is a Team?",
    description:
      "Create a team to organize events and manage users. As administrator of your network you can create and manage teams. Create a team for yourself or invite a team manager that will lead the team. Users within your network can be part of multiple teams.",
    description_2:
      "Before you assign a user to a team, ensure that they are part of your network. You can invite users to your network <1>here</1>.",
    your_teams_text: "Your Teams",
    add_team_title: "Team Name",
    add_team_helperText:
      "This will be the name of your team. You will automatically be the owner of this team.",
    create_team_button: "Create Team",
    add_team_email: "Team Email",
    add_team_email_helperText:
      "This will be the email of your team. It is used for contacting the team.",
  },

  manager: {
    dashboard: {
      events: "Events",
      past_events: "Past Events",
      upcoming_events: "Upcoming Events",
      events_description: "Here you can see all the events in your network.",
      no_events: "There are no events to show.",
      no_events_create: "Create your first event",
    },

    danger: {
      warning_zone: "Warning Zone",
      delete_warning: "This action cannot be undone.",
    },

    setup: {
      title: "Onboarding",
      description: "Complete your profile to start creating events.",
    },

    onboarding: {
      welcomeMessage: "Welcome to tesseras Event Manager Setup & Onboarding!",
      intro:
        "Welcome to the onboarding setup page for event managers. As an event organizer, you are just a few steps away from setting up your payment processing system through Surfboard Payments. Please follow the instructions below to provide the necessary details and complete the onboarding process.",
      stepByStepGuide: "Step-by-Step Guide to Complete Onboarding",
      steps: {
        step1: {
          title: "Fill in Business Details",
          description:
            "Please provide the necessary business details in the form below. This information will be used to create your merchant account and online store.",
        },
        step2: {
          title: "Fill out KYB Form",
          active: "Click the link below to fill out the KYB form.",
          description:
            "tessera will generate a Know Your Business (KYB) link for you from the Surfboard Payments partner portal. This link will be sent to your registered email address.",
        },
        step3: {
          title: "Verification Process",
          description:
            "Once you submit your details, Surfboard Payments will verify the legitimacy of your business. This process typically takes 1-4 business days. You will receive a notification via email once your business details have been approved.",
        },
        step4: {
          title: "Account Creation",
          description:
            "After approval, Surfboard Payments will automatically create a merchant account and an online store for your business. You will receive the credentials and relevant information for accessing your merchant account.",
        },
      },
      customization: {
        title: "Customization",
        description:
          "The onboarding web-app can be customized to match the branding of your business. Please provide the necessary details below.",
      },
      importantLinks: {
        title: "Important Links",
        description:
          "If you want to learn more about Surfboard Payments, please visit the following links:",
        links: {
          partnersGuideTitle: "Partners Guide",
          partnersGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/partners",

          merchantsGuideTitle: "Merchants Guide",
          merchantsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/merchants/merchants-home",

          storesGuideTitle: "Stores Guide",
          storesGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/stores/stores-home",

          terminalsGuideTitle: "Terminals Guide",
          terminalsGuideURL:
            "https://developers.surfboardpayments.com/docs/concepts/terminals/terminals-home",

          onboardingRequirementsTitle: "Onboarding Requirements",
          onboardingRequirementsURL:
            "https://developers.surfboardpayments.com/docs/concepts/onboarding#requirements-for-onboarding",

          onlinePaymentsGuideTitle: "Online Payments Guide",
          onlinePaymentsGuideURL:
            "https://developers.surfboardpayments.com/docs/guides/online-payments",
        },
      },
      needHelp: {
        title: "Need Help?",
        description:
          "If you have any questions or need assistance during the onboarding process, please contact us at <1>info@tesseratickets.se</1>.",
      },
      thankYou: "Thank you for choosing tessera!",
    },

    settings: {
      general: {
        general_information: "General Information",
        title: "Network Settings",
        subtitle:
          "Totally customize the look of your dashboard and make it truly yours. Choose your main color, accent color, and text color. Note that these colours will also be used for your default event pages.",
        main_color: "Main Color",
        accent_color: "Accent Color",
        text_color: "Text Color",
        pick_color: "Pick {{color}}",
        close_color_picker: "Close {{color}} Picker",
        banner: "Banner",
        upload_banner: "Upload Banner",
        background_color: "Background Color",
        short_description: "Short Description",
        short_description_helperText:
          "Provide a shortend description of your network.",
        description: "Description",
        description_helperText:
          "This will be displayed on your network's business page. Markdown is supported.",
        network_look: "Network Look",
        network_look_description:
          "Edit dashboard colors and upload a banner, which will be displayed on your network's business page.",
      },
      users: {
        title: "Network Users",
        description:
          "Here you can invite users to your network by sending them an email invitation. You can also manage the roles of users in your network. Users can be assigned the roles of Super Admin, Admin, or Member. When a user accepts an invitation, they will be able to access the network and can be added to the teams within the network to start creating events.",
        no_users: "There are no users in this network.",
        delete_network: "Delete Network",
        delete_network_confirmation:
          "Are you sure you want to delete this network? This action cannot be undone.",
        delete_network_confirmation_title: "Confirm Network Deletion",
        edit_network: "Edit Network",
        invite_user: "Invite User",
        invite_user_helperText:
          "Enter the email of the user you want to invite.",
        user_added_success: "User added successfully!",
        select_role_placeholder: "Select role",
        role_super_admin: "Super Admin",
        role_admin: "Admin",
        role_member: "Member",
        invite_pending: "Invite Pending",
        no_pending_invites: "There are no pending invites.",
      },
      subscription: {
        title: "Subscription",
        description:
          "Manage your subscription plan. You can upgrade your plan to get more features.",
        current_plan: "Current Plan",
        upgrade_plan: "Upgrade Plan",
        upgrade_plan_description: "In BETA, upgrading plans is not available.",
        downgrade_plan: "Downgrade Plan",
        cancel_plan: "Cancel Plan",
        cancel_plan_confirmation:
          "Are you sure you want to cancel your plan? This action cannot be undone.",
        cancel_plan_confirmation_title: "Confirm Plan Cancellation",
        payment_plan: "Payment Plan",
        enabled_features: "Enabled Features",
        enabled_features_description:
          "In BETA, all features are enabled, here is a list of all the features you can use.",
      },
      customization: {
        title: "Merchant Customization",
        description:
          "Customize the look of your merchant portal. Make yourself unique and apply your brand colors and logo.",
        background_color: "Background Color",
        background_color_helperText:
          "Choose a background color for your network.",
        brand_color: "Brand Color",
        brand_color_helperText: "Choose a brand color for your network.",
        accent_color: "Accent Color",
        accent_color_helperText: "Choose an accent color for your network.",
        rect_shape: "Rectangle Shape",
        rect_shape_helperText: "Choose the shape of your buttons.",
        upload_logo: "Upload Logo",
        logo_helperText:
          "The file can not be bigger than 3000x3000 pixels and exceed 2MB.",
        upload_icon: "Upload Icon",
        rect_shapes: {
          rounded: "Rounded",
          edgy: "Edgy",
          pill: "Pill",
        },
        font_type: "Font Type",
        font_type_helperText: "Choose the font type for your network.",
        font_types: {
          sans_serif: "Sans Serif",
          serif: "Serif",
          mono: "Monospace",
        },
        logo_url: "Logo URL",
        logo_url_helperText: "Enter the URL of your network's logo.",
        icon_url: "Icon URL",
        icon_url_helperText:
          "Enter the URL of your network's icon. Recommended aspect ratio is 1:1.",
        save_settings: "Save Settings",
        reset_settings: "Reset Settings",
        save_logo: "Save Logo",
      },
      social_media: {
        title: "Social Media",
        subtitle:
          "Add your social media links to your network. These links will be displayed on your network's business page.",
        facebook: "Facebook",
        twitter: "Twitter",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        youtube: "YouTube",
        tiktok: "TikTok",
        website: "Website",
      },
      api: {
        title: "API Keys",
        no_keys: "You have not created any API keys yet.",
        buttons: {
          create: "Create New API Key",
          cancel: "Cancel",
          done: "Done",
          revoke: "Revoke",
          download: "Download",
        },
        table: {
          headers: {
            apiKey: "API Key",
            description: "Description",
            permissions: "Permissions",
            rateLimit: "Rate Limit",
            expiresAt: "Expires At",
            status: "Status",
          },
          status: {
            active: "Active",
            revoked: "Revoked",
          },
        },
        createModal: {
          title: "Create New API Key",
          form: {
            description: {
              label: "Description",
              placeholder: "Enter API key description",
              error: "Description is required",
            },
            permissions: {
              label: "Permissions",
              placeholder: "Select permissions",
              error: "At least one permission is required",
            },
            expiresAt: {
              label: "Expires At",
              placeholder: "yyyy-MM-ddTHH:mm",
              error: "Expiration date is required",
            },
            rateLimit: {
              label: "Rate Limit",
              requests: {
                label: "Requests",
                placeholder: "e.g. 10",
                error: "Must be at least 1",
              },
              duration: {
                label: "Duration",
                placeholder: "e.g. 1m",
                error: "Duration is required",
              },
            },
          },
        },
        revokeModal: {
          title: "Confirm Revocation",
          message:
            "Are you sure you want to revoke this API key? This action cannot be undone.",
        },
        successModal: {
          title: "API Key Created Successfully",
          warning:
            "⚠️ This is the only time you'll see the secret key. Please save it somewhere safe.",
          fields: {
            apiKey: "API Key",
            secretKey: "Secret Key",
          },
        },
        messages: {
          fetchError: "Failed to fetch API keys",
          createError: "Failed to create API key",
          revokeError: "Failed to revoke API key",
          fetchPermissionsError: "Failed to fetch permissions",
          revokeSuccess: "API key revoked successfully",
          copySuccess: "Copied to clipboard!",
        },
      },
    },

    teams: {
      title: "{{name}} Teams",
      subtitle:
        "A team is the organizer of event. A network consists of multiple teams that can create events. A network admin can create and add users to teams. Below you can see all the teams in your network, click on a team to see more details.",
    },

    drawer: {
      title: "Network Dashboard",
      events: "Events",
      contact_database: "Contact Database",
      merchant_portal: "Merchant Portal",
      business_page: "Business Page",
      all_events: "All Events",
      teams: "Teams",
      create_event: "Create Event",
      pin: "Pin",
      unpin: "Unpin",
      settings: {
        title: "Settings",
        general: "General",
        subscription: "Subscription",
        financial: "Financial",
        users: "Users",
        customize: "Customization",
        social_media: "Social Media",
        api: "API Keys",
      },
      tessera_ai: "Tessera AI",
    },

    contact_database: {
      title: "Contact Database",
      description:
        "The contact database consists of all the users that interact with your network. When a user requests a ticket to one of your events, they will be added to the contact database. This allows you to invite users to future events.",
      import_button: "Import",
      delete_button: "Delete",
      invite_button: "Invite",
      invite: {
        select_event: "Select Event",
        select_event_helperText:
          "Make sure that you've edited the event invite template before inviting users. You can do this by going to <1>Manage Event > Emails > Templates</1>. Now select the event you want to invite users to.",
        unsubscribed_info:
          "Some users will not receive the email as they are unsubscribed.",
      },
      details: {
        title: "User Details",
        close: "Close",
        contact_details: "Contact Details",
        email: "Email",
        subscribed: "Subscribed",
        yes: "Yes",
        no: "No",
        last_added_info: "Last Added Info",
        last_added_reason: "Last Added Reason",
        last_added_by: "Last Added By",
        logs: "Logs",
        load_more: "Load More",
      },
      import: {
        title: "Import Contacts",
        description:
          "You can import contacts to your contact database by entering their emails below or uploading a CSV file. The CSV file must contain a single column with the email addresses of the contacts you want to import.",
        close: "Close",
        emails_label: "Emails",
        emails_placeholder: "Enter emails separated by commas",
        import_button: "Import",
      },
    },

    ai: {
      title: "Tessera AI Assistant (Alpha)",
      sessions: "Chats",
      session: "Chat",
      no_sessions: "No chats to show.",
      description:
        "Tessera AI is an assistant that can help you get started with creating events, managing your network, and more. You can ask Tessera AI questions, and it will provide you with the information you need. Not that Tessera AI is still in development, and some features may not be available.",
      message: "Your request",
      message_placeholder: "Describe what you want to do...",
      submit: "Send",
      response: "Tessera AI",
      user: "User",
      success: "Request processed successfully",
      select_organization: "Select Organization",
      new_chat: "New Chat",
      use_event_button: "Create Event",
      no_event_name: "No event name",
      mistakes:
        "Tessera AI can make mistakes, always double-check information.",
      welcome_message: {
        assistant_name: "Tessera AI",
        greeting:
          "👋 Hello! I'm your Tessera AI Assistant. I can help you with:",
        features: {
          events: "Creating and drafting new events",
          tickets:
            "Answer questions regarding attendees and tickets to an event",
          food: "Answer questions regarding attendees' food preferences and allergies",
          marketing: "Answer questions regarding marketing and site statistics",
        },
        language_support:
          "I can help you in both Swedish and English. How can I assist you today?",
      },
      landing_page: {
        prompt: "Ask me to help you design your landing page",
        placeholder: "e.g., Create a modern hero section with a signup form",
      },
    },
    ai_create: {
      title: "Create Event with AI",
      initial_prompt: "Tell me about the event you want to create",
      input_placeholder: "Type your response here...",
      thinking: "AI is thinking...",
      restart_session: "Start Over",
      manual_create: "You can also <1>create manually</1>",
      already_answered: "I have already answered this.",
      fields: {
        name: "Name",
        category: "Category",
        description: "Description",
        date: "Date",
        end_date: "End Date",
        location: "Location",
        organization_id: "Organization",
        is_private: "Private Event",
        collect_food_preferences: "Collect Food Preferences",
        language: "Language",
        // Ticket Release fields
        open: "Opens",
        close: "Closes",
        promo_code: "Promo Code",
        is_reserved: "Reserved",
        save_template: "Save as Template",
        tickets_available: "Available Tickets",
        cancellation_policy: "Cancellation Policy",
        notification_method: "Notification Method",
        max_tickets_per_user: "Max Tickets per User",
        ticket_release_method: "Release Method",
        method_description: "Selection Criteria",
        open_window_duration: "Lottery Window Duration",
        payment_deadline: "Payment Deadline",
        reserve_payment_duration: "Reserve Payment Duration",
        allocation_cut_off: "Allocation Cut Off",
        // Ticket Type fields
        price: "Price",
        // Boolean values
        yes: "Yes",
        no: "No",
      },
      sections: {
        event_details: "Event Details",
        ticket_release: "Ticket Release",
        ticket_types: "Ticket Types",
      },
      generated_event_details: "Generated Event Details",
      event_details: "Event Details",
      ticket_release: "Ticket Release",
      ticket_types: "Ticket Types",
      buttons: {
        edit_event: "Edit Event",
        create_event: "Publish Event",
      },
      completion_message:
        "Your event is ready! Feel free to give more instructions if you want.",
    },
  },

  features: {
    limit_description:
      "You have exceeded the limit of {{feature}}. Upgrade your plan to get more.",
  },

  manage_event: {
    title: "Manage {{event_name}}",
    delete_button: "Delete {{event_name}}",
    view_button: "View Event",
    manage_button: "Manage",
    manage_ticket_releases: "Manage Ticket Batches",
    ticket_release_method_title: "Ticket Release Method",
    ticket_release_ticket_info_title: "Ticket Info",
    ticket_orders: "Ticket Orders",
    ticket: "Tickets",
    lottery_entered_ticket_requests: "Lottery Entered Ticket Requests",
    not_lottery_entered_ticket_requests: "Requested After Lottery Window",
    paid_tickets: "Paid Tickets",
    not_yet_paid_tickets: "Not Yet Paid Tickets",
    refunded_tickets: "Refunded Tickets",
    reserve_tickets: "Reserve Tickets",
    ticket_release_actions_title: "Ticket Batch Actions",
    closed: "has closed",
    open: "is open",
    allocate_tickets_button: "Allocate Tickets",
    the_ticket_release: "The ticket batch",
    deleted_ticket_requests: "Deleted Ticket Requests",
    remaining_tickets: "Remaining Tickets",
    deleted_tickets: "Deleted Tickets",
    not_open: "Not Open",
    not_yet_open: "Not Yet Open",
    check_allocated_reserve_tickets: "Try to Allocate Reserve Tickets",
    check_allocated_reserve_tickets_tooltip:
      "Press this button to manually allocate reserve tickets. Useful if you don't want to wait for automatic allocation.",
    allocated_tickets: "Allocated Tickets",
    pay_within_hours: "Users must pay within (hours)*",
    edit_payment_deadline: "Edit Payment Deadline",
    payment_deadline: "Payment Deadline",
    payment_deadline_description:
      "Specify the payment deadline for your event. This does not affect already allocated tickets. Only tickets allocated after this change will be affected.",
    payment_deadline_not_editable:
      "Payment deadline is not editable for this ticket release. The payment deadline is set when tickets are allocated.",
    payment_deadline_helperText:
      "When is the payment deadline for users who initially got a ticket?",
    reserve_payment_duration: "Reserve Payment Duration",
    reserve_payment_duration_helperText:
      "When a reserve receives a ticket, how long do they have to pay before it's given to the next person in line?",
    reserve_payment_duration_text:
      "Allocated reserve tickets have a default payment deadline of {{days}} days, {{hours}} hours, {{minutes}} minutes, and {{seconds}} seconds.",
    allocate_tickets_confirm_title: "Confirm Ticket Allocation",
    allocate_tickets_warning:
      "This ticket release is currently open. Allocating tickets now will automatically close the release. Are you sure you want to allocate tickets now?",
    allocate_tickets_confirm:
      "Are you sure you want to close this ticket release?",
    manage_tickets: "Manage Tickets",
    tickets_available: "Tickets Available",
    allocate_tickets_helperText:
      "How long do users have to pay for their tickets before they're given to the next person in line?",
    delete_event_title: "Confirm Event Deletion",
    delete_event_confirmation:
      "Are you sure you want to delete this event? This action cannot be undone.",
    delete_event_confirmation_enter_text: "Type 'delete' to confirm deletion.",
    delete_ticket_release_confirmation:
      "Are you sure you want to delete this ticket release? This action cannot be undone.",
    manage_tickets_custom_event_form: "Form Responses",
    add_ticket_release: "Add Ticket Batch",
    ticket_release_time_title: "Ticket Release Open and Close",

    breadcrumbs: {
      manage: "Manage",
      edit: "Edit",
      ticket_releases: "Ticket Batch",
      event: "Event",
      add_ons: "Addons",
      ticket_types: "Tickets",
      form: "Form",
      send_outs: "Send Outs",
      tickets: "Tickets",
      event_page: "Event Page",
      emails: "Emails",
      email_templates: "Email Templates",
    },

    overview: {
      title: "Overview",
      site_visits: "Site Visits",
      unique_visitors: "Unique Visitors",
      num_ticket_orders: "Ticket Orders",
      total_income: "Revenue",
      total_ticket_orders: "Ticket Orders",
      total_paid_tickets: "Paid Tickets",
      total_refunded_tickets: "Refunded Tickets",
      total_revenue: "Revenue",
      total_remaining_tickets: "Remaining Tickets",
      revenue_per_ticket: "Revenue per Ticket",
      event_capacity: "Event Capacity",
      abandoned_orders: "Abandoned Orders",
      time_to_payment_avg: "Time to Payment (avg)",
      event_is_at_capacity: "Event is at capacity",
      tickets_remaining: "tickets remaining",
      from_creation_to_payment: "From creation to payment",
      total_payable_tickets: "Payable Tickets",
    },
    no_data: "No data available.",

    ticket_releases: {
      title: "Manage Ticket Batches",
    },

    settings: {
      financial: {
        title: "Financial Settings",
        description:
          "Manage the financial settings for your event. Add your banking details and view the financial status of your event.",
      },

      domain: {
        title: "Domain Settings",
        description: "Manage the domain settings for your event,",
      },
    },

    drawer: {
      title: "Event Dashboard",
      settings: {
        title: "Settings",
        financial: "Financial",
        emails: "Emails",
        domains: "Domains",
      },

      edit: {
        event: "Edit Event",
        ticket_releases: "Edit Ticket Batches",
        ticket_types: "Edit Tickets",
        form: "Edit Form",
        event_page: "Edit Event Page",
      },

      send_outs: {
        title: "Send Outs",
        list: "List",
        new: "New",
      },

      emails: {
        title: "Emails",
        templates: "Templates",
      },

      manage: {
        title: "Management",
        overview: "Overview",
        attendees: "Attendees",
        ticket_releases: "Ticket Batches",
        check_in: "Check In",
        form_responses: "Form Responses",
      },

      economy: {
        title: "Economy",
        sales_report: "Sales Report",
        pay_outs: "Pay Outs",
      },

      tools: {
        title: "Tools",
        seating_plan: "Seating Plan",
        meal_management: "Meal Management",
        embed_event: "Embed Event",
      },

      is_not_pinned: "Pin Menu",
      is_pinned: "Unpin Menu",
    },

    tickets: {
      title: "Manage Attendees",
      no_tickets: "There are no attendees for this event yet.",

      user: {
        info: "Attendee Info",
        food_preferences: "Food Preferences",
        full_name: "Full Name",
        username: "Username",
        id: "ID",
        email: "Email",
        phone: "Phone",
        is_guest: "Is Guest",
      },

      ticket_info: {
        id: "ID",
        title: "Ticket Info",
        ticket_batch: "Tickets",
        ticket_release: "Ticket Batch",
        requested_at: "Requested At",
        is_paid: "Is Paid",
        allocated: "Allocated",
        purchasable_at: "Purchasable At",
        payment_deadline: "Payment Deadline",
        entered_into_lottery: "Entered into Lottery",
        is_reserve: "Is Reserve",
        paid_at: "Paid At",
        checked_in: "Checked In",
        deleted_at: "Deleted At",
        is_deleted: "Is Deleted",
        deleted_reason: "Reason",
        ticket_type: "Ticket Type",

        ticket_types: {
          ticket: "Ticket",
          ticket_request: "Ticket Request",
        },

        add_ons: {
          title: "Addons",
          name: "Name",
          price: "Price",
          quantity: "Quantity",
          contains_alcohol: "Contains Alcohol",
        },

        edit: {
          title: "Edit Ticket Info",
          checked_in_helperText: "Has the user checked in?",
          payment_deadline_helperText:
            "The user will receive an email with the updated payment deadline.",
        },
      },

      event_form: {
        title: "Form Responses",
      },

      ticket_actions: {
        title: "Ticket Actions",
        allocate: "Allocate",
      },

      payment_info: {
        title: "Payment Info",
        currency: "Currency",
        amount: "Amount",
        refunded: "Refunded",
        refunded_at: "Refunded At",
        payment_method: "Payment Method",
      },

      ticket_count: "{{count}} ticket(s) - Total Cost: {{totalCost}} SEK",
      ticket: "Ticket",
      chips: {
        paid: "Paid",
        pay_now: "Pay Now",
        not_paid_in_time: "Not Paid in Time",
        refunded: "Refunded",
        deleted: "Deleted",
        handled: "Handled",
        not_handled: "Not Handled",
        reserved: "Reserved",
        checked_in: "Checked In",
      },
    },

    economy: {
      title: "Event Economy",
      subtitle:
        "View the financial details of your event. Generate sales reports and see total revenue.",
      sales_reports: "Sales Reports",
      total_sales: "Total Sales",
      tickets_sold: "Tickets Sold",
      created_at: "Created At",
      status: "Status",
      message: "Message",
      download: "Download",
      no_reports: "There are no sales reports for this event.",
    },

    private_event: {
      title: "This is a Private Event",
      subtitle: "This event is private. Share the link below with invitees.",
      description: "Copy the link below to share the event.",
    },
    send_out: {
      title: "Send Outs",
      new: "New Send Out",
      description:
        "Send an email to all users who have requested a ticket for this event. Notify users about the ticket release or allocation.",
      preview: "Preview",
      subject: "Subject",
      subject_helperText: "What is the subject of the email?",
      message: "Message",
      message_helperText:
        "What are the contents of the email? Markdown is supported.",
      preview_helperText: "This is how the email will appear to users.",
      ticket_releases: "Select Ticket Batches",
      ticket_releases_helperText:
        "The users of the selected ticket batches will receive the email.",
      filter_tickets: "Filter Tickets",
      filter_tickets_helperText:
        "Filter tickets based on their status. Multiple statuses can be selected.",
      num_users: "The email will be sent to {{numUsers}} users.",

      recipients: "Recipients",
      status_message: "Status Message",
      no_send_outs: "There are no send outs for this event.",
    },
    edit: {
      title: "Edit Event",
      subtitle:
        "On this page you can edit the details of your event and the event banner.",
      event_details: {
        title: "Event Details",
        description: "Edit the details of your event.",
      },
      ticket_releases: {
        title: "Edit Ticket Batch",
        edit_name: "Edit {{name}}",
        subtitle:
          "Create and edit ticket releases for your event. Create as many as you want.",
        select: "Select a ticket batch to edit.",
        add: "Add Ticket Release",
        add_subtitle: "Define the details for this ticket release.",
        closed: "Closed",
        no_ticket_releases: "There are no available tickets for this event.",
        edit_ticket_types: "Edit Tickets",
        add_helperText: "Select a ticket batch to edit.",

        edit_add_ons: "Edit Ticket Addons",
      },

      add_ons: {
        title: "Edit Addons",
        subtitle: "Addons to the ticket release.",
        form_title: "Addon Form",
        form_subtitle: "Define your addons here.",
        confirm_delete_title: "Confirm Addon Deletion",
        confirm_delete_text:
          "Are you sure you want to delete this addon? This action cannot be undone.",
      },

      ticket_types: {
        title: "Edit Tickets",
        ticket_details: "Ticket Details",
        ticket_details_helperText:
          "Modify the details of your ticket types and then click 'Save'.",
        select_ticket_type: "Select Ticket Type",
      },

      event_page: {
        title: "Edit Event Page",
        description:
          "Choose between a default event page or customize it to your liking.",
        enable_description: "You can edit the landing page before enabling it.",
        enable: "Use Custom Event Page",
        enabled: "Enabled",
        disabled: "Disabled",
        editor_title: "The Editor",
        editor_description1:
          "The event page editor is a powerful tool to help you create a custom event page. Add text, images, videos, and more to design the page as you want. We take care of the rest.",
        editor_description2:
          "The editor does not update automatically if you edit your event details, organization details, which includes descriptions or colours. It is therefore recommended to create your custom event page after you have finished editing your event details. If you change the details of your event, you will need to update your custom event page manually, alternately reset the page to default to allow the new details to be displayed, however this will remove custom components you have added.",
        editor_description3:
          "The editor is a completely new feature in BETA and has therefore not been fully tested. If you encounter any issues, please create a support ticket.",
        editor_button: "Go to Editor",
      },

      banner: {
        title: "Edit Banner",
        description:
          "Upload a banner for your event. Image dimension of 1920x1080 is recommended.",
        upload_button: "Upload Banner",
        current_banner: "Current Banner",
        remove_button: "Remove Banner",
      },
    },

    form_field_responses: {
      list_view: "List View",
      table_view: "Table View",
      no_form_responses: "There are no form responses for this event.",
      no_form_responses_for_ticket:
        "There are no form responses for this ticket.",
    },

    email_templates: {
      title: "Event Email Templates",
      description: "Customize email templates for your event.",
      edit_email_template: "Edit Email Template",
      template_name: "Template Name",
      subject: "Subject",
      body: "Body",
      use_variables:
        "Fill out the contents of the email template. You can use the variables below to personalize the email.",
      add_variable: "Add {{variable}}",
      save_template: "Save Template",
      validation: {
        name_required: "Template name is required",
        subject_required: "Subject is required",
        body_required: "Body is required",
        body_invalid_variables:
          "Body contains variables that are not in the required data",
      },
    },

    meals: {
      title: "Meal Management",
      edit_meal: "Edit Meal",
      add_meal: "Add New Meal",
      name: "Meal Name",
      name_placeholder: "Enter meal name",
      description: "Description",
      description_placeholder: "Enter meal description",
      tags_label: "Dietary Tags",
      tags: enMealTags,
      select_tags: "Select dietary tags",
      custom_tag: "Custom Tag",
      custom_tag_placeholder: "Add custom dietary tag",
      add_tag: "Add Tag",
      tag_exists: "Tag already exists",
      update_meal: "Update Meal",
      submit_meal: "Submit Meal",
      search_placeholder: "Search meals...",
      dietary_conflicts: "Dietary Conflicts",
      no_conflicts: "No dietary conflicts found",
      dietary_preferences: "Dietary Preferences",
      additional_notes: "Additional Notes",
      conflicts: {
        gluten: "Can't eat gluten",
        dairy: "Requires dairy-free or lactose-free meal",
        vegetarian: "Is vegetarian",
        vegan: "Is vegan",
        nuts: "Can't eat nuts",
        eggs: "Can't eat eggs",
        soy: "Can't eat soy",
        shellfish: "Can't eat shellfish",
        kosher: "Not kosher",
        halal: "Not halal",
        meat: "Doesn't eat meat",
        other: "Other",
        other_details: "Other: {{details}}",
      },
    },

    embed_event: {
      title: "Embed Event",
      description:
        "By embedding your event, you directly integrate it into your website. Copy the code below and paste it into your website. If you require more customization, by for instance showing multiple events, you can do so by using tesseras <1>Event API</1>.",
      domain_warning:
        "Before embedding, ensure your domain is added to the allowed domains in <1>Domain Settings</>",
      add_script: "Include CDN and HTML",
      add_js: "Add Javascript",
      example: "This is an example of how it will look on your website.",
    },

    tools: {
      seating_plan: {
        save_button: "Save Seating Plan",
        auto_seating: {
          button: "Auto-Assign Seats",
          modal_title: "Auto-Assign Seating Configuration",
          description: "The auto-assign feature will:",
          features: {
            use_preferences:
              "Use selected seating preference questions to group attendees",
            keep_groups: "Try to keep groups together at the same tables",
            distribute: "Distribute attendees evenly across available tables",
            consider_capacity:
              "Consider table capacities when making assignments",
            privacy_note: "Note: DOES NOT use AI-methods",
          },
          using_responses:
            "Using responses from the selected seating preference question to create groups.",
          select_question:
            "Please select a seating preference question to use for grouping.",
          custom_groups: {
            title: "Custom Groups (Optional)",
            description:
              "Add custom group names to be considered during seating assignment",
            input_placeholder: "Enter a group name and press Enter",
            add_button: "Add Group",
          },
          actions: {
            cancel: "Cancel",
            generate: "Generate Seating Plan",
          },
          success: "Seating plan generated successfully!",
          unassigned_warning:
            "{{count}} attendees could not be assigned to tables.",
          error: "Failed to generate seating plan. Please try again.",
        },
        create_table: {
          title: "Create New Table",
          type_label: "Table Type",
          type_round: "Round",
          type_square: "Square",
          type_rectangle: "Rectangle",
          num_chairs: "Number of Chairs",
          table_size: "Table Size",
          table_width: "Table Width",
          table_height: "Table Height",
          short_side_seats: "Short Side Seats",
          yes: "Yes",
          no: "No",
          add_button: "Add Table",
        },
        edit_table: {
          title: "Edit Table",
          assigned_attendees: "Assigned Attendees",
          drag_drop_hint:
            "Drag and drop attendees here to assign to this table",
        },
        attendees: {
          unassigned: "Unassigned Attendees",
          search_placeholder: "Search attendees...",
          search_helper_text: "Search by name, email, or preferences",
          no_attendees: "No unassigned attendees",
        },
        seating_preference: {
          label: "Select Seating Preference Questions",
          description:
            "Choose which form fields to use for seating preferences",
          no_fields: "No seating preference questions available",
          placeholder: "Select seating preference questions",
        },
        floor_plan: {
          title: "Floor Plan",
          description: "Upload a floor plan image to help with table placement",
          upload_button: "Upload Floor Plan",
          drag_drop: "Drag and drop an image here or click to select",
          file_types: "Supported formats: PNG, JPG, JPEG",
          error: "Error uploading floor plan",
          success: "Floor plan uploaded successfully",
        },
      },
    },
  },

  ticket_release_method: {
    first_come_first_served_title: "First Come First Served",
    first_come_first_served_description:
      "First Come First Serve Lottery is a ticket release method where people who request a ticket within a specified time frame are entered into a lottery. When tickets are allocated, all requests within this time frame are entered into a lottery, and winners are selected randomly. Winners receive a ticket, and the rest are placed on the waitlist. Anyone requesting a ticket after the specified time frame is placed on the waitlist unless the lottery is not full. If the lottery is not full, remaining tickets are distributed to those on the waitlist in the order they requested.",
  },

  ticket_request: {
    description:
      "Your ticket request has been received! You will be notified if you receive a ticket.",
    cost_overview: "Cost Overview",
    cancel_ticket_request_button: "Cancel",
    go_to_tickets_button: "Go to Tickets",
    cancel_ticket_request_confirm_title: "Confirm Ticket Request Cancellation",
    handled: "Ticket Request converted to ticket",
    deleted: "Ticket Request has been deleted",
    ticket_request: "Ticket Request",
    cancel_ticket_request_confirm:
      "Are you sure you want to cancel this ticket request? This action cannot be undone.",
    cancelled_at: "Deleted at {{date}}",
  },

  event_form_fields: {
    title: "Event Form",
    description:
      "The event organizer has requested additional information from you. See the information below.",
    accept_terms_and_conditions:
      "By submitting this form, you agree to share the above information with the event organizer to plan the event. The information will be processed in accordance with the Chapter's information processing policy.",
    no_form_fields: "Oops! There is no form for this event.",
    ticket_number: "Ticket {{number}}",
    view_form_fields: "View Form",
    hide_form_fields: "Hide Form",
    save_response: "Save Response",
    ticket_number_description: "Enter details for ticket {{number}} below.",
    existing_image: "Existing Image",
  },

  tickets: {
    hide_cost_overview: "Hide Cost Overview",
    view_cost_overview: "View Cost Overview",
    cost_overview: "Cost Overview",
    confirmed_ticket:
      "Your ticket has been confirmed! It's now time to pay for your ticket. Click the button below to pay. If you do not pay before <1>{{payBefore}}</1>, your ticket will be given to the next person in line.",
    reserve_ticket:
      "Unfortunately, you were allocated a reserve ticket for this event. You will be notified if a ticket becomes available.",
    has_paid: "You have paid for your ticket",
    is_registered: "You are registered for the event",
    unregister_button: "Unregister from Event",
    confirm_unregister_title: "Confirm Unregistration",
    confirm_unregister_text:
      "Are you sure you want to unregister from this event? This action cannot be undone.",
    has_refunded: "Ticket has been refunded",
    request_refund:
      "You can request a refund by contacting the event organizer.",
    no_refund: "No refund available",

    cancel_ticket_request_button: "Cancel",
    cancel_ticket_button: "Cancel Ticket",
    confirm_cancel_ticket_title: "Confirm Ticket Cancellation",
    confirm_cancel_ticket_request_title: "Confirm Ticket Request Cancellation",
    leave_reserve_list_text: "Leave Reserve List",
    reserve_number: "You are number <1>{{number}}</1> on the reserve list.",
    paid_ticket:
      "You have paid for your ticket! We look forward to seeing you at the event. A receipt has been sent to your email.",
    confirm_cancel_reserve_ticket_text:
      "Are you sure you want to cancel your ticket? This action cannot be undone!",
    confirm_cancel_ticket_text:
      "Are you sure you want to cancel this ticket? This action cannot be undone!",
    pay_button: "Pay Now",
    not_paid_on_time:
      "Sorry! You did not pay for your ticket on time, so you lost your ticket.",
    total_cost: "Total",
    edit_details: "Edit Details",
    payment: {
      title: "Confirm Your Ticket Purchase",
      pay_now: "Pay {{price}} SEK",
      description: "Here you can pay for your ticket.",
    },

    qr_code: {
      description:
        "This is your QR code. Have it ready when you arrive at the event.",
      already_checked_in: "You have already checked in.",

      wallet: {
        add_to_google_wallet: "Save to Google Wallet",
        add_to_apple_wallet: "Save to Apple Wallet",
      },
    },

    // Add to src/translations/enTranslations.ts
    attendee_details: {
      title: "Enter Attendee Details",
      description: "Please fill in the details for each ticket holder",
      primary_attendee: "Primary Attendee",
      attendee_number: "Attendee {{number}}", // Uses number parameter
      form_fields: "Event Form",
      form_fields_required:
        "Please fill in all required event form fields before proceeding.",
      form_fields_save_required:
        "Please save the form fields before proceeding.",
      fields: {
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        phone: {
          label: "Phone Number",
          optional: "(Optional)",
        },
      },
      food_preferences: {
        title: "Allergies & Dietary Preferences",
        additional_info: "Additional Information",
        edit_in_profile: "Edit in Profile",
        additional_info_placeholder:
          "Please specify any additional dietary requirements or allergies",
        agree_text_1:
          "I and the other attendees agree to Tessera's <1>Privacy Policy</1>.",
        agree_text_2:
          "I allow the event organizer to process my and other attendees information.",

        options: {
          gluten_intolerant: "Gluten Intolerant",
          lactose_intolerant: "Lactose Intolerant",
          vegetarian: "Vegetarian",
          vegan: "Vegan",
          nut_allergy: "Nut Allergy",
          shellfish_allergy: "Shellfish Allergy",
          egg_allergy: "Egg Allergy",
          soy_allergy: "Soy Allergy",
          halal: "Halal",
          kosher: "Kosher",
        },
      },
      buttons: {
        continue: "Continue",
        save: "Save Changes",
        save_attendees: "Save Attendees",
      },
    },
  },

  event: {
    list_title: "Events",
    tickets: "Tickets",
    reserved: "Reserved",
    ticket_releases: "Ticket Batches",
    no_ticket_releases: "There are no ticket batches for this event.",
    event_by: "Event by",
    location: "Location",
    date_and_time: "Date & Time",
    organizer: "Organizer",
    time: "Time",
    description: "About the event",
    attendees: "{{count}} attending",
    promo_code_title: "Apply Promo Code",
    promo_code_helperText: "Enter the promo code to access reserved tickets.",
    promo_code_applied: "Promo code applied",
    contact_organizers: "Contact the organizers",
    ticket_request_success_title: "Ticket Request Successful",
    ticket_request_success_description:
      "You can also fill out this information later. Do so <1>here</1>.",
    ticket_order_pay_direct_form_later:
      "You can fill out this information after you have paid for your ticket.",
    continue_pay: "Continue to Payment",
    continue_ticket_orders: "View Ticket Order",
    ticket_releases_description:
      "Listed below are the ticket batches for this event. If you have a promo code, enter it at the bottom.",
    categories: {
      event: "Event",
      workshop: "Workshop",
      lecture: "Lecture",
      party: "Party",
      conference: "Conference",
      concert: "Concert",
      other: "Other",
    },
    status: {
      scheduled: "Scheduled",
      upcoming: "Upcoming",
      past: "Past",
    },
    check_in: {
      scan_ticket_title: "Scan Ticket",
      scan_ticket_instructions: "Scan the QR code on the ticket to check in.",
      loading: "Loading...",
      manual_check_in_button: "Check In",
      search_tickets: "Search Tickets",
      checked_in: "Checked In",
      hide_checked_in: "Hide Checked In",
      search_tickets_helperText: "Search for name, email, or ticket ID.",
      ready_to_scan: "Ready to scan",
      no_camera_permission: "No camera permission",
    },
    ticket_release: {
      closed: "Ticket batch has closed",
      tickets_available_in: "Tickets release on {{time}}",
      tickets_available_in_format: "MMMM d, yyyy 'at' HH:mm",
      tickets_available_for: "Ticket release closes",
      method: "This release uses <1>{{method}}</1>",
      sold_out: "Sold out",
      second: "second(s)",
      minute: "minute(s)",
      hour: "hour(s)",
      day: "day(s)",
      week: "week(s)",
      month: "month(s)",
      year: "year(s)",
      month_short: "mo",
      day_short: "d",
      hour_short: "h",
      minute_short: "m",
      second_short: "s",
      max_tickets_per_user: "{{count}} Tickets/person",
      cancellation: "Cancellation",
      requires_account: "Requires Account",
      requires_account_tooltip:
        "You must be signed in, in order to request tickets to this release.",
      max_tickets_error:
        "You can only purchase/request up to {{count}} tickets.",
      switch_release: {
        title: "Switch Ticket Batch",
        confirm:
          "You can only purchase or request tickets from one release at a time. Are you sure you want to switch?",
      },
      method_info: {
        fcfsl:
          "Requests made within the first <1>{{duration}}</1> of the release will be entered into the lottery.",
      },
      reserved: "Reserved",
      no_tickets: "There are no tickets available.",
      remove_reminder: "Remove Reminder",
      set_reminder: "Set Reminder for 10 minutes before release",
      legal: {
        privacy_consent:
          "By requesting/purchasing a ticket, you agree to share your details with the event organiser in accordance with Tessera's",
        terms_consent: "I have read and agree to Tessera's",
        information_processing_policy_info:
          "By requesting/purchasing a ticket, you agree to share your details with the event organiser in accordance with Tessera's <1>Privacy Policy</1>. You also agree to Tessera's <2>Refund Policy</2> and <3>Terms & Conditions</3>.",
      },

      checkout: {
        title: "Ticket details",
        overview: "Overview",
        what_is_a_request_title: "What is a Ticket Request?",
        what_is_a_request:
          "When making a request, you are not guaranteed to get the tickets. The allocation is done according to the Ticket Release Method described in the release description.",
        total: "Total",
        subtotal: "Subtotal",
        tax_amount: "VAT",
        fee_amount: "Transaction Fee",
        tax: "VAT ({{vat}}%)",
        transaction_fee: "Transaction fee",
        ticket: "Ticket",
        proceed: "Proceed",
        view_cart: "View Cart",
        cart_summary: "Cart Summary",
        cart_description:
          "Verify that the tickets below are correct before proceeding to payment.",
        proceed_request: "Request Tickets",
        proceed_payment: "Checkout",
        free_tickets: "Free Tickets",
        register: "Register Now",
        complete_signup: "Complete event registration",
        free: "Free",
        taxes_fees_next_step: "Open the cart to see taxes and fees",
        cart_description_payment:
          "Verify that the tickets below are correct before proceeding to payment.",
        cart_description_request:
          "Verify that the tickets below are correct before proceeding with your ticket request.",
        select: "Select",
      },
      add_ons: {
        title: "Ticket Addons",
        description: "Add addons to your ticket.",
        max_quantity: "Max Quantity: {{max}}",
        contains_alcohol: "Contains Alcohol",
        view_add_ons: "View Ticket addons",
        ticket_number: "Ticket {{number}}",
      },
      request_process: {
        complete_ticket_request: "",
        account_required_description:
          "To request a ticket, you must have an account. Sign in or create an account.",
        already_have_an_account: "Already have an account?",
        continue: "Enter your details to continue",
        form: {
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          phone_number: "Phone Number (optional)",
          password: "Password",
          password_repeat: "Repeat Password",
          button_save_account: "Save Account",
          button_save_account_helperText:
            "Create a tessera account for quicker checkouts in the future.",
          button_sign_up: "Sign Up",
          button_sign_in: "Sign In",
          button_continue_as_guest: "Continue as Guest",
        },
      },
    },

    actions: {
      add_to_calendar: "Add to calendar",
      share: "Share",
      calendar: {
        google: "Google Calendar",
        outlook: "Outlook Calendar",
        ical: "iCalendar (.ics)",
      },
    },

    loading: {
      event: "Loading event...",
      create_order: "Creating your order...",
      create_request: "Creating your ticket request...",
      fetch_order: "Fetching your order...",
      fetch_request: "Fetching your ticket request...",
      load_order: "Loading your order...",
      load_request: "Loading your ticket request...",
      just_a_moment: "Just a moment...",
      redirect_payment: "Redirecting to payment...",
      saving_request: "Saving your ticket request...",
      registering_ticket: "Registering your ticket...",
    },
    quick_tips: {
      title: "Getting started Tips",
      description:
        "Your event was successfully created! Here are some tips to get you started:",
      edit_event: "Edit event details",
      add_ticket_release: "Add ticket batch",
      manage_tickets: "Manage tickets",
      share_event: "Share event",
      create_addons: "Create addons",
      invite_attendees: "Invite attendees",
      setup_domains: "Setup domains",
      create_meal_plan: "Create meal plan",
    },
  },

  auth: {
    other_login_options: "Other login options",
    login_with_kth: "KTH Login",
    alternative_login_methods: "Alternative login methods",
  },

  customer: {
    login: "Login",
    signup: "Sign Up",
    oauth: "You can also sign in with",
    info: {
      subtitle: "Customer Login and Signup",
      description:
        "Welcome to tessera! By creating an account, your event experience will never be the same. All your tickets collected in one place. Easily request or buy tickets.",
      forgot_password: "Forgot Password?",
      dont_have_an_account: "Don't have an account? Sign up!",
    },
    form: {
      first_name: "First Name",
      last_name: "Last Name",
      username: "Username",
      email: "Email",
      password: "Password",
      password_repeat: "Repeat Password",
      button_signup: "Sign Up",
      button_login: "Login",
    },
    accept_terms: {
      first: "I have read and agree to tessera's ",
      privacy_policy: "Privacy Policy",
      terms_of_use: "Terms & Conditions",
      acceptable_use_policy: "Acceptable Use Policy",
      cookie_policy: "Cookie Policy",
    },
    forgot_password: {
      title: "Request New Password",
      description:
        "Enter the email address associated with your account to receive a link to reset your password.",
    },

    wait_verify_email: {
      title: "Verify Your Email",
      description:
        "We have sent you an email with a link to verify your account. Click the link to verify your account.",
    },

    resend: {
      title: "Resend Confirmation Email",
      toast_description: "It seems like you haven't verified your account yet.",
      toast_button: "Resend Email",
      description:
        "If you haven't received an email, you can resend the confirmation email.",
    },
  },

  footer: {
    about_title: "About",
    about_content:
      "tessera is a platform that makes releasing tickets and event management easy.",
    quick_links_title: "Quick Links",
    home: "Home",
    events: "Events",
    profile: "Profile",
    report_an_issue_title: "Report an Issue",
    made_by: "tessera is built by <1>Lucas Dow</1>",
    report_an_issue_content:
      "If something isn't working or you have a suggestion, <1>Create an issue on GitHub</1>.",
    contact_title: "Contact",
    support_title: "Support",
    follow_us: "Follow Us",
    legal_title: "Legal",
  },

  modal: {
    sure_quit: "Are you sure you want to quit?",
  },

  common: {
    copy: "Copy",
    coming_soon: "Coming Soon...",
    copied_to_clipboard: "Copied to clipboard",
    you: "You",
    back: "Back",
    cancel: "Cancel",
    show_all: "Show All",
    show_less: "Show Less",
    show_more: "Show More",
    search: "Search...",
    learn_more: "Learn More",
    created: "Created",
    made_at: "Made At",
    updated: "Updated",
    private_event: "Private Event",
    hour_one: "hour",
    hour_other: "hours",
    minute_one: "minute",
    minute_other: "minutes",
    lets_start: "Let's Start",
    of: "of",
    or: "or",
    use: "Use",
    since: "since",
    days_ago: "days ago",
    edit: "Edit",
    settings: "Settings",
    members: "Members",
    events: "Events",
    view_details: "View Details",
    create_event: "Create Event",
    delete: "Delete",
    ticket_id: "Ticket ID",
    mobile_warning:
      "Welcome to tessera! It appears you are using a mobile device. Some website features may not be optimized for mobile. However, requesting and viewing tickets should work as expected. If you're an event organizer, we recommend using a desktop device.",
    yes: "Yes",
    no: "No",
    customize: "Customize",
  },

  beta: {
    coming_soon: "Coming Soon...",
  },

  refund: {
    dialog_title: "Refund Ticket",
    description:
      "Only full refunds on a whole ticket order is allowed. Therefore, more tickets may be affected, please check the details below before proceeding.",
    reason: "Refund Reason",
    amount: "Refund Amount",
    submit: "Process Refund",
    order_info: "Order ID: {{id}}, Total: SEK {{total}}",
    user_info: "Name: {{name}}, Email: {{email}}",
    tickets_to_refund: "Tickets to refund",
    total_amount: "Total Amount: SEK {{amount}}",
    cannot_refund: "Cannot refund ticket",
    amount_error: {
      min: "Refund amount must be greater than 0",
      max: "Refund amount cannot exceed SEK {{max}}",
    },
    affected_tickets: "Affected Tickets",
  },

  error: {
    no_rows_selected: "No rows selected",
    invalid_refund_selection:
      "Invalid refund selection. Please select a single ticket.",
    ticket_not_found: "Selected ticket not found",
    no_ticket_selected: "No ticket selected for refund",
    unknown: "An unknown error occurred",
  },
  success: {
    delete: "Successfully deleted {{type}}",
    undelete: "Successfully undeleted {{type}}",
    allocate: "Successfully allocated tickets",
    refund_processed: "Refund processed successfully",
  },

  pricing: {
    contact_us: "Contact Us",
    get_started: "Get Started",
    selected_plan: "Selected Plan",
    pricing: "Pricing",
    choose_plan: "Choose the plan that works best for you",
    beta_text:
      "tessera is currently in BETA, therefore all features are available on the free plan.",
    how_to_pay: "How do you wish to pay?",
    monthly: "Monthly",
    yearly: "Yearly",
    discount: "33% off",
    excluding_vat: "All prices are excluding VAT.",
    list_of_features: "List of Features",
    per_event: "/event",
    per_month: "/month",
    per_year: "/year",
    most_popular: "Most Popular",
    plans: {
      free: "Free",
    },
    ticket_fees: {
      title: "Transaction Fee",
      description:
        "Transaction fee includes VAT, all banks and credit card fees, no hidden costs, providing you with the services you need to succeed with your event.",
      regular_fees: "Standard Fee",
      non_profit_fees: "For non-profits",
      fee_1: "3.5 % + 3 SEK",
      fee_2: "3 % + 1 SEK",
      per_sold_ticket: "Per transaction",
    },
  },

  business_page: {
    events: "Events",
    about: "About {{networkName}}",
    upcoming_events: "Upcoming Events",
    past_events: "Past Events",
    no_upcoming_events: "There are no upcoming events.",
    no_past_events: "There are no past events.",
    view_event: "View Event",
    calendar_view: "Calender View",
    grid_view: "Grid View",
    contact_us: "Contact Us",
    see_events_cta: "See events",
    calendar: {
      organized_by: "Organized by <1>{{name}}</1>",
      ticket_release: "Ticket Release",
      event: "Event",
      close: "Close",
    },
    ticket_release: "Ticket Release",
    events_this_month: "Events This Month",
    no_events_this_month: "No events this month",
    ticket_releases_this_month: "Ticket Releases This Month",
    no_ticket_releases_this_month: "No ticket releases this month",
  },

  legal: {
    privacy_policy: "Privacy Policy",
    terms: "Terms & Conditions",
    return: "Return Policy",
  },

  faq: {
    search_placeholder: "Search for a question...",
    title: "Frequently Asked Questions",
    subtitle:
      "If you require further assistance, please create a ticket in the <1>Support Center</1>.",
    ...gbFaq,
  },

  solutions: {
    titles: {
      in_person_events: "In-Person Events",
      university_events: "University Events",
      concerts: "Concerts",
      theater_shows: "Theater Shows",
      galas: "Galas",
      corporate_events: "Corporate Events",
      complex_teams: "Complex Teams",
      easy_event_setup: "Easy Event Setup",
    },
    main: {
      title: "Solutions for Every Event",
      description: "Find the perfect solution for your event management needs",
      about_title: "Event Management Made Simple",
      about_description:
        "Tessera provides specialized solutions for different types of events and organizations. From university events to corporate gatherings, we have the tools you need to succeed.",
      about_description_2:
        "Our platform is designed to be flexible and adaptable, allowing you to create and manage events exactly the way you want.",
      solutions: {
        title: "Discover Our Solutions",
      },
    },
    easy_event_setup: {
      title: "Easy Event Setup",
      description:
        "Create and manage events with ease - from concept to execution in minutes",
      section_1: {
        title: "Streamlined Event Creation",
        description:
          "Setting up your event has never been easier. Simply input the event you want to create using natural language and tessera will do the rest! Tessera integrates AI into the event creation process to streamline the whole process. For finer details, use the event builder.",
        description_2:
          "Our platform guides you through each step of the process, ensuring you don't miss any crucial details. From basic event information to advanced settings, we've made it simple to get your event up and running quickly.",
      },
      section_2: {
        title: "Because it's effortless",
        description:
          "tessera's platform is designed to make event creation as smooth as possible. With our AI-powered suggestions and pre-built templates, you can focus on what matters most - creating memorable experiences for your attendees.",
        description_2:
          "Whether you're organizing your first event or your hundredth, our platform provides the tools and guidance you need to succeed.",
      },
      features: {
        title: "Key Features",
        feature_1: {
          title: "AI-Powered Setup",
          description:
            "Let our AI assistant guide you through the event creation process with smart suggestions and best practices.",
        },
        feature_2: {
          title: "Pre-built Templates",
          description:
            "Choose from a variety of professionally designed templates for different event types.",
        },
        feature_3: {
          title: "Event Builder",
          description:
            "Clear guidance at every step ensures you configure your event perfectly.",
        },
        feature_4: {
          title: "Quick Customization",
          description:
            "Easily customize every aspect of your event while maintaining professional quality.",
        },
        feature_5: {
          title: "Supports multiple event types",
          description:
            "tessera supports a wide variety of event types, including concerts, theater shows, galas, corporate events, and more.",
        },
      },
    },
    in_person_events: {
      title: "In-Person Events",
      description:
        "Comprehensive solutions for managing physical events of any size",
      section_1: {
        title: "Seamless In-Person Event Management",
        description:
          "tessera provides all the tools you need to manage successful in-person events. From ticket distribution to check-in management, our platform ensures a smooth experience for both organizers and attendees.",
        description_2:
          "Create beautiful event pages, manage ticket releases, and handle attendee information all in one place. Our platform is designed to make in-person event management as efficient as possible.",
      },
      section_2: {
        title: "Features for Physical Events",
        description:
          "tessera offers specialized features for in-person events, including seating arrangements, meal preference management, and on-site check-in capabilities.",
        description_2:
          "Our platform helps you manage every aspect of your physical event, ensuring a seamless experience from start to finish.",
      },
      event_types: {
        title: "Types of In-Person Events",
        description:
          "tessera supports a wide variety of in-person events, including:",
        types: [
          {
            icon: "festival",
            title: "Festivals",
            description:
              "Music festivals, cultural festivals, and food festivals",
          },
          {
            icon: "school",
            title: "Student Events",
            description:
              "University balls, graduation ceremonies, and student fairs",
          },
          {
            icon: "business",
            title: "Conferences",
            description:
              "Business conferences, tech meetups, and industry seminars",
          },
          {
            icon: "theater",
            title: "Cultural Events",
            description: "Theater performances, art exhibitions, and concerts",
          },
          {
            icon: "celebration",
            title: "Ceremonies",
            description: "Award ceremonies, galas, and formal dinners",
          },
          {
            icon: "groups",
            title: "Social Events",
            description:
              "Networking events, community gatherings, and social clubs",
          },
        ],
      },
    },
    university_events: {
      title: "University Events",
      description:
        "Specialized solutions for student unions and organizations managing university events",
      section_1: {
        title: "Student Event Management",
        description:
          "tessera provides specialized tools for managing student events, from formal dinners to large parties. Our platform is designed with student organizations. Student organizations are not-for-profit organizations and tessera offers reduced pricing and fees for student organizations.",
        description_2:
          "Create beautiful event pages, manage ticket releases with lottery systems, and handle seating arrangements all in one place. Our platform is designed to make student event management as efficient as possible.",
      },
      section_2: {
        title: "Features for Student Events",
        description:
          "tessera offers specialized features for student events, including seating arrangements, dietary preferences, and dress code management. Our platform helps you manage every aspect of your student event, from ticket sales to guest lists, ensuring a seamless experience for both organizers and attendees.",
        description_2:
          "Our platform helps you manage every aspect of your student event, from ticket sales to guest lists, ensuring a seamless experience for both organizers and attendees.",
      },
      event_types: {
        title: "Types of Student Events",
        description: "tessera supports all types of student events, including:",
        types: [
          {
            icon: "party",
            title: "Gasques",
            description:
              "Formal student dinner parties with entertainment and dancing",
          },
          {
            icon: "dinner",
            title: "Banquets",
            description:
              "Formal dinners with traditional student ceremonies and songs",
          },
          {
            icon: "table",
            title: "Dinners",
            description:
              "Seated dinners with entertainment and traditional student activities",
          },
          {
            icon: "celebration",
            title: "Student Parties",
            description:
              "Casual to semi-formal student gatherings and celebrations",
          },
          {
            icon: "graduation",
            title: "Graduation Events",
            description:
              "Graduation ceremonies, balls, and celebration dinners",
          },
          {
            icon: "welcome",
            title: "Welcome Events",
            description:
              "Orientation weeks, welcome parties, and student introductions",
          },
        ],
      },
    },
    concerts: {
      description:
        "Specialized tools for managing concert events, from ticket sales to venue management",
    },
    theater_shows: {
      description:
        "Complete solutions for theater productions and performances",
    },
    galas: {
      description: "Elegant tools for managing formal events and galas",
    },
    corporate_events: {
      description:
        "Professional solutions for corporate events and business gatherings",
    },
    complex_teams: {
      title: "Complex Team Management",
      description:
        "Powerful solutions for organizations with complex team structures managing multiple events simultaneously",
      section_1: {
        title: "Advanced Team Management",
        description:
          "tessera provides a sophisticated team management system that allows organizations to create and manage complex team hierarchies. From departments to sub-teams, our platform makes it easy to organize and delegate responsibilities.",
        description_2:
          "Create custom roles, set permissions, and manage access levels for different team members. Our platform is designed to handle the most complex organizational structures while maintaining simplicity and efficiency.",
      },
      section_2: {
        title: "Streamlined Multi-Event Management",
        description:
          "tessera's platform enables teams to manage multiple events simultaneously with minimal overhead. Our advanced permission system ensures that team members only have access to the events and features they need.",
        description_2:
          "Track event progress, monitor ticket sales, and manage resources across multiple events and teams from a centralized dashboard. Our platform scales with your organization, whether you're managing a handful of events or hundreds.",
      },
      event_types: {
        title: "Team Structure Examples",
        description:
          "tessera supports various team structures and event management scenarios, including:",
        types: [
          {
            icon: "departments",
            title: "Department Teams",
            description:
              "Create teams for different departments with their own events and sub-teams",
          },
          {
            icon: "chapters",
            title: "Chapter Management",
            description:
              "Manage multiple chapters or branches with independent event calendars",
          },
          {
            icon: "projects",
            title: "Project Teams",
            description:
              "Set up temporary teams for specific events or project series",
          },
          {
            icon: "committees",
            title: "Committee Structure",
            description:
              "Organize committees with specific roles and responsibilities",
          },
          {
            icon: "venues",
            title: "Venue Teams",
            description:
              "Create teams dedicated to managing events at specific venues",
          },
          {
            icon: "collaborations",
            title: "Collaborative Events",
            description:
              "Enable multiple teams to work together on shared events",
          },
        ],
      },
    },
  },
  ticket_release: {
    available: "Available",
    paid: "Paid",
    unpaid: "Unpaid",
    refunded: "Refunded",
    deleted: "Deleted",
    open: "Open",
    closed: "Closed",
    remaining_tickets: "Remaining Tickets",
    total_tickets: "Total Tickets",
  },
};

export default enTranslations;
