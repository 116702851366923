import { ROUTES } from "../../routes/def";

export const LEGAL_LINKS = {
  terms_and_conditions: "/legal/terms-and-conditions",
  privacy_policy: "/legal/privacy-policy",
  acceptable_use_policy:
    "https://app.termly.io/policy-viewer/policy.html?policyUUID=0a27dbbc-88c9-4383-a965-f0c852ccc37e",
  cookie_policy:
    "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24c54b4-2fa0-4cef-b897-424003c2b807",
  refund_policy: ROUTES.RETURN_POLICY,
  disclaimer:
    "https://app.termly.io/policy-viewer/policy.html?policyUUID=ba68552e-041a-43ba-8bdc-6f68bdb74d7e",
};
