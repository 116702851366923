export const generateEditTicketReleaseTicketTypes = (
  eventId: number,
  ticketReleaseId: number
) => {
  const route = ROUTES.EDIT_EVENT_TICKET_RELEASE_TICKET_TYPES;
  return generateRoute(route, { eventId, ticketReleaseId });
};

export const generateEditTicketReleaseAddons = (
  eventId: number,
  ticketReleaseId: number
) => {
  const route = ROUTES.EDIT_EVENT_TICKET_RELEASE_ADDONS;
  return generateRoute(route, { eventId, ticketReleaseId });
};

export const generateEditTicketReleaseRoute = (
  eventId: number | string,
  ticketReleaseId?: number | string
) => {
  const route = ROUTES.EDIT_EVENT_TICKET_RELEASES;

  let res = generateRoute(route, { eventId });

  if (ticketReleaseId) {
    // query param
    res += `?ticket_release_id=${ticketReleaseId}`;
  }

  return res;
};

export const generateManageTicketReleaseRoute = (
  eventId: number | string,
  ticketReleaseId: number | string
) => {
  const route = ROUTES.MANAGE_EVENT_TICKET_RELEASES;

  return generateRoute(route, { eventId, ticketReleaseId });
};

interface GenerateRouteParams {
  eventId?: number | string;
  ticketReleaseId?: number | string;
  token?: string;
  ticketId?: number;
  organizationId?: number;
  refId?: string;
  networkSlug?: string;
}

export const generateRoute = (
  route: string,
  {
    eventId,
    ticketReleaseId,
    token,
    ticketId,
    organizationId,
    refId,
    networkSlug,
  }: GenerateRouteParams
) => {
  let result = route;
  if (eventId) {
    result = route.replace(":eventID", eventId!.toString());
  }

  if (ticketReleaseId) {
    result = result.replace(":ticketReleaseID", ticketReleaseId.toString());
  }

  if (token) {
    result = result.replace(":token", token);
  }

  if (ticketId) {
    result = result.replace(":ticketID", ticketId.toString());
  }

  if (organizationId) {
    result = result.replace(":organizationID", organizationId.toString());
  }

  if (refId) {
    result = result.replace(":refID", refId);
  }

  if (networkSlug) {
    result = result.replace(":networkSlug", networkSlug);
  }

  return result;
};

export const ROUTES = {
  // Main routes
  MAIN: "/",
  LOGIN: "/login",
  LOGIN_ALTERNATIVES: "/login/alternatives",
  LOGIN_COMPLETE: "/login-complete/:token",
  LOGOUT: "/logout",
  FAQ: "/faq",

  // Product routes
  PRODUCT: "/product",
  PRODUCT_TICKET_DISTRIBUTION: "/product/ticket-distribution",
  PRODUCT_EVENT_MANAGEMENT: "/product/event-management",
  PRODUCT_EVENT_PAGES: "/product/event-pages",
  PRODUCT_USER_PREFERENCES: "/product/user-preferences",
  PRODUCT_TEAM_MANAGEMENT: "/product/team-management",
  PRODUCT_BETA_PROGRAM: "/product/beta-program",
  PRODUCT_MANAGER_ONBOARDING: "/product/manager-onboarding",
  PRODUCT_EVENT_FORMS: "/product/event-forms",
  PRODUCT_PAYMENTS: "/product/payments",
  PRODUCT_DATA_SECURITY: "/product/data-security",

  // Solutions routes
  SOLUTIONS: "/solutions",
  SOLUTIONS_IN_PERSON_EVENTS: "/solutions/in-person-events",
  SOLUTIONS_UNIVERSITY_EVENTS: "/solutions/university-events",
  SOLUTIONS_COMPLEX_TEAMS: "/solutions/complex-teams",
  SOLUTIONS_EASY_EVENT_SETUP: "/solutions/easy-event-setup",

  // Manager related routes
  MANAGER: {
    BASE: "/manager",
    DASHBOARD: "/dashboard",
    TEAMS: "/teams",
    TEAMS_VIEW: "/teams/:organizationID",
    SETTINGS: {
      BASE: "/settings",
      GENERAL: "/general",
      SUBSCRIPTION: "/subscription",
      USERS: "/users",
      CUSTOMIZATION: "/merchant-customization",
      SOCIAL_MEDIA: "/social-media",
      API_KEYS: "/api-keys",
    },
    SETUP: "/setup",
    CONTACT_DATABASE: "/contact-database",
    CREATE_EVENT: "/create-event",
    AI_CREATE_EVENT: "/ai-create-event",
    TESSERA_AI: "/tessera-ai",
  },

  MANAGER_DASHBOARD: "/manager/dashboard",
  MANAGER_TEAMS: "/manager/teams",
  MANAGER_TEAMS_VIEW: "/manager/teams/:organizationID",
  MANAGER_SETTINGS: "/manager/settings",
  MANAGER_SETTINGS_GENERAL: "/manager/settings/general",
  MANAGER_SETTINGS_SUBSCRIPTION: "/manager/settings/subscription",
  MANAGER_SETTINGS_USERS: "/manager/settings/users",
  MANAGER_SETTINGS_CUSTOMIZATION: "/manager/settings/merchant-customization",
  MANAGER_SETTINGS_SOCIAL_MEDIA: "/manager/settings/social-media",
  MANAGER_SETTINGS_API_KEYS: "/manager/settings/api-keys",
  MANAGER_SETUP: "/manager/setup",
  MANAGER_CONTACT_DATABASE: "/manager/contact-database",
  CREATE_EVENT: "/manager/create-event",
  AI_CREATE_EVENT: "/manager/ai-create-event",
  TESSERA_AI: "/manager/tessera-ai",

  // Event related routes inside EVENTS object with full paths
  MANAGE_EVENTS: {
    BASE: "/manage/events/:eventID",
    MANAGE: {
      BASE: "/",
      EDIT: {
        BASE: "edit",
        ADD_TICKET_RELEASE: "add-ticket-release",
        TICKET_RELEASES: "edit/ticket-releases",
        FORM: "form",
        LANDING_PAGE: {
          BASE: "edit/landing-page",
          EDITOR: {
            BASE: "editor",
          },
        },
      },
      SETTINGS: {
        BASE: "settings",
        FINANCIAL: "financial",
        DOMAIN: "domain",
      },
      TICKET_RELEASES: {
        BASE: "ticket-releases",
        EDIT: ":ticketReleaseID/edit",
        TICKET_TYPES: ":ticketReleaseID/ticket-types",
        ADDONS: ":ticketReleaseID/addons",
        ALLOCATE_TICKETS: ":ticketReleaseID/allocate-tickets",
      },
      TICKETS: "attendees",
      RESPONSES: "event-form-responses",
      ECONOMY: "economy",
      SCAN: "scan",
      SEND_OUTS: {
        BASE: "send-outs",
        NEW: "new",
      },
      TOOLS: {
        BASE: "tools",
        SEATING_PLAN: "tools/seating-plan",
        MEAL_MANAGEMENT: "tools/meal-management",
      },
      EMAIL_TEMPLATES: "email-templates",
    },
  },

  // Event routes outside the EVENTS object, referencing the ones inside
  EVENTS_ALL: "/events", // Alias for consistency
  EVENT_DETAIL: "/events/:refID",
  EVENT_DETAIL_LANDING_PAGE: "/events/:refID/landing-page",
  EVENT_TICKET_RELEASE: "/events/:eventID/ticket-release",
  EVENT_TICKET_RELEASE_DETAIL:
    "/events/:eventID/ticket-release/:ticketReleaseID",
  TICKETS: "/events/:eventID/tickets",
  TICKET_DETAIL: "/events/:eventID/tickets/:ticketID",

  // Event management routes
  MANAGE_EVENT: "/manage/events/:eventID",
  MANAGE_EVENT_TICKET_RELEASES: "/manage/events/:eventID/ticket-releases",
  MANAGE_EVENT_TICKETS: "/manage/events/:eventID/attendees",
  MANAGE_EVENT_RESPONSES: "/manage/events/:eventID/event-form-responses",
  MANAGE_SEND_OUT_LIST: "/manage/events/:eventID/send-outs",
  MANAGE_SEND_OUT_NEW: "/manage/events/:eventID/send-outs/new",
  MANAGE_EVENT_SETTINGS_FINANCIAL: "/manage/events/:eventID/settings/financial",
  MANAGE_EVENT_SETTINGS_DOMAIN: "/manage/events/:eventID/settings/domain",
  MANAGE_EVENT_ECONOMY: "/manage/events/:eventID/economy",
  TICKET_SCANNER: "/manage/events/:eventID/scan",

  // Emails
  MANAGE_EVENT_EMAIL_TEMPLATES:
    "/manage/events/events/:eventID/email-templates",

  // Event editing routes
  EDIT_EVENT: "/manage/events/:eventID/edit",
  EDIT_EVENT_ADD_TICKET_RELEASE:
    "/manage/events/:eventID/edit/add-ticket-release",
  EDIT_EVENT_TICKET_RELEASE_TICKET_TYPES:
    "/manage/events/:eventID/ticket-release/:ticketReleaseID/ticket-types",
  EDIT_EVENT_TICKET_RELEASE_ADDONS:
    "/manage/events/:eventID/ticket-release/:ticketReleaseID/addons",
  EDIT_EVENT_TICKET_RELEASES: "/manage/events/:eventID/edit/ticket-releases",
  EDIT_EVENT_FORM: "/manage/events/:eventID/edit/form",
  EDIT_EVENT_LANDING_PAGE_EDTIOR:
    "/manage/events/:eventID/edit/landing-page/editor",
  EDIT_EVENT_LANDING_PAGE_SETTINGS:
    "/manage/events/:eventID/edit/landing-page/",

  // Event tools
  EVENT_TOOL_SEATING_PLAN: "/manage/events/:eventID/tools/seating-plan",
  EVENT_TOOL_MEAL_MANAGEMENT: "/manage/events/:eventID/tools/meal-management",
  EVENT_TOOL_EMBED_EVENT: "/manage/events/:eventID/tools/embed-event",

  // Accept network invite
  ACCEPT_NETWORK_INVITE: "/networks/accept-invite/:token",

  // Organization related routes
  ORGANIZATIONS: "/organizations",
  ORGANIZATION_DETAIL: "/organizations/:organizationID",
  ORGANIZATION_USERS: "/organizations/:organizationID/users",
  CREATE_TEAM: "/teams/create",
  ORGANIZATION_SETTINGS: "/organizations/:organizationID/settings",

  // User profile routes
  PROFILE: "/profile",
  PROFILE_ORGANIZATIONS: "/profile/organizations",
  USER_FOOD_PREFERENCES: "/user-food-preferences",
  RENEW_FOOD_PREFS_CONSENT: "/profile/food-preferences/renew-consent",

  // Password related routes
  FORGOT_PASSWORD: "/forgot-password",
  PASSWORD_RESET: "/reset-password/:token",

  // Other routes
  PRICING: "/pricing",
  TICKET_RELEASE_METHODS: "/ticket-release-methods",
  TICKET_TYPES: "/ticket-types",
  TICKET_RELEASE_CONSTANTS: "/ticket-release/constants",
  HANDLE_LOGIN_CALLBACK: "/handle-login-callback",
  EXTERNAL_VERIFY_EMAIL: "/verify-email/:token",
  CONTACT_PAGE: "/contact",
  PRIVACY_POLICY: "/legal/privacy-policy",
  TERMS_AND_CONDITIONS: "/legal/terms-and-conditions",
  RETURN_POLICY: "/legal/return-policy",

  // Guest routes
  GUEST_TICKET_REQUEST: "/events/:refID/guest/:ugkthid",

  // Admin routes
  ADMIN: "/admin/*",

  // Post login routes
  POST_LOGIN: "/post-login",
  BECOME_A_MANAGER: "/become-a-manager",
  CANVAS: "/canvas",

  // Business page routes
  NETWORK_BUSINESS_PAGE: "/network/business/:networkSlug",

  // Unsubscribe route
  UNSUBSCRIBE: "/contact/unsubscribe",

  SUPPORT_CENTER: "https://tessera.tawk.help",
};
