import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Grid,
  Stack,
  Tooltip,
} from "@mui/joy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IAddon, ISelectedAddon, ITicketRelease } from "../../../../types";
import StyledText from "../../../text/styled_text";
import usePalette from "../../../../theme/use_palette";
import { useTranslation } from "react-i18next";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import StyledButton from "../../../buttons/styled_button";
import { selectAccentColor } from "../../../../redux/features/managerThemeSlice";
import { useSelector } from "react-redux";
import { ShoppingCartItem } from "../../../../redux/features/ticketOrderSlice";
import { adjustTransparency } from "../../../../utils/manager/color";

interface TicketReleaseAddonsProps {
  ticketRelease: ITicketRelease;
  cartItems: ShoppingCartItem[];
  handleChange: any;
  immutable?: boolean;
}

const TicketReleaseAddons: React.FC<TicketReleaseAddonsProps> = ({
  ticketRelease,
  cartItems,
  handleChange,
  immutable = false,
}) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const accentColor = useSelector(selectAccentColor);

  // Check if there are any addons to display
  if (!ticketRelease.add_ons || ticketRelease.add_ons.length === 0) {
    return null;
  }

  return (
    <Box mt={2}>
      <StyledText level="h3" color={palette.primaryDark} fontSize={20}>
        {t("event.ticket_release.add_ons.title")}
      </StyledText>
      <StyledText level="body-sm" color={palette.charcoal} fontSize={16}>
        {t("event.ticket_release.add_ons.description")}
      </StyledText>

      {/* Loop through each ticket in the cart */}
      {cartItems.map((item, index) => (
        <Accordion
          key={item.id}
          defaultExpanded={index === 0}
          sx={{
            border: "2px dashed",
            borderColor: palette.black,
            borderRadius: "5px",
            maxWidth: "500px",
            p: 1,
            my: 1,
            bgcolor: adjustTransparency(palette.offWhite, 0.5),
            "& .MuiAccordionSummary-button": {
              borderRadius: "5px",
            },
            "& .MuiAccordionSummary-button:hover": {
              bgcolor: adjustTransparency(palette.black, 0.1),
            },
          }}
        >
          <AccordionSummary>
            <StyledText
              level="h5"
              color={palette.black}
              fontSize={16}
              fontWeight={700}
            >
              {t("event.ticket_release.add_ons.ticket_number", {
                number: index + 1,
              })}{" "}
              | {item.ticket.name}
            </StyledText>
          </AccordionSummary>
          <AccordionDetails>
            {/* Display addons for this specific ticket */}
            {ticketRelease.add_ons?.map((addon: IAddon) => {
              const selectedAddon = item.addons.find((a) => a.id === addon.id);

              return (
                <Box
                  key={addon.id}
                  mt={2}
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "5px",
                    p: 1,
                  }}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={2}
                    alignItems="center"
                  >
                    {/* Checkbox to select/deselect addon */}
                    <Grid>
                      <Checkbox
                        name={`${addon.name}-${addon.id}`}
                        checked={!!selectedAddon}
                        // When calling handleChange
                        onChange={() => {
                          // Handle addon selection/deselection
                          let updatedAddons: ISelectedAddon[];
                          if (selectedAddon) {
                            // Remove the addon
                            updatedAddons = item.addons.filter(
                              (a) => a.id !== addon.id
                            );
                          } else {
                            // Add the addon with quantity 1
                            updatedAddons = [
                              ...item.addons,
                              { id: addon.id, quantity: 1 },
                            ];
                          }
                          handleChange(item.id, updatedAddons);
                        }}
                        disabled={immutable}
                      />
                    </Grid>
                    {/* Display addon name and price */}
                    <Grid>
                      <Tooltip
                        title={addon.description}
                        placement="right-start"
                      >
                        <span>
                          <StyledText
                            color={palette.charcoal}
                            fontSize={16}
                            level="body-sm"
                            fontWeight={600}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "500px",
                            }}
                          >
                            {addon.name}
                            <StyledText
                              level="body-sm"
                              startDecorator={<HorizontalRuleIcon />}
                              color={palette.charcoal}
                              fontSize={16}
                              fontWeight={500}
                              sx={{ ml: 1 }}
                            >
                              {addon.price} kr
                            </StyledText>
                          </StyledText>
                        </span>
                      </Tooltip>
                    </Grid>
                    {/* Quantity controls if addon is selected */}
                    {selectedAddon && (
                      <Grid>
                        <Stack
                          spacing={2}
                          direction="row"
                          alignItems="center"
                          ml={2}
                        >
                          {/* Decrease quantity button */}
                          <StyledButton
                            size="sm"
                            color={palette.charcoal}
                            bgColor={palette.errorMain}
                            fs={40}
                            style={{ height: "30px" }}
                            disabled={selectedAddon.quantity <= 1}
                            onClick={() => {
                              const updatedAddons = item.addons.map((a) =>
                                a.id === addon.id
                                  ? { ...a, quantity: a.quantity - 1 }
                                  : a
                              );
                              handleChange(item.id, updatedAddons);
                            }}
                          >
                            -
                          </StyledButton>
                          {/* Display current quantity */}
                          <StyledText
                            color={palette.charcoal}
                            fontSize={18}
                            level="body-sm"
                            fontWeight={700}
                          >
                            {selectedAddon.quantity}
                          </StyledText>
                          {/* Increase quantity button */}
                          <StyledButton
                            size="sm"
                            color={palette.charcoal}
                            bgColor={palette.successLight}
                            fs={40}
                            style={{ height: "30px" }}
                            disabled={
                              selectedAddon.quantity >= addon.max_quantity
                            }
                            onClick={() => {
                              const updatedAddons = item.addons.map((a) =>
                                a.id === addon.id
                                  ? { ...a, quantity: a.quantity + 1 }
                                  : a
                              );
                              handleChange(item.id, updatedAddons);
                            }}
                          >
                            +
                          </StyledButton>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  {/* Addon description and max quantity info */}
                  <StyledText
                    level="body-sm"
                    color={palette.charcoal}
                    fontSize={13}
                    sx={{ mt: 0.5 }}
                  >
                    {addon.description}{" "}
                    <StyledText
                      level="body-sm"
                      color={palette.charcoalTransparent}
                      fontSize={13}
                      fontWeight={600}
                    >
                      (
                      {t("event.ticket_release.add_ons.max_quantity", {
                        max: addon.max_quantity,
                      })}
                      ){" "}
                      {addon.contains_alcohol &&
                        `(${t(
                          "event.ticket_release.add_ons.contains_alcohol"
                        )})`}
                    </StyledText>
                  </StyledText>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default TicketReleaseAddons;
