import { Box, Divider, Grid, Link, Stack, Container } from "@mui/joy";
import StyledText from "../text/styled_text";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ConsentPreferences from "../legal/consent_banner";
import { ROUTES } from "../../routes/def";
import { ConstructNormalRoute } from "../../utils/subdomain/subdomain_utils";
import usePalette, { useIsDarkTheme } from "../../theme/use_palette";
import { LEGAL_LINKS } from "../legal/legal_links";
import TesseraRedLogo from "../../assets/logos/tessera-logo-red-white-clipped.png";
import KTHInnovationLogo from "../../assets/logos/Founded at KTH-2025-blue.png";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const palette = usePalette();
  const darkMode = useIsDarkTheme();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const footerSections = [
    {
      title: t("footer.quick_links_title"),
      links: [
        { label: "Home", href: ConstructNormalRoute(ROUTES.MAIN) },
        {
          label: isLoggedIn ? "Profile" : "Sign up",
          href: isLoggedIn
            ? ConstructNormalRoute(ROUTES.PROFILE)
            : ConstructNormalRoute(ROUTES.LOGIN),
        },
        {
          label: "Become a manager",
          href: ConstructNormalRoute(ROUTES.BECOME_A_MANAGER),
        },
        { label: "Pricing", href: ConstructNormalRoute(ROUTES.PRICING) },
      ],
    },
    {
      title: t("footer.legal_title"),
      links: [
        {
          label: "Terms & Conditions",
          href: process.env.REACT_APP_BASE_URL + ROUTES.TERMS_AND_CONDITIONS,
          external: true,
        },
        {
          label: "Privacy Policy",
          href: process.env.REACT_APP_BASE_URL + ROUTES.PRIVACY_POLICY,
          external: true,
        },
        {
          label: "Return Policy",
          href: process.env.REACT_APP_BASE_URL + ROUTES.RETURN_POLICY,
          external: true,
        },
        {
          label: "Disclaimer",
          href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=ba68552e-041a-43f9-8bdc-6f68bdb74d7e",
          external: true,
        },
        {
          label: "Acceptable Use Policy",
          href: "https://app.termly.io/policy-viewer/policy.html?policyUUID=0a27dbbc-88c9-4383-a965-f0c852ccc37e",
          external: true,
        },
        {
          label: "DSAR",
          href: "https://app.termly.io/notify/8f234fc7-8c8a-46ba-9a63-0e35106f6ef3",
          external: true,
        },
        {
          label: "Cookie Policy",
          href: LEGAL_LINKS.cookie_policy,
          external: true,
        },
      ],
    },
    {
      title: t("footer.support_title"),
      links: [
        {
          label: "Support Center",
          href: ROUTES.SUPPORT_CENTER,
          external: true,
        },
        { label: "FAQ", href: ConstructNormalRoute(ROUTES.FAQ) },
      ],
    },
    {
      title: t("footer.follow_us"),
      links: [
        {
          label: "LinkedIn",
          href: "https://www.linkedin.com/showcase/tesseratickets",
          external: true,
        },
      ],
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: palette.charcoal,
        width: "100%",
        zIndex: 5,
        position: "relative",
        paddingTop: { xs: 4, md: 6 },
        paddingBottom: { xs: 12, md: 4 },
      }}
      id="footer"
    >
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, md: 4 }}
          sx={{
            justifyContent: "space-between",
            mb: { xs: 4, md: 6 },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {footerSections.map((section, index) => (
            <Grid
              key={index}
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <StyledText
                color={palette.offWhite}
                level="body-sm"
                fontWeight={700}
                sx={{
                  mb: { xs: 1.5, md: 2 },
                  textAlign: { xs: "center", md: "left" },
                  fontSize: { xs: 18, md: 20 },
                }}
              >
                {section.title}
              </StyledText>
              <Stack
                spacing={1.5}
                sx={{
                  alignItems: { xs: "center", md: "flex-start" },
                  width: "100%",
                }}
              >
                {section.links.map((link, linkIndex) => (
                  <Box
                    key={linkIndex}
                    sx={{
                      width: "fit-content",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {link.external ? (
                      <Link
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: palette.offWhite,
                          opacity: 0.8,
                          transition: "all 0.2s ease",
                          fontSize: { xs: 14, md: 16 },
                          "&:hover": {
                            opacity: 1,
                            textDecoration: "none",
                            transform: "translateY(-1px)",
                          },
                        }}
                      >
                        {link.label}
                      </Link>
                    ) : (
                      <Link
                        href={link.href}
                        sx={{
                          color: palette.offWhite,
                          opacity: 0.8,
                          transition: "all 0.2s ease",
                          fontSize: { xs: 14, md: 16 },
                          "&:hover": {
                            opacity: 1,
                            textDecoration: "none",
                            transform: "translateY(-1px)",
                          },
                        }}
                      >
                        {link.label}
                      </Link>
                    )}
                  </Box>
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mb: 3,
          }}
        >
          <Link
            href={process.env.REACT_APP_BASE_URL + ROUTES.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: palette.offWhite,
              fontSize: { xs: 16, md: 18 },
              opacity: 0.9,
              transition: "all 0.2s ease",
              textDecoration: "underline",
              "&:hover": {
                opacity: 1,
                transform: "translateY(-1px)",
              },
            }}
          >
            Privacy Policy
          </Link>
          <Box
            sx={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              backgroundColor: palette.offWhite,
              opacity: 0.6,
            }}
          />
          <Link
            href={process.env.REACT_APP_BASE_URL + ROUTES.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: palette.offWhite,
              fontSize: { xs: 16, md: 18 },
              opacity: 0.9,
              transition: "all 0.2s ease",
              textDecoration: "underline",
              "&:hover": {
                opacity: 1,
                transform: "translateY(-1px)",
              },
            }}
          >
            Terms & Conditions
          </Link>
        </Box>

        <Divider
          sx={{
            borderColor: "rgba(255, 255, 255, 0.1)",
            my: { xs: 3, md: 4 },
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 2, md: 3 },
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box
              onClick={() => navigate(ROUTES.MAIN)}
              sx={{
                cursor: "pointer",
                transition: "all 0.2s ease",
                "&:hover": {
                  opacity: 0.8,
                  transform: "translateY(-2px)",
                },
              }}
            >
              <img
                src={TesseraRedLogo}
                alt="Tessera Logo"
                style={{
                  width: isScreenSmall ? "200px" : "300px",
                  filter: darkMode
                    ? "brightness(0) saturate(100%) invert(1) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)"
                    : "",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                padding: "8px 16px",
                borderRadius: "8px",

                height: isScreenSmall ? "60px" : "75px",
              }}
            >
              <img
                src={KTHInnovationLogo}
                alt="Founded at KTH - Supported by KTH Innovation 2025"
                style={{
                  height: "100%",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <ConsentPreferences />
          </Box>
          <StyledText
            color={palette.offWhite}
            level="body-sm"
            sx={{
              opacity: 0.8,
              textAlign: "center",
              maxWidth: { xs: "280px", md: "none" },
              margin: "0 auto",
              fontSize: { xs: 12, md: 14 },
            }}
          >
            © {new Date().getFullYear()} Dow Technology. All rights reserved.
          </StyledText>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
